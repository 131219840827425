// Images
import intelPack1 from './images/items/intel/intel_pack1.webp'
import intelPack2 from './images/items/intel/intel_pack2.webp'
import intelPack3 from './images/items/intel/intel_pack3.webp'
import intelPack4 from './images/items/intel/intel_pack4.webp'
import shadyHacker from './images/npc/glitch.webp'

export const hackerImg = shadyHacker

export const intelOfferList = [
  {
    name: 'Small package',
    amount: 1000,
    price: 100,
    quality: 0,
    bonusAmountMultiplier: 1,
    img: intelPack1,
  },
  {
    name: 'Standard package',
    amount: 3000,
    price: 300,
    quality: 3,
    bonusAmountMultiplier: 1.1,
    img: intelPack2,
  },
  {
    name: 'Complex package',
    amount: 10000,
    price: 1000,
    quality: 4,
    bonusAmountMultiplier: 1.25,
    img: intelPack3,
  },
  {
    name: 'Overmind package',
    amount: 25000,
    price: 2500,
    quality: 5,
    bonusAmountMultiplier: 1.5,
    img: intelPack4,
  },
] //@Todo - move to BE
