export const Fn_G_ComposeResCostMessage = (p: {}) => {
  let stringFormatValues: string[] = []
  Object.entries(p).map(item => {
    item[1] !== 0 ? stringFormatValues.push(`${item[1]}\xa0${item[0]}`) : ''
  })

  return stringFormatValues.join(', ')
}

// @todo refactoring for this fn (used for defense & production menu)
// export const Fn_G_UpdateGameData = (data: any, type: string, contextFn: any) => {
//   let valueKey: string = ''
//   let valueTarget: string = ''

//   if (type === 'defense') {
//     valueKey = 'defType'
//     valueTarget = 'def'
//   } else if (type === 'production') {
//     valueKey = 'x'
//     valueTarget = 'x'
//   }

//   if (valueKey && valueTarget) {
//     let levelSum = 0
//     let powerConsumptionSum = 0
//     let setValues: { [index: string]: number } = {}

//     Object.values(data).map((item: any) => {
//       setValues[item[valueKey]] = item.statsAtCurrentLevel[valueTarget]
//       powerConsumptionSum += item.statsAtCurrentLevel.powerConsumption
//       levelSum += item.level
//     })

//     contextFn({
//       currentBuildingLimit: levelSum,
//       powerConsumption: powerConsumptionSum,
//       defStats: {
//         heat: setValues.heat,
//         cold: setValues.cold,
//         radiation: setValues.radiation,
//         cyber: setValues.cyber,
//         force: setValues.force,
//       },
//     })
//   }
// }
