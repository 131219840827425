/** @jsxImportSource @emotion/react */
// Default
import * as Unit from '../../../Reusable_overmind'
// import * as theme from '../../../theme'
// import { css } from '@emotion/react'

// Others
import { GameContext } from '../../../gameContext'
import { useContext } from 'react'

// Local data
import { unitImgList } from '../../../gameStaticData/unitImgList'
import { unitList } from '../../../gameStaticData/MOCK/unitList'

///////////////////////////////////////////////////////////////////////////////////////////////////////////
// CSS
///////////////////////////////////////////////////////////////////////////////////////////////////////////

// const s = {} as const

///////////////////////////////////////////////////////////////////////////////////////////////////////////
// Main
///////////////////////////////////////////////////////////////////////////////////////////////////////////

export const UnitMenu = () => {
  const context = useContext(GameContext)

  // Init
  const MOCK_unitList = unitList //@Todo - replace MOCK!

  return (
    <Unit.G_GP.MenuCategory>
      {MOCK_unitList.map((item: Unit, index) => {
        return (
          <Unit.G_GP.UnitPattern
            key={index}
            img={unitImgList[index]}
            name={item.name}
            unitCount={2} // @todo User data
            unitType={item.unitType}
            unitClass={item.unitClass}
            stats={item.stats}
            constructionRate={item.constructionRate}
            resCost={item.resCost}
            unlockAt={item.unlockAt}
            hqLevel={context.levelOfSelectedHQ.levelOfSelectedHQ}
          />
        )
      })}
    </Unit.G_GP.MenuCategory>
  )
}

///////////////////////////////////////////////////////////////////////////////////////////////////////////
// Components
///////////////////////////////////////////////////////////////////////////////////////////////////////////

type Unit = {
  id: string
  index: number
  name: string
  unitType: string
  unitClass: string
  unlockAt: number

  resCost: {
    metal?: number
    plastics?: number
    electronics?: number
    fuel?: number
    food?: number
  }
  constructionRate: number

  stats: {
    hp: number
    def: number
    atk: number
    fuelConsumption?: number
    speed: number
  }
}
