/** @jsxImportSource @emotion/react */
// Default
// import * as Unit from '../../Reusable'
import * as theme from '../../../theme_overmind'
import { css } from '@emotion/react'

// Others
import { useEffect, useState } from 'react'

// Controllers
import * as GameController from '../../../gameControllers/GameController'
import * as ResourcesController from '../../../gameControllers/ResourcesController'

///////////////////////////////////////////////////////////////////////////////////////////////////////////
// CSS
///////////////////////////////////////////////////////////////////////////////////////////////////////////

const s = {
  resBar_div: css({
    position: `relative`,
    width: theme.main.layout.resBar.width,
    height: theme.main.layout.resBar.height,
    padding: theme.main.spacing.xs,
    backgroundColor: theme.color.transparent_cover_dark,
    color: theme.color.light,
  }),
  resBarContent: css({
    width: `${theme.responsiveSize.layout.resBar.innerItem}%`,
    height: `100%`,
    display: `flex`,
    justifyContent: `space-around`,
  }),
  leftResBarContent: css({
    position: `absolute`,
    left: 0,
  }),
  rightResBarContent: css({
    position: `absolute`,
    right: 0,
  }),
  centerResBarContent: css({
    position: `absolute`,
    left: `50%`,
    transform: `translate(-50%, 0)`,
    width: `${100 - theme.responsiveSize.layout.resBar.innerItem * 2}%`,
    height: `100%`,
    textAlign: `center`,
    fontSize: theme.main.font.md_header,
  }),
  innerResBarContent_item: css({
    width: `20%`,
    margin: `0 ${theme.main.spacing.xs}`,
    padding: `${theme.main.spacing.xxs} ${theme.main.spacing.xs}`,
    textAlign: `center`,
  }),
} as const

///////////////////////////////////////////////////////////////////////////////////////////////////////////
// Main
///////////////////////////////////////////////////////////////////////////////////////////////////////////

export const ResBar = () => {
  const [currentData, setCurrentData] = useState(ResourcesController.GetData())

  // Refresh resBar data
  GameController.GameTick()

  // Date setup
  const currentDate = new Date()

  const currentYear = currentDate.getFullYear()
  const currentMonth = currentDate.getMonth() + 1
  const currentDay = currentDate.getDate()
  const gameDate = `
    ${currentYear + 50}-${currentMonth < 10 ? `0${currentMonth}` : currentMonth}-${
    currentDay < 10 ? `0${currentDay}` : currentDay
  }`

  const currentTimeHrs = currentDate.getHours()
  const currentTimeMin = currentDate.getMinutes()
  const currentTimeSec = currentDate.getSeconds()
  const gameTime = `
    ${currentTimeHrs < 10 ? `0${currentTimeHrs}` : currentTimeHrs}:${
    currentTimeMin < 10 ? `0${currentTimeMin}` : currentTimeMin
  }:${currentTimeSec < 10 ? `0${currentTimeSec}` : currentTimeSec}
    `

  const fullGameTime = {
    date: gameDate,
    time: gameTime,
  }

  // Load new data //@Todo - replace with BE
  useEffect(() => {
    const DataRefresh = setInterval(() => {
      setCurrentData(ResourcesController.GetData())
    }, 1000)

    return () => clearInterval(DataRefresh)
  })

  // Display resBar
  if (currentData !== undefined) {
    return (
      <div css={s.resBar_div}>
        <LeftResBarContent resData={currentData} />
        <CenterResBarContent currentGameTime={fullGameTime} />
        <RightResBarContent resData={currentData} />
      </div>
    )
  } else {
    return <></>
  }
}

///////////////////////////////////////////////////////////////////////////////////////////////////////////
// Components
///////////////////////////////////////////////////////////////////////////////////////////////////////////

const LeftResBarContent = (p: { resData: ResourcesController.BaseDataFormat }) => (
  <div css={[s.resBarContent, s.leftResBarContent]}>
    <InnerResBarContent resData={p.resData} barType='left' />
  </div>
)

const RightResBarContent = (p: { resData: ResourcesController.BaseDataFormat }) => (
  <div css={[s.resBarContent, s.rightResBarContent]}>
    <InnerResBarContent resData={p.resData} barType='right' />
  </div>
)

const CenterResBarContent = (p: { currentGameTime: { date: string; time: string } }) => (
  <div css={s.centerResBarContent}>
    {p.currentGameTime.date} <br /> {p.currentGameTime.time}
  </div>
)

const InnerResBarContent = (p: {
  resData: ResourcesController.BaseDataFormat
  barType: string
}) => {
  return p.barType === 'left' ? (
    <>
      <ResBarLi title='Metal'>{p.resData?.metal}</ResBarLi>
      <ResBarLi title='Plastics'>{p.resData?.plastics}</ResBarLi>
      <ResBarLi title='Electronics'>{p.resData?.electronics}</ResBarLi>
      <ResBarLi title='Fuel'>{p.resData?.fuel}</ResBarLi>
    </>
  ) : p.barType === 'right' ? (
    <>
      <ResBarLi title='Oxygen'>{p.resData?.oxygen}</ResBarLi>
      <ResBarLi title='Water'>{p.resData?.water}</ResBarLi>
      <ResBarLi title='Food'>{p.resData?.food}</ResBarLi>
      <ResBarLi title='Power'>{p.resData?.power}</ResBarLi>
    </>
  ) : (
    <></>
  )
}

const ResBarLi = (p: { title: string; children: React.ReactNode }) => (
  <div css={s.innerResBarContent_item}>
    {p.title} <br />
    {p.children !== undefined && p.children !== null ? p.children.toLocaleString('en-US') : ''}
  </div>
)
