/** @jsxImportSource @emotion/react */
// Default
import * as Unit from '../../../Reusable_overmind'
// import * as theme from '../../../theme'
// import { css } from '@emotion/react'

// Others
import { GameContext } from '../../../gameContext'
import { useContext, useEffect } from 'react'

// Local data
import { productionBuildingsImgList } from '../../../gameStaticData/productionBuildingsImgList'
import { productionBuildingsList } from '../../../gameStaticData/MOCK/productionBuildingsList'

///////////////////////////////////////////////////////////////////////////////////////////////////////////
// CSS
///////////////////////////////////////////////////////////////////////////////////////////////////////////

// const s = {} as const

///////////////////////////////////////////////////////////////////////////////////////////////////////////
// Main
///////////////////////////////////////////////////////////////////////////////////////////////////////////

export const ProductionMenu = () => {
  const context = useContext(GameContext)

  // Init
  const MOCK_buildingsList = productionBuildingsList //@Todo - replace MOCK!

  // Set game data into context for main screen
  const UpdateGameData = () => {
    let levelSum = 0
    let powerConsumptionSum = 0
    let setResProduction: { [index: string]: number } = {}

    Object.values(MOCK_buildingsList).map(item => {
      setResProduction[item.resType] = item.statsAtCurrentLevel.resProduction
      powerConsumptionSum += item.statsAtCurrentLevel.powerConsumption
      levelSum += item.level
    })

    context.productionHQ_data.setProductionHQ_data({
      currentBuildingLimit: levelSum,
      powerConsumption: powerConsumptionSum,
      resProduction: {
        metal: setResProduction.metal,
        plastics: setResProduction.plastics,
        electronics: setResProduction.electronics,
        fuel: setResProduction.fuel,
        oxygen: setResProduction.oxygen,
        water: setResProduction.water,
        food: setResProduction.food,
        power: setResProduction.power,
      },
    })
  }

  useEffect(() => {
    UpdateGameData()
  }, [])

  return (
    <Unit.G_GP.MenuCategory>
      {MOCK_buildingsList.map((item: Building, index) => {
        const currentLevel = item.statsAtCurrentLevel
        const nextLevel = item.statsAtNextLevel

        return (
          <Unit.G_GP.BuildingPattern
            key={index}
            img={productionBuildingsImgList[index]}
            name={item.name}
            level={item.level}
            currentPowerConsumption={currentLevel.powerConsumption}
            nextPowerConsumption={nextLevel.powerConsumption}
            currentResProduction={currentLevel.resProduction}
            nextResProduction={nextLevel.resProduction}
            constructionRate={nextLevel.constructionRate}
            resCost={nextLevel.resCost}
          />
        )
      })}
    </Unit.G_GP.MenuCategory>
  )
}

///////////////////////////////////////////////////////////////////////////////////////////////////////////
// Components
///////////////////////////////////////////////////////////////////////////////////////////////////////////

type Building = {
  id: string
  index: number
  name: string
  resType: string
  level: number

  statsAtCurrentLevel: {
    resProduction: number
    powerConsumption: number
  }
  statsAtNextLevel: BuildingStats
}

type BuildingStats = {
  resProduction: number
  powerConsumption: number
  constructionRate: number
  resCost: {
    metal: number
    plastics: number
    electronics: number
    fuel: number
  }
}
