/** @jsxImportSource @emotion/react */
// Default Reusable
import * as theme from '../theme_overmind'
import { css } from '@emotion/react'
// import styled from '@emotion/styled'

// Reusable categories
import * as G_Reusable from './GameDefault'

// Others

// Images

///////////////////////////////////////////////////////////////////////////////////////////////////////////
// CSS - Main
///////////////////////////////////////////////////////////////////////////////////////////////////////////

export const main = {
  gpMenuCategory_div: css({
    height: `100%`,
    padding: theme.main.spacing.xs,
    overflowY: `scroll`,
  }),
  gpMenuItem_div: css({
    width: `100%`,
    margin: `${theme.main.spacing.xs} 0`,
    padding: theme.main.spacing.xs,
    backgroundColor: theme.color.transparent_cover_light,
    border: theme.main.border.dark,
  }),
  gpMenuItemInfo_div: css({
    display: `flex`,
  }),
  gpMenuItemInfoContent_div: css({
    width: `100%`,
    padding: theme.main.spacing.xs,
  }),
  gpMenuItemInfoContentTitle_div: css({
    marginBottom: theme.main.spacing.xs,
  }),
  gpMenuItemColumnWrapper_div: css({
    display: `flex`,
  }),
  gpMenuItemColumn_div: css({
    width: `50%`,
  }),
  gpMenuItem_img: css({
    width: theme.main.layout.gamePanel.gpMenu.itemImgSize,
    height: theme.main.layout.gamePanel.gpMenu.itemImgSize,
    border: theme.main.border.dark,
  }),
  gpItemNextLevelInfo_p: css({
    fontSize: theme.main.font.xs,
  }),
  gpItemNextLevelCostInfo_p: css({
    fontSize: theme.main.font.sm,
  }),
  gpMenuItemFooter_div: css({
    width: `100%`,
    display: `flex`,
    margin: `${theme.main.spacing.xs} 0`,
  }),
  gpMenuItem_upgradeBtn: css({
    flex: `0 0 ${theme.main.layout.gamePanel.gpMenu.itemImgSize}`,
    padding: theme.main.spacing.xs,
    border: theme.main.border.dark,
    cursor: `pointer`,
  }),
  gpMenuItem_upgradeBtn_locked: css({
    cursor: 'no-drop',
  }),
} as const

///////////////////////////////////////////////////////////////////////////////////////////////////////////
// Main Components
///////////////////////////////////////////////////////////////////////////////////////////////////////////

export type UnitStats = {
  hp: number
  def: number
  atk: number
  fuelConsumption?: number
  speed: number
}

export const MenuCategory = (p: { children: React.ReactNode }) => (
  <div css={main.gpMenuCategory_div} className='gpMenu'>
    {p.children}
  </div>
)

export const ItemPattern = (p: {
  children: React.ReactNode
  actionBtn: React.ReactNode
  name: string
  level?: number
  count?: number
  img: string
}) => (
  <div css={main.gpMenuItem_div}>
    <div css={main.gpMenuItemInfo_div}>
      <img src={p.img} css={main.gpMenuItem_img}></img>
      <div css={main.gpMenuItemInfoContent_div}>
        <div css={main.gpMenuItemInfoContentTitle_div}>
          <span>{p.name} </span>
          {p.level ? (
            <span css={main.gpItemNextLevelInfo_p}>{`(Level:\xa0${p.level})`}</span>
          ) : (
            <></>
          )}

          {p.count ? (
            <span css={main.gpItemNextLevelInfo_p}>{`(Count:\xa0${p.count})`}</span>
          ) : (
            <></>
          )}
        </div>

        {p.children}
      </div>
    </div>

    {p.actionBtn}
  </div>
)

export const BuildingPattern = (p: {
  img: string
  name: string
  level: number
  currentPowerConsumption: number
  nextPowerConsumption: number
  currentResProduction?: number
  nextResProduction?: number
  currentDef?: number
  nextDef?: number
  constructionRate: number
  resCost: G_Reusable.ResCost
}) => (
  <ItemPattern
    img={p.img}
    name={p.name}
    level={p.level}
    actionBtn={<G_Reusable.UpgradeBox constructionRate={p.constructionRate} resCost={p.resCost} />}
  >
    <G_Reusable.CurrentLevelInfo>
      Power consumption: {p.currentPowerConsumption}
    </G_Reusable.CurrentLevelInfo>
    <G_Reusable.NextLevelInfo>{p.nextPowerConsumption}</G_Reusable.NextLevelInfo>
    {p.currentResProduction !== undefined ? (
      <>
        <G_Reusable.CurrentLevelInfo>
          Production: {p.currentResProduction}
        </G_Reusable.CurrentLevelInfo>
        <G_Reusable.NextLevelInfo>{p.nextResProduction}</G_Reusable.NextLevelInfo>
      </>
    ) : (
      <></>
    )}
    {p.currentDef !== undefined ? (
      <>
        <G_Reusable.CurrentLevelInfo>Defense: {p.currentDef}</G_Reusable.CurrentLevelInfo>
        <G_Reusable.NextLevelInfo>{p.nextDef}</G_Reusable.NextLevelInfo>
      </>
    ) : (
      <></>
    )}
  </ItemPattern>
)

export const TechPattern = (p: {
  img: string
  name: string
  level: number
  currentEffect: number
  nextEffect: number
  researchRate: number
  resCost: G_Reusable.ResCostUnit
  unlockAt: number
  hqLevel: number
}) => (
  <ItemPattern
    img={p.img}
    name={p.name}
    level={p.level}
    actionBtn={
      <G_Reusable.UpgradeBox
        constructionRate={p.researchRate}
        resCost={p.resCost}
        alternativeType='Research'
        unlockAt={p.unlockAt}
        hqLevel={p.hqLevel}
      />
    }
  >
    <G_Reusable.CurrentLevelInfo>Effect: {p.currentEffect}</G_Reusable.CurrentLevelInfo>
    <G_Reusable.NextLevelInfo>{p.nextEffect}</G_Reusable.NextLevelInfo>{' '}
  </ItemPattern>
)

export const UnitPattern = (p: {
  img: string
  name: string
  unitCount: number
  unitType: string
  unitClass: string
  constructionRate: number
  resCost: G_Reusable.ResCostUnit
  stats: UnitStats
  unlockAt: number
  hqLevel: number
}) => (
  <ItemPattern
    img={p.img}
    name={p.name}
    count={p.unitCount}
    actionBtn={
      <G_Reusable.UpgradeBox
        constructionRate={p.constructionRate}
        resCost={p.resCost}
        alternativeType='Unit'
        unlockAt={p.unlockAt}
        hqLevel={p.hqLevel}
      />
    }
  >
    <G_Reusable.BaseInfo>Class: {p.unitClass}</G_Reusable.BaseInfo>
    <G_Reusable.BaseInfo>Type: {p.unitType}</G_Reusable.BaseInfo>
    <G_Reusable.CurrentLevelInfo>Stats: </G_Reusable.CurrentLevelInfo>
    <div css={main.gpMenuItemColumnWrapper_div}>
      <div css={main.gpMenuItemColumn_div}>
        <G_Reusable.BaseInfo>HP: {p.stats.hp}</G_Reusable.BaseInfo>
        <G_Reusable.BaseInfo>Speed: {p.stats.speed}</G_Reusable.BaseInfo>
        {p.stats.fuelConsumption ? (
          <G_Reusable.BaseInfo>Fuel: {p.stats.fuelConsumption}</G_Reusable.BaseInfo>
        ) : (
          <></>
        )}
      </div>

      <div css={main.gpMenuItemColumn_div}>
        <G_Reusable.BaseInfo>Def: {p.stats.def}</G_Reusable.BaseInfo>
        <G_Reusable.BaseInfo>Atk: {p.stats.atk}</G_Reusable.BaseInfo>
      </div>
    </div>
  </ItemPattern>
)
