/** @jsxImportSource @emotion/react */
// Default
import * as Unit from '../../../Reusable_overmind'
import * as theme from '../../../theme_overmind'
import { css } from '@emotion/react'

// Others
import { useState } from 'react'

// Local data
import { resImgList, resList } from '../../../gameStaticData/resImgList'
import res_dealer from '../../../gameStaticData/images/npc/res_dealer.webp'

///////////////////////////////////////////////////////////////////////////////////////////////////////////
// CSS
///////////////////////////////////////////////////////////////////////////////////////////////////////////

const s = {
  npcIcon_img: css({
    width: theme.main.imgSizing.npc,
    border: theme.main.border.dark,
    margin: theme.main.spacing.xs,
  }),
  titleBox_div: css({
    textAlign: `center`,
    marginTop: theme.main.spacing.sm,
  }),
  resListWrapper_div: css({
    display: `flex`,
  }),
  resListBox_div: css({
    width: `50%`,
    textAlign: `center`,
  }),
  resIcon_img: css({
    width: theme.main.imgSizing.item,
    border: theme.main.border.dark,
    margin: theme.main.spacing.xxs,
  }),
  resIcon_img_selectActive: css({
    border: theme.main.border.main,
  }),
  focusTitle: css({
    fontWeight: 'bold',
  }),
  focusValue: css({
    margin: `${theme.main.spacing.xs} 0`,
    fontWeight: 'bold',
    color: theme.color.focus,
    textShadow: theme.main.shadow.text.main,
  }),
  tradeInput: css({
    margin: `${theme.main.spacing.xs} 0`,
    padding: theme.main.spacing.xxs,
    color: theme.color.light,
    backgroundColor: theme.color.transparent_cover_dark,
    border: `none`,
  }),
  tradeSubmit: css({
    cursor: `pointer`,
    margin: `${theme.main.spacing.xs} 0`,
    padding: theme.main.spacing.xxs,
    backgroundColor: theme.color.focus,
    border: `none`,
    fontWeight: 'bold',
  }),
} as const

///////////////////////////////////////////////////////////////////////////////////////////////////////////
// Main
///////////////////////////////////////////////////////////////////////////////////////////////////////////

export const ExchangeMenu = () => {
  const [resInputID, setResInputID] = useState(-1)
  const [resInputValue, setResInputValue] = useState(0)
  const [resOutputID, setResOutputID] = useState(-1)

  let resOutputValue = 0
  resOutputID >= 0 && resInputID >= 0
    ? (resOutputValue = Math.floor(
        resInputValue / (resList[resOutputID].value / resList[resInputID].value)
      ))
    : ''

  return (
    <Unit.G_GP.MenuCategory>
      <div css={Unit.G_GP.main.gpMenuItem_div}>
        <div css={s.titleBox_div}>
          <h3>Black market exchanger</h3>
          <img css={s.npcIcon_img} src={res_dealer} />
          <h3>Exchange rate</h3>
          <h2>
            {resInputID >= 0 ? resList[resInputID].value : 0} :{' '}
            {resOutputID >= 0 ? resList[resOutputID].value : 0}
          </h2>
        </div>

        <div css={s.resListWrapper_div}>
          <ResListBox
            useStateIDSetter={setResInputID}
            tradeValue={resInputValue}
            activeItem={resInputID}
            useStateValueSetter={setResInputValue}
            listTitle='Input'
          />
          <ResListBox
            useStateIDSetter={setResOutputID}
            tradeValue={resOutputValue}
            activeItem={resOutputID}
            listTitle='Output'
          />
        </div>
      </div>
    </Unit.G_GP.MenuCategory>
  )
}

///////////////////////////////////////////////////////////////////////////////////////////////////////////
// Components
///////////////////////////////////////////////////////////////////////////////////////////////////////////

const SetID = (useStateSetter: (action: number) => void, index: number) => {
  useStateSetter(index)
}

const ResListBox = (p: {
  useStateIDSetter: (action: number) => void
  activeItem: number
  useStateValueSetter?: (action: number) => void
  tradeValue: number
  listTitle: string
}) => {
  const maxInputValue = 1000000
  p.tradeValue > maxInputValue && p.useStateValueSetter !== undefined
    ? p.useStateValueSetter(maxInputValue)
    : ''

  return (
    <div css={s.resListBox_div}>
      <h3>{p.listTitle}</h3>
      <ResList useStateSetter={p.useStateIDSetter} activeItem={p.activeItem} />
      <div>
        <span css={s.focusTitle}>Selected: </span>
        {p.activeItem >= 0 ? resList[p.activeItem].name : 'Nothing?'}
      </div>
      {p.useStateValueSetter !== undefined ? (
        <Unit.G.InputFormSet
          minLimit={0}
          maxLimit={maxInputValue}
          currentValue={p.tradeValue}
          useStateValueSetter={p.useStateValueSetter}
        />
      ) : (
        <div css={s.focusValue}>{p.tradeValue.toLocaleString('en-US')} units</div>
      )}
    </div>
  )
}

const ResList = (p: { useStateSetter: (action: number) => void; activeItem: number }) => {
  return (
    <>
      {resImgList.map((item, index) => {
        return (
          <img
            css={[s.resIcon_img, p.activeItem === index ? s.resIcon_img_selectActive : '']}
            src={item}
            key={index}
            onClick={() => {
              SetID(p.useStateSetter, index)
            }}
            className='focusBorder'
          />
        )
      })}
    </>
  )
}
