/** @jsxImportSource @emotion/react */
// Default
// import * as Unit from '../../Reusable'
import * as theme from '../../../theme_overmind'
import { css } from '@emotion/react'

// Others
import { GameContext } from '../../../gameContext'
import { useContext } from 'react'

// MDI
import { mdiAccountGroup } from '@mdi/js'
import { mdiBabyBottleOutline } from '@mdi/js'
import { mdiCogRefreshOutline } from '@mdi/js'
import { mdiEarth } from '@mdi/js'
import { mdiRadioactive } from '@mdi/js'
import { mdiSnowflakeThermometer } from '@mdi/js'
import { mdiSunThermometerOutline } from '@mdi/js'
import { mdiSwordCross } from '@mdi/js'
import { mdiWeatherCloudy } from '@mdi/js'
import { mdiWeatherFog } from '@mdi/js'
import { mdiWeatherLightning } from '@mdi/js'
import { mdiWeatherNight } from '@mdi/js'
import { mdiWeatherPouring } from '@mdi/js'
import { mdiWeatherSnowyHeavy } from '@mdi/js'
import { mdiWeatherSunny } from '@mdi/js'
import { mdiWeatherSunnyAlert } from '@mdi/js'
import { mdiWeatherTornado } from '@mdi/js'
import { mdiWeatherWindy } from '@mdi/js'
import Icon from '@mdi/react'

///////////////////////////////////////////////////////////////////////////////////////////////////////////
// CSS
///////////////////////////////////////////////////////////////////////////////////////////////////////////

const s = {
  mainWrapper_div: css({
    height: theme.main.layout.subInfoBar.height,
    position: `relative`,
    backgroundColor: theme.color.transparent_cover_dark,
    color: theme.color.focus,
  }),
  contentBox_div: css({
    width: `${theme.responsiveSize.layout.resBar.innerItem}%`,
    position: `absolute`,
    display: `flex`,
  }),
  leftContentBox_div: css({
    justifyContent: `right`,
  }),
  rightContentBox_div: css({
    right: 0,
  }),
  itemBox_div: css({
    margin: `0 ${theme.main.spacing.sm}`,
    display: `flex`,
    cursor: 'help',
  }),
  item_children: css({
    marginLeft: theme.main.spacing.xs,
  }),
  alert_status: css({
    color: theme.color.bug,
  }),
} as const

///////////////////////////////////////////////////////////////////////////////////////////////////////////
// Main
///////////////////////////////////////////////////////////////////////////////////////////////////////////

export const SubInfoBar = () => {
  const MOCK_DATA = {
    temperature: 27,
    currentWeatherType: 8,
    radioactivityLevel: 9,
    productivity: `110%`,
    birthrate: `30%`,
    discontent: `10%`,
    workers: 290,
  } //@Todo - replace MOCK!

  const weatherTypeList = [
    { name: 'Cloudy', mdi: mdiWeatherCloudy },
    { name: 'Fog', mdi: mdiWeatherFog },
    { name: 'Storm', mdi: mdiWeatherLightning },
    { name: 'Night', mdi: mdiWeatherNight },
    { name: 'Rain', mdi: mdiWeatherPouring },
    { name: 'Snow', mdi: mdiWeatherSnowyHeavy },
    { name: 'Sunny', mdi: mdiWeatherSunny },
    { name: 'Burning heat', mdi: mdiWeatherSunnyAlert },
    { name: 'Tornado', mdi: mdiWeatherTornado },
    { name: 'Wind', mdi: mdiWeatherWindy },
  ]

  return (
    <div css={s.mainWrapper_div}>
      <div css={[s.contentBox_div, s.leftContentBox_div]}>
        <Item mdiPath={mdiEarth}>
          <span css={s.item_children}>No ongoing event..</span>
        </Item>
        <Item mdiPath={weatherTypeList[MOCK_DATA.currentWeatherType].mdi} warning>
          <span css={s.item_children}>{weatherTypeList[MOCK_DATA.currentWeatherType].name}</span>
        </Item>
        <Item
          mdiPath={MOCK_DATA.temperature > 0 ? mdiSunThermometerOutline : mdiSnowflakeThermometer}
        >
          <span css={s.item_children}>{MOCK_DATA.temperature}°C</span>
        </Item>
        <Item mdiPath={mdiRadioactive} warning>
          <span css={s.item_children}>{MOCK_DATA.radioactivityLevel}</span>
        </Item>
      </div>

      <div css={[s.contentBox_div, s.rightContentBox_div]}>
        <Item mdiPath={mdiAccountGroup}>
          <span css={s.item_children}>205/{MOCK_DATA.workers}</span>
        </Item>
        <Item mdiPath={mdiCogRefreshOutline}>
          <span css={s.item_children}>{MOCK_DATA.productivity}</span>
        </Item>
        <Item mdiPath={mdiBabyBottleOutline}>
          <span css={s.item_children}>{MOCK_DATA.birthrate}</span>
        </Item>
        <Item mdiPath={mdiSwordCross}>
          <span css={s.item_children}>{MOCK_DATA.discontent}</span>
        </Item>
      </div>
    </div>
  )
}

///////////////////////////////////////////////////////////////////////////////////////////////////////////
// Components
///////////////////////////////////////////////////////////////////////////////////////////////////////////

export const Item = (p: { mdiPath: string; children: React.ReactNode; warning?: boolean }) => {
  const context = useContext(GameContext)

  return (
    <div
      css={[s.itemBox_div, p.warning ? s.alert_status : '']}
      className='focus'
      onClick={() => context.activeScreen.setActiveScreen(-1)}
    >
      <Icon path={p.mdiPath} size={0.8} /> <span>{p.children}</span>
    </div>
  )
}
