export const unitList = [
  {
    id: 'D-00', // ID = unique building's identification
    index: 0, // index = game UI index
    name: 'Explorer drone',
    unitType: 'mechanic',
    unitClass: 'spy',
    unlockAt: 1,

    resCost: {
      metal: 100,
      plastics: 80,
      electronics: 40,
      fuel: 20,
    },
    constructionRate: 400,

    stats: {
      hp: 300,
      def: 12,
      atk: 0,
      fuelConsumption: 10,
      speed: 300,
    },
  },
  {
    id: 'D-01',
    index: 1,
    name: 'Small cargo-transporter',
    unitType: 'mechanic',
    unitClass: 'logistics',
    unlockAt: 1,

    resCost: {
      metal: 100,
      plastics: 80,
      electronics: 40,
      fuel: 20,
    },
    constructionRate: 600,

    stats: {
      hp: 300,
      def: 12,
      atk: 0,
      fuelConsumption: 30,
      speed: 200,
    },
  },
  {
    id: 'D-02',
    index: 2,
    name: 'Large cargo-transporter',
    unitType: 'mechanic',
    unitClass: 'logistics',
    unlockAt: 5,

    resCost: {
      metal: 500,
      plastics: 880,
      electronics: 440,
      fuel: 220,
    },
    constructionRate: 3600,

    stats: {
      hp: 1300,
      def: 62,
      atk: 0,
      fuelConsumption: 70,
      speed: 100,
    },
  },
  {
    id: 'D-03',
    index: 3,
    name: 'Infantry transporter',
    unitType: 'mechanic',
    unitClass: 'logistics',
    unlockAt: 4,

    resCost: {
      metal: 100,
      plastics: 80,
      electronics: 40,
      fuel: 20,
    },
    constructionRate: 600,

    stats: {
      hp: 300,
      def: 12,
      atk: 0,
      fuelConsumption: 30,
      speed: 200,
    },
  },
  {
    id: 'D-04',
    index: 4,
    name: 'Scrap gatherer',
    unitType: 'organic',
    unitClass: 'logistics',
    unlockAt: 1,

    resCost: {
      metal: 20,
      plastics: 20,
      food: 160,
    },
    constructionRate: 200,

    stats: {
      hp: 100,
      def: 12,
      atk: 0,
      speed: 10,
    },
  },
  {
    id: 'D-05',
    index: 5,
    name: 'Technician',
    unitType: 'organic',
    unitClass: 'logistics',
    unlockAt: 2,

    resCost: {
      metal: 20,
      plastics: 20,
      food: 160,
    },
    constructionRate: 200,

    stats: {
      hp: 100,
      def: 12,
      atk: 0,
      speed: 10,
    },
  },
  {
    id: 'D-06',
    index: 6,
    name: 'Soldier',
    unitType: 'organic',
    unitClass: 'infantry',
    unlockAt: 2,

    resCost: {
      metal: 150,
      plastics: 80,
      food: 250,
    },
    constructionRate: 600,

    stats: {
      hp: 300,
      def: 12,
      atk: 20,
      speed: 20,
    },
  },
  {
    id: 'D-07',
    index: 7,
    name: 'Light offensive drone',
    unitType: 'mechanic',
    unitClass: 'mech',
    unlockAt: 3,

    resCost: {
      metal: 300,
      plastics: 500,
      electronics: 240,
      fuel: 220,
    },
    constructionRate: 800,

    stats: {
      hp: 300,
      def: 12,
      atk: 30,
      fuelConsumption: 30,
      speed: 200,
    },
  },
  {
    id: 'D-08',
    index: 8,
    name: 'Heavy offensive mech',
    unitType: 'mechanic',
    unitClass: 'mech',
    unlockAt: 8,

    resCost: {
      metal: 3500,
      plastics: 5020,
      electronics: 2410,
      fuel: 1220,
    },
    constructionRate: 5800,

    stats: {
      hp: 3050,
      def: 120,
      atk: 305,
      fuelConsumption: 230,
      speed: 50,
    },
  },
]
