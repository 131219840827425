/** @jsxImportSource @emotion/react */
// Default
import * as Unit from '../../../Reusable_overmind'
import * as theme from '../../../theme_overmind'
import { css } from '@emotion/react'

// Others
import { DefHQ_data, DefStats } from '../../../gameContext'
import { GameContext } from '../../../gameContext'
import { useContext } from 'react'

//Controller
import { UpdateHQLevel } from '../../../gameControllers/GameController'

// Local data
import { defenseHQData } from '../../../gameStaticData/MOCK/defenseHQData'
import defenseHQ from '../../../gameStaticData/images/HQs/defense_HQ.webp'

///////////////////////////////////////////////////////////////////////////////////////////////////////////
// CSS
///////////////////////////////////////////////////////////////////////////////////////////////////////////

const s = {
  failure: css({
    color: theme.color.bug,
    cursor: `help`,
  }),
} as const

///////////////////////////////////////////////////////////////////////////////////////////////////////////
// Main
///////////////////////////////////////////////////////////////////////////////////////////////////////////

export const DefenseHQ = () => {
  const context = useContext(GameContext)
  let defenseHQ_contextData: DefHQ_data = context.defenseHQ_data.defenseHQ_data
  UpdateHQLevel(defenseHQData.level, context.levelOfSelectedHQ.setLevelOfSelectedHQ)

  const MOCK_environmentStats = { heat: 50, cold: 0, radiation: 9, cyber: 2, force: 20 } //@Todo - replace MOCK!
  const MOCK_progressBar = [{ name: 'Force shield', reqTime: 100, processedTime: 90 }] //@Todo - replace MOCK!

  return (
    <Unit.G_MS.InnerScreenPattern
      dataBlock={{
        name: defenseHQData.name,
        level: defenseHQData.level,
        currentWorkers: 172,
        currentWorkplaces: defenseHQData.statsAtCurrentLevel.workers,
        nextWorkPlaces: defenseHQData.statsAtNextLevel.workers,
        currentConstructionSpeed: defenseHQData.statsAtCurrentLevel.constructionSpeed,
        nextConstructionSpeed: defenseHQData.statsAtNextLevel.constructionSpeed,
        currentBuildingCount: defenseHQ_contextData.currentBuildingLimit,
        currentBuildingLimit: defenseHQData.statsAtCurrentLevel.buildingsLimit,
        nextBuildingLimit: defenseHQData.statsAtNextLevel.buildingsLimit,
        currentPowerConsumption: 700,
        fullPowerConsumption: defenseHQ_contextData.powerConsumption,
        constructionRate: defenseHQData.statsAtNextLevel.constructionRate,
        resCost: defenseHQData.statsAtNextLevel.resCost,
      }}
      slotCount={defenseHQData.statsAtCurrentLevel.slots}
      img={defenseHQ}
      progressQueue={MOCK_progressBar}
    >
      <InfoTable
        defStats={defenseHQ_contextData.defStats}
        environmentStats={MOCK_environmentStats}
      />
    </Unit.G_MS.InnerScreenPattern>
  )
}

///////////////////////////////////////////////////////////////////////////////////////////////////////////
// Components
///////////////////////////////////////////////////////////////////////////////////////////////////////////

const InfoTable = (p: { defStats: DefStats; environmentStats: DefStats }) => {
  return (
    <>
      <Unit.G_MS.InfoTableHeader columnsCount={4}>
        <div>Type</div>
        <div>Shield</div>
        <div>Environment</div>
        <div>Failure</div>
      </Unit.G_MS.InfoTableHeader>

      {Object.entries(p.defStats).map((item, index) => {
        return (
          <InfoTableItem
            key={index}
            title={item[0]}
            shield={item[1]}
            environment={Object.values(p.environmentStats)[index]}
          />
        )
      })}
    </>
  )
}

const InfoTableItem = (p: { title: string; shield: number; environment: number }) => {
  const context = useContext(GameContext)
  return (
    <Unit.G_MS.InfoTableItem columnsCount={4}>
      <div>{p.title.charAt(0).toUpperCase() + p.title.slice(1)}:</div>
      <div>{p.shield.toLocaleString('en-US')}</div>
      <div>{p.environment.toLocaleString('en-US')}</div>
      <div>
        <span
          css={s.failure}
          className='focus'
          onClick={() => context.activeScreen.setActiveScreen(-1)}
        >
          {p.shield - p.environment > 0 ? '' : p.shield - p.environment}
        </span>
      </div>
    </Unit.G_MS.InfoTableItem>
  )
}
