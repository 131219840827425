/** @jsxImportSource @emotion/react */
// Default
import * as Unit from '../../../Reusable_overmind'
import * as theme from '../../../theme_overmind'
import { css } from '@emotion/react'

// Others
import { useState } from 'react'

// Local data
import { rationsImgList, rationsList } from '../../../gameStaticData/resImgList'

// MDI
import { mdiAccountGroup } from '@mdi/js'
import { mdiTimerRefreshOutline } from '@mdi/js'
import Icon from '@mdi/react'

///////////////////////////////////////////////////////////////////////////////////////////////////////////
// CSS
///////////////////////////////////////////////////////////////////////////////////////////////////////////

const s = {
  titleBox_div: css({
    textAlign: `center`,
    marginTop: theme.main.spacing.sm,
  }),
  rationsListWrapper_div: css({
    marginTop: theme.main.spacing.md,
    display: `flex`,
    justifyContent: `space-around`,
  }),
  rationsEffectsWrapper_div: css({
    margin: theme.main.spacing.md,
  }),
  rationIcon_img: css({
    width: theme.main.imgSizing.item,
    border: theme.main.border.dark,
    margin: theme.main.spacing.xxs,
  }),
  rationIcon_MDI: css({
    marginLeft: theme.main.spacing.xxs,
  }),
  cooldownIcon_MDI: css({
    marginRight: theme.main.spacing.xxs,
  }),
  rationInput: css({
    margin: `${theme.main.spacing.xs} 0`,
    padding: theme.main.spacing.xxs,
    color: theme.color.light,
    backgroundColor: theme.color.transparent_cover_dark,
    border: `none`,
  }),
  rationSubmit: css({
    cursor: `pointer`,
    margin: `${theme.main.spacing.xs} 0`,
    padding: theme.main.spacing.xxs,
    backgroundColor: theme.color.focus,
    border: `none`,
    fontWeight: 'bold',
  }),
  infoLine: css({
    display: `flex`,
    justifyContent: `center`,
  }),
  secTitleLine: css({
    fontSize: theme.main.font.sm,
    fontStyle: `italic`,
  }),
} as const

///////////////////////////////////////////////////////////////////////////////////////////////////////////
// Main
///////////////////////////////////////////////////////////////////////////////////////////////////////////

export const ColonyManagerMenu = () => {
  const [currentRationsRate, setCurrentRationsRate] = useState(3)

  const MOCK_populationSize = 290 //@Todo - replace MOCK!

  return (
    <Unit.G_GP.MenuCategory>
      <div css={Unit.G_GP.main.gpMenuItem_div}>
        <div css={s.titleBox_div}>
          <h3>Colony rations</h3>
          <p css={s.infoLine}>
            Population size: {MOCK_populationSize}
            <Icon path={mdiAccountGroup} size={0.8} css={s.rationIcon_MDI} />
          </p>
          <Unit.G.InputFormSet
            minLimit={1}
            maxLimit={10}
            currentValue={currentRationsRate}
            useStateValueSetter={setCurrentRationsRate}
          />
          <p css={[s.infoLine, s.secTitleLine, Unit.G.main.gCooldown]}>
            <Icon path={mdiTimerRefreshOutline} size={0.7} css={s.cooldownIcon_MDI} /> 24hrs
          </p>

          <RationsList populationSize={MOCK_populationSize} rationsRate={currentRationsRate} />
          <EffectInfoBox rationsRate={currentRationsRate} />
        </div>
      </div>
    </Unit.G_GP.MenuCategory>
  )
}

///////////////////////////////////////////////////////////////////////////////////////////////////////////
// Components
///////////////////////////////////////////////////////////////////////////////////////////////////////////

const RationsList = (p: { populationSize: number; rationsRate: number }) => {
  return (
    <div css={s.rationsListWrapper_div}>
      {rationsImgList.map((item, index) => {
        return (
          <div key={index}>
            <img css={s.rationIcon_img} src={item} />
            <p css={s.infoLine}>
              {(rationsImgList.length - index) * p.rationsRate} per
              <Icon path={mdiAccountGroup} size={0.8} css={s.rationIcon_MDI} />
              /day
            </p>
            <p css={s.secTitleLine}>
              {(p.populationSize * (rationsImgList.length - index) * p.rationsRate).toLocaleString(
                'en-US'
              )}
              /day
            </p>
            <p css={s.secTitleLine}>
              {Math.ceil(
                (p.populationSize * (rationsImgList.length - index) * p.rationsRate) / 24
              ).toLocaleString('en-US')}
              /hr
            </p>
          </div>
        )
      })}
    </div>
  )
}

const EffectInfoBox = (p: { rationsRate: number }) => {
  return (
    <div css={s.rationsEffectsWrapper_div}>
      <h3>Colony effects</h3>
      <div>Productivity: {rationsList[p.rationsRate - 1].productivity}%</div>
      <div>BirthRate: {rationsList[p.rationsRate - 1].birthRate}%</div>
      <div>Discontent: {rationsList[p.rationsRate - 1].discontent}%</div>
    </div>
  )
}
