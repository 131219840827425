// Images
import electronics from './images/items/resources/electronics_res.webp'
import food from './images/items/resources/food_res.webp'
import fuel from './images/items/resources/fuel_res.webp'
import metal from './images/items/resources/metal_res.webp'
import oxygen from './images/items/resources/oxygen_res.webp'
import plastics from './images/items/resources/plastics_res.webp'
import power from './images/items/resources/power_res.webp'
import water from './images/items/resources/water_res.webp'

export const resImgList = [metal, plastics, electronics, fuel, oxygen, water, food, power]
export const rationsImgList = [oxygen, water, food]

export const resList = [
  { name: 'metal', value: 1 },
  { name: 'plastics', value: 2 },
  { name: 'electronics', value: 3 },
  { name: 'fuel', value: 4 },
  { name: 'oxygen', value: 1 },
  { name: 'water', value: 2 },
  { name: 'food', value: 3 },
  { name: 'power', value: 4 },
] //@Todo - move to BE

export const rationsList = [
  { productivity: 75, birthRate: 0, discontent: 50 },
  { productivity: 100, birthRate: 0, discontent: 25 },
  { productivity: 100, birthRate: 0, discontent: 0 },
  { productivity: 100, birthRate: 25, discontent: -5 },
  { productivity: 100, birthRate: 50, discontent: -10 },
  { productivity: 80, birthRate: 100, discontent: -15 },
  { productivity: 60, birthRate: 150, discontent: -20 },
  { productivity: 40, birthRate: 200, discontent: -30 },
  { productivity: 20, birthRate: 250, discontent: -40 },
  { productivity: 0, birthRate: 300, discontent: -50 },
] //@Todo - move to BE
