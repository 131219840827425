export const productionHQData = {
  id: 'A', // ID = unique HQ / category identification
  name: 'Production command center',
  level: 5,

  statsAtCurrentLevel: {
    slots: 45,
    workers: 150,
    constructionSpeed: 1100,
    buildingsLimit: 27,
    storageLimits: {
      metal: 15000,
      plastics: 12000,
      electronics: 8000,
      fuel: 5000,
      oxygen: 2500,
      water: 2500,
      food: 1500,
      power: 3000,
    },
  },
  statsAtNextLevel: {
    slots: 46,
    workers: 250,
    constructionSpeed: 1800,
    buildingsLimit: 32,
    storageLimits: {
      metal: 21000,
      plastics: 16000,
      electronics: 11000,
      fuel: 8000,
      oxygen: 4500,
      water: 4500,
      food: 3500,
      power: 7000,
    },
    constructionRate: 300,
    resCost: {
      metal: 100,
      plastics: 80,
      electronics: 40,
      fuel: 20,
    },
  },
}
