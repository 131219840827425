export const techList = [
  {
    id: 'B-00', // ID = unique building's identification
    index: 0, // index = game UI index
    name: 'Combustion drive',
    level: 3,
    unlockAt: 1,

    statsAtCurrentLevel: {
      effect: 10,
    },
    statsAtNextLevel: {
      effect: 15,
      researchRate: 3000,
      resCost: {
        metal: 100,
        plastics: 80,
        electronics: 40,
        fuel: 20,
      },
    },
  },
  {
    id: 'B-01',
    index: 2,
    name: 'Reinforced armor',
    level: 4,
    unlockAt: 3,

    statsAtCurrentLevel: {
      effect: 210,
    },
    statsAtNextLevel: {
      effect: 315,
      researchRate: 3000,
      resCost: {
        metal: 100,
        plastics: 80,
        electronics: 40,
        fuel: 20,
      },
    },
  },

  {
    id: 'B-03',
    index: 3,
    name: 'Weapon systems',
    level: 4,
    unlockAt: 4,

    statsAtCurrentLevel: {
      effect: 210,
    },
    statsAtNextLevel: {
      effect: 315,
      researchRate: 3000,
      resCost: {
        metal: 100,
        plastics: 80,
        electronics: 40,
        fuel: 20,
      },
    },
  },
]
