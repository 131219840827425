/** @jsxImportSource @emotion/react */
// Default
import * as Unit from '../../../Reusable_overmind'
import * as theme from '../../../theme_overmind'
import { css } from '@emotion/react'

// Others
import { GameContext } from '../../../gameContext'
import { useContext } from 'react'

// Local data
import { dealerImg } from '../../../gameStaticData/dealerImgList'
import { hackerImg } from '../../../gameStaticData/intelOfferImgList'
import { intelOfferList } from '../../../gameStaticData/intelOfferImgList'
import { officerList } from '../../../gameStaticData/officerImgList'
import { techImgList } from '../../../gameStaticData/techImgList'
import blackMarket from '../../../gameStaticData/images/HQs/black_market.webp'

// MDI
import { mdiAlert } from '@mdi/js'
import Icon from '@mdi/react'

///////////////////////////////////////////////////////////////////////////////////////////////////////////
// CSS
///////////////////////////////////////////////////////////////////////////////////////////////////////////

const s = {
  mainWrapper_div: css({
    position: `absolute`,
    width: theme.main.layout.screen.innerScreen.slotsDataBoxWidth,
    top: theme.main.layout.screen.innerScreen.mainDataBoxTopPosition,
    left: theme.main.layout.screen.innerScreen.slotsDataBoxLeftPosition,
    color: theme.color.light,
    backgroundColor: theme.color.transparent_cover_dark,
    border: theme.main.border.dark,
  }),
  innerWrapper_div: css({
    display: `flex`,
    justifyContent: `space-around`,
    padding: theme.main.spacing.sm,
    fontSize: theme.main.font.md_header,
  }),
  title_div: css({
    cursor: `pointer`,
  }),
  activeTitle_div: css({
    color: theme.color.focus,
  }),
  content_div: css({
    margin: theme.main.spacing.xs,
    display: `flex`,
  }),
  itemBox_div: css({
    width: `${100 / officerList.length}%`,
    textAlign: `center`,
  }),
  itemInnerBox_div: css({
    margin: theme.main.spacing.xs,
    padding: theme.main.spacing.sm,
    color: theme.color.dark,
    overflowY: `scroll`,
    height: theme.main.layout.screen.innerScreen.blackMarketHeight,
  }),
  item_img: css({
    width: theme.main.imgSizing.npc,
    margin: theme.main.spacing.xs,
    border: theme.main.border.dark,
  }),
  focusTitle: css({
    fontWeight: `bold`,
  }),
  effectBox_div: css({
    margin: `${theme.main.spacing.xs} 0 `,
  }),
  description: css({
    marginTop: theme.main.spacing.xs,
    fontSize: theme.main.font.sm,
    fontStyle: `italic`,
  }),
  focusValue: css({
    color: theme.color.light,
    textShadow: theme.main.shadow.text.main,
    fontStyle: `italic`,
  }),
  focusEffectValue: css({
    color: theme.color.bug,
    fontSize: theme.main.font.md_header,
    textShadow: theme.main.shadow.text.main,
    fontWeight: `bold`,
  }),
  focusEffectValue_div: css({
    margin: `${theme.main.spacing.sm} 0`,
  }),
  specialistAction_btn: css({
    margin: theme.main.spacing.xs,
    padding: `${theme.main.spacing.xs} ${theme.main.spacing.sm}`,
    border: theme.main.border.bug,
    fontWeight: `bold`,
    backgroundColor: theme.color.transparent_cover_light,
    cursor: `pointer`,
  }),
  npcTile: css({
    backgroundColor: theme.color.transparent_cover_light,
  }),
} as const

///////////////////////////////////////////////////////////////////////////////////////////////////////////
// Main
///////////////////////////////////////////////////////////////////////////////////////////////////////////

export const BlackMarket = () => {
  const context = useContext(GameContext)
  const activeScreen = context.activeMarketScreen.activeMarketScreen

  // Active tab display changer
  const ChangeActiveTabContent = (index: number) => {
    context.activeMarketScreen.setActiveMarketScreen(index)
  }

  const screens = ['Shady hacker', 'Dealer', 'Specialists', 'Dark underworld']
  const npcDescription = {
    hacker: {
      name: 'Shady hacker',
      img: hackerImg,
      description: [
        'Welcome my friend!',
        'If you are looking for the secrets of the powerful Overmind, then you are in the right place.',
        'Everything in this world has its value, including me...',
        'So?',
        'What exactly would you be interested in?',
      ],
    },
    dealer: {
      name: 'Dealer',
      description: [
        'Hey, you!',
        'No quick movements, no tricks!',
        'Looking for something specific?',
        'Weapons, devices, components, equipment, supplies or perhaps some cheap workforce?',
        'I can get anything!',
        'So have we a deal?',
      ],
    },
  }

  const MOCK_itemData = {
    testingItem: {
      name: 'High tech armor',
      img: techImgList[1],
      price: 25000,
      currency: 'electronics',
      quality: 4,
      description: 'Testing item data',
    },
  }

  return (
    <Unit.G_MS.ScreenPattern img={blackMarket}>
      <div css={s.mainWrapper_div}>
        <div css={s.innerWrapper_div}>
          {screens.map((item, index) => {
            return (
              <TitleTile
                activeTab={activeScreen}
                index={index}
                key={index}
                onClick={ChangeActiveTabContent}
              >
                {item}
              </TitleTile>
            )
          })}
        </div>

        <div css={s.content_div}>
          {/* Hacker */}
          {activeScreen === 0 ? (
            <NpcTile
              name={npcDescription.hacker.name}
              description={npcDescription.hacker.description}
              img={hackerImg}
            />
          ) : (
            ''
          )}
          {activeScreen === 0
            ? intelOfferList.map((item, index) => {
                return IntelOfferTile(item, index)
              })
            : ''}

          {/* Dealer */}
          {activeScreen === 1 ? (
            <>
              <NpcTile
                name={npcDescription.dealer.name}
                description={npcDescription.dealer.description}
                img={dealerImg}
              />
              <ItemTile itemData={MOCK_itemData.testingItem} />
              <Unit.G_MS.ShopOfferBar count={105} />
            </>
          ) : (
            ''
          )}

          {/* Specialists */}
          {activeScreen === 2
            ? officerList.map((item, index) => {
                return SpecialistTile(item, index)
              })
            : ''}
        </div>
      </div>
      <Unit.G_MS.SlotBar count={40} />
    </Unit.G_MS.ScreenPattern>
  )
}

///////////////////////////////////////////////////////////////////////////////////////////////////////////
// Components
///////////////////////////////////////////////////////////////////////////////////////////////////////////

const TitleTile = (p: {
  children: React.ReactNode
  activeTab: number
  index: number
  onClick: (a: number) => void
}) => (
  <div
    css={[s.title_div, p.activeTab === p.index ? s.activeTitle_div : '']}
    className='nav'
    onClick={() => p.onClick(p.index)}
  >
    {p.children}
  </div>
)

const SpecialistTile = (
  item: {
    name: string
    img: string
    description: string
    price: number
    duration: number
    effects: { description: string; value: number }[]
    quality: number
  },
  index: number
) => {
  return (
    <div css={s.itemBox_div} key={index}>
      <div css={[s.itemInnerBox_div, Unit.G_Q.QualityBG(item.quality)]} className='microScroll'>
        <h3>{item.name}</h3>
        <img css={s.item_img} src={item.img} />
        <p>
          <span css={s.focusTitle}>Price:</span>{' '}
          <span css={s.focusValue}>{item.price.toLocaleString('en-US')} intel</span>
        </p>
        <p>
          <span css={s.focusTitle}>Duration:</span>{' '}
          <span css={s.focusValue}>{item.duration} days</span>
        </p>
        <button css={s.specialistAction_btn} className='submit'>
          Hire now!
        </button>
        <div>
          {item.effects.map((item: { description: string; value: number }, index: number) => {
            return (
              <div css={s.effectBox_div} key={index}>
                <p css={s.focusTitle}>Effect[{index + 1}]:</p>
                <p css={s.description}>{item.description}</p>
                {item.value > 0 ? (
                  <p css={s.focusEffectValue}>{item.value}%</p>
                ) : item.value === 0 ? (
                  ''
                ) : (
                  <p css={s.focusEffectValue}>ULTIMATE</p>
                )}
              </div>
            )
          })}
        </div>
        <p css={s.description}>{item.description}</p>
      </div>
    </div>
  )
}

const NpcTile = (p: { name: string; description: string[]; img: string }) => {
  return (
    <div css={s.itemBox_div}>
      <div css={[s.itemInnerBox_div, s.npcTile]} className='microScroll'>
        <h3>{p.name}</h3>
        <img css={s.item_img} src={p.img} />

        {p.description.map((item, index) => {
          return (
            <p css={s.description} key={index}>
              {item}
            </p>
          )
        })}
      </div>
    </div>
  )
}

const ItemTile = (p: {
  itemData: {
    name: string
    img: string
    price: number
    currency: string
    quality: number
    description: string
  }
}) => {
  return (
    <div css={s.itemBox_div}>
      <div
        css={[s.itemInnerBox_div, s.npcTile, Unit.G_Q.QualityBG(p.itemData.quality)]}
        className='microScroll'
      >
        <h3>{p.itemData.name}</h3>
        <img css={s.item_img} src={p.itemData.img} />

        <div css={s.focusTitle}>Price:</div>
        <div css={s.focusValue}>
          <p>{p.itemData.price.toLocaleString('en-US')}</p> <p>{p.itemData.currency}</p>
        </div>

        <div css={s.focusEffectValue_div}>
          <div css={s.focusTitle}>Description:</div>
          <div css={s.focusValue}>
            <p>{p.itemData.description}</p>
          </div>
        </div>

        <button css={s.specialistAction_btn} className='submit'>
          Buy now!
        </button>
      </div>
    </div>
  )
}

const IntelOfferTile = (
  item: {
    name: string
    img: string
    price: number
    amount: number
    bonusAmountMultiplier: number
    quality: number
  },
  index: number
) => {
  return (
    <div css={s.itemBox_div} key={index}>
      <div css={[s.itemInnerBox_div, Unit.G_Q.QualityBG(item.quality)]} className='microScroll'>
        <h3>{item.name}</h3>
        <img css={s.item_img} src={item.img} />
        <p>
          <span css={s.focusTitle}>Price:</span>{' '}
          <span css={s.focusValue}>{item.price.toLocaleString('en-US')} CZK</span>
        </p>
        <p>
          <span css={s.focusTitle}>Offer:</span>{' '}
          <span css={s.focusValue}>{item.amount.toLocaleString('en-US')} intel</span>
        </p>
        <button css={s.specialistAction_btn} className='submit'>
          Buy now!
        </button>

        {item.amount * item.bonusAmountMultiplier - item.amount > 0 ? (
          <>
            <div css={s.focusEffectValue_div}>
              <Icon path={mdiAlert} size={2} />
              <p css={s.focusEffectValue}>{Math.floor(item.bonusAmountMultiplier * 100 - 100)}%</p>
            </div>
            <p css={s.focusTitle}>Bonus:</p>
            <p>
              <span css={s.focusValue}>
                + {(item.amount * item.bonusAmountMultiplier - item.amount).toLocaleString('en-US')}{' '}
                intel
              </span>
            </p>

            <div css={s.focusEffectValue_div}>
              <p css={s.focusTitle}>Total:</p>{' '}
              <p css={s.focusValue}>
                {(item.amount * item.bonusAmountMultiplier).toLocaleString('en-US')} intel
              </p>
            </div>
          </>
        ) : (
          ''
        )}
      </div>
    </div>
  )
}
