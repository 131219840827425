// Images
import dealer from './images/npc/dealer.webp'

export const dealerImg = dealer

export const intelOfferList = [
  {
    name: 'Small package',
    amount: 1000,
    price: 100,
    quality: 0,
    bonusAmountMultiplier: 1,
    img: dealer,
  },
] //@Todo - move to BE
