/** @jsxImportSource @emotion/react */
// Default Router
import { Global } from '@emotion/react'
import { GlobalStyles } from './theme'

// Others
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { LayoutWrapper } from './bricks/Layout'

// Pages
import { Career } from './pages/Career'
import { Courses } from './pages/Courses'
import { HomePage } from './pages/HomePage'
import { ReactCourse } from './pages/courses/reactCourse/ReactCourse'

// Documents
import { GDPR } from './pages/GDPR'
import { VOP } from './pages/VOP'

// Dev
import { Dev } from './pages/Dev'
import { DevOvermind } from './pages/DevOvermind'

//////////////////////////////////////////////////////////////////////////////////////
// Courses ///////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////

// React Js
import { Checkers } from './pages/courses/reactCourse/examples/checkers/Checkers'
import { Chess } from './pages/courses/reactCourse/examples/chess/Chess'
import { RockScissorsPaperGame } from './pages/courses/reactCourse/examples/rockScissorsPaperGame/RockScissorsPaperGame'
import { SealBreaker } from './pages/courses/reactCourse/examples/sealBreaker/SealBreaker'
import { Snake } from './pages/courses/reactCourse/examples/snake/Snake'
import { TicTacToe } from './pages/courses/reactCourse/examples/ticTacToe/TicTacToe'
import { ToDoApp } from './pages/courses/reactCourse/examples/toDoApp/TodoApp'

//////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////

// Nav menu routes
export const navLinkRoutes = {
  HomePage: { to: `/`, title: `Intro` },
  // Courses: { to: `/kurzy`, title: `Kurzy` },
  React: { to: `/react`, title: `React` },
  Career: { to: `/kariera`, title: `Kariéra` },
}

// Documents
export const documents = {
  VOP: { to: `/VOP`, title: `VOP` },
  GDPR: { to: `/GDPR`, title: `GDPR` },
}

// Course routes
export const courseRoutes = {
  React: {
    to: `/react`,
    title: `React`,
    rockScissorsPaperGame: { to: `/react/lekce-5`, title: `Kámen, nůžky, papír` },
    ticTacToe: { to: `/react/lekce-6`, title: `Piškvorky` },
    sealBreaker: { to: `/react/lekce-7`, title: `Seal breaker` },
    todoApp: { to: `/react/lekce-8`, title: `Todo App` },
    snake: { to: `/react/lekce-9`, title: `Had` },
    checkers: { to: `/react/lekce-10`, title: `Dáma` },
    chess: { to: `/react/lekce-13`, title: `Šachy` },
  },
}

///////////////////////////////////////////////////////////////////////////////////////////////////////////
// CSS
///////////////////////////////////////////////////////////////////////////////////////////////////////////

export const s = {} as const

///////////////////////////////////////////////////////////////////////////////////////////////////////////
// Main
///////////////////////////////////////////////////////////////////////////////////////////////////////////

export function RoutesList() {
  return (
    <BrowserRouter>
      <LayoutWrapper>
        <Global styles={GlobalStyles} />
        <Routes>
          {/* Pages */}
          <Route path={navLinkRoutes.HomePage.to} element={<HomePage />} />
          {/* <Route path={navLinkRoutes.Courses.to} element={<Courses />} /> */}
          <Route path={navLinkRoutes.React.to} element={<ReactCourse />} />
          <Route path={navLinkRoutes.Career.to} element={<Career />} />

          {/* Documents */}
          <Route path={documents.GDPR.to} element={<GDPR />} />
          <Route path={documents.VOP.to} element={<VOP />} />

          {/* Courses */}
          {/* React */}
          <Route path={courseRoutes.React.to} element={<ReactCourse />} />
          <Route
            path={courseRoutes.React.rockScissorsPaperGame.to}
            element={<RockScissorsPaperGame />}
          />
          <Route path={courseRoutes.React.ticTacToe.to} element={<TicTacToe />} />
          <Route path={courseRoutes.React.sealBreaker.to} element={<SealBreaker />} />
          <Route path={courseRoutes.React.todoApp.to} element={<ToDoApp />} />
          <Route path={courseRoutes.React.snake.to} element={<Snake />} />
          <Route path={courseRoutes.React.checkers.to} element={<Checkers />} />
          <Route path={courseRoutes.React.chess.to} element={<Chess />} />

          {/* Dev */}
          {/* <Route path={`/dev`} element={<Dev />} /> */}
          <Route path={`/dev_secret_hidden_tab`} element={<DevOvermind />} />
        </Routes>
      </LayoutWrapper>
    </BrowserRouter>
  )
}

///////////////////////////////////////////////////////////////////////////////////////////////////////////
// Components
///////////////////////////////////////////////////////////////////////////////////////////////////////////
