/** @jsxImportSource @emotion/react */
// Default
import * as Unit from '../../../Reusable'
import * as theme from '../../../theme'
import { css } from '@emotion/react'

// Others
import { WingedHeader } from '../../../bricks/WingedHeader'
import { courseRoutes } from '../../../Routes'

// Images
import course from './images/course.webp'
import degree from './images/degree.webp'

// mdi
import { mdiCogPlayOutline } from '@mdi/js'
import Icon from '@mdi/react'

///////////////////////////////////////////////////////////////////////////////////////////////////////////
// CSS
///////////////////////////////////////////////////////////////////////////////////////////////////////////

export const s = {
  degreeDiv: css({
    marginTop: theme.main.spacing.lg,
    marginBottom: theme.main.spacing.md,
  }),
  degree: css({
    width: `70%`,
    position: `relative`,
    left: `50%`,
    transform: `translate(-50%, 0)`,

    [theme.mq.tablet]: {
      width: '100%',
    },
  }),
  ul: css({
    listStyle: `none`,
  }),
  legend: css({
    marginBottom: theme.main.spacing.sm,
    fontSize: theme.main.font.lg,
    textAlign: `right`,

    [theme.mq.tablet]: {
      marginTop: theme.main.spacing.sm,
    },

    [theme.mq.mobile]: {
      marginTop: 0,
      fontSize: theme.main.font.md,
    },
  }),
  curriculum: css({
    width: `50%`,
    margin: `auto`,
    fontSize: theme.main.font.lg,

    [theme.mq.mobile]: {
      width: '85%',
      fontSize: theme.main.font.md,
      marginBottom: theme.main.spacing.sm,
    },
  }),
} as const

///////////////////////////////////////////////////////////////////////////////////////////////////////////
// Main Components
///////////////////////////////////////////////////////////////////////////////////////////////////////////

export const ReactCourse = () => {
  Unit.ModulesInitialization()
  GTM_ListenerInit('react')

  return (
    <>
      <WingedHeader
        img={course}
        name={'React Js'}
        lecturer={'Matthew Antirix'}
        level={'Komplexní / Obsáhlý'}
        req={'Lze zvládnout i bez znalostí'}
        type={'100% online'}
        price={17999}
        // discountPrice={7499}
        discountDate={'Sleva do 31.8.2024'}
        duration={'80 hodin + lektor na drátě'}
        basicCourse={{ id: 'A', duration: '80 hodin', paymentLink: '', discountPaymentLink: '' }}
        extensions={[
          {
            id: 'B',
            title: 'Tvorba vlastní hry',
            duration: '20 hodin',
            price: 2999,
            // discountPrice: ,
            paymentLink: '',
            discountPaymentLink: '',
          },
          // {
          //   id: '-C-',
          //   title: 'Tvorba aplikace',
          //   duration: '???',
          //   price: '???',
          //   discountPrice: '',
          //   paymentLink: '',
          //   discountPaymentLink: '',
          // },
          {
            id: '',
            title: 'React Js A+B',
            duration: '100 hodin',
            price: 19999,
            // discountPrice: 9499,
            paymentLink: '',
            discountPaymentLink: '',
          },
        ]}
      />

      <Unit.AppContent>
        <Unit.AppH1>
          <strong>Specifikace kurzu</strong>
        </Unit.AppH1>
        <Unit.AppH2>
          <strong>Start příštího kurzu je 2.&nbsp;ledna (&nbsp;čtvrtek 17:00 - 19:30&nbsp;)</strong>
        </Unit.AppH2>
        <Unit.AppP>
          Online lekce probíhají každé úterý a čtvrtek v časovém rozmezí 17:00 - 19:30. Kurz
          obsahuje 32 takovýchto lekcí a trvá tedy v celkovém rozsahu 16 týdnů. Rozhodně nejde o
          žádný pseudo rychlo kurz, jak z tebe vytáhnout peníze a nalít ti do hlavy veškeré
          informace za jediný víkend!. Pokud nestíháš nevadí, lekce jsou nahrávány a ptát se můžeš i
          mimo toto časové okno!
        </Unit.AppP>

        <Unit.AppH2>
          <strong>Lektor kurzu k tvým službám</strong>
        </Unit.AppH2>
        <Unit.AppP>
          Lektor tohoto kurzu tu je pro tebe i mimo skupinové online lekce. Na každý týden máš zadán
          i úkol na zpracování. Náš lektor není žádný učitel, tak jak to znáš ze školy, ale tvůj
          kolega / parťák, tak jak tě to bude čekat i při práci v tomto oboru. To znamená, že tu
          není pouze od toho, aby si odučil své hodiny, opravil ti úkol a konec.. V žádném případě,
          mnohdy budou mít úkoly i mnoho správných řešení, nebo nepochopíš co se po tobě chce, nebo
          prostě jen jednoduše nebudeš mít vůbec tucha jak to vyřešit. Lektor je ti v tomto zcela k
          dispozici, máš na něj přímý kontakt a dle jeho časových možností s ním můžeš řešit vše co
          ti bude stát v cestě ke zvládnutí tohoto kurzu!
        </Unit.AppP>

        <Unit.AppH2>
          <strong>Pro koho je kurz určen, co potřebuji znát ?</strong>
        </Unit.AppH2>
        <Unit.AppP>
          Ano je pravdou, že práce v Reactu je složitější než jen základní HTML či CSS. Přesto je
          tento kurz navržen tak, aby se v rychlosti probraly i tyto základy a to včetně
          javascriptu. Nebudeme mít sice tolik času se podívat v rámci těchto základů úplně na vše,
          ale rozhodně tě to nemusí odradit. Jednotlivé lekce jsou navrženy, tak aby tě seznámily se
          vším co budeš potřebovat na splnění dané úrovně. Seznámíme tě i s postupy jak případně
          takovéto informace vyhledat.. no a když ani to nebude stačit, což se také může stát. Tak
          je tu pořád tvůj parťák lektor, který ti s takovými problémy hravě pomůže. Vesměs reálně v
          praxi.. i ti zkušenější mnohdy dostanou zadání, na které neznají řešení a musí se poradit
          buď s internetem nebo kolegy, zvážit možnosti a učinit nějaké rozhodnutí..
        </Unit.AppP>

        <Unit.AppH2>
          <strong>Jak kurz probíhá ?</strong>
        </Unit.AppH2>
        <Unit.AppP>
          Základem jsou online lekce, kde se vždy probere dané téma a zadá se úkol na vypracování.
          Tyto úkoly budou ve skutečnosti dílčí části tvého portfolia, které si sám / sama vytvoříš
          a které budeš moci ukázat i svým známým. Nejde o žádné ukázky na úrovni typu tlačítko co
          umí zvýšit nebo snížit číslo o 1 atd. ano je potřeba se naučit tyto principy, ale rozhodně
          to není nic čím by stálo za to se chlubit.. úkoly, které tě budou čekat budou zpravidla
          funkční hry, které si budeš moci reálně zahrát. Nejenom, že se naučíš jak sestavit nějakou
          hru, naučíš se i jak ji modifikovat nebo dokonce vytvořit svoji vlastní.. ano vesměs
          budeme demonstrovat sílu programování na hrách, ale pokud zvládneš tohle, zvládneš
          sestavit i různé aplikace jiného charakteru.
        </Unit.AppP>

        <Unit.AppH2>
          <strong>Skupinový projekt ?</strong>
        </Unit.AppH2>
        <Unit.AppP>
          Přesně tak, jedna věc je umět programovat, umět řešit problémy a dojít k nějakému závěru.
          Nicméně fatální nedostatky zpravidla nebývají na této úrovni, ale až při práci v týmu.
          Většina větších aplikací není reálně zvládnutelná v jedné osobě, tedy alespoň ne v
          rozumném časovém horizontu. Navíc u her toto pravidlo platí dvojnásob! V rámci projektu si
          vyzkoušíš jaké je to pracovat v týmu, jak pracovat s kódem, na kterém současně pracuje
          více osob, jak fungovat a organizovat práci v týmu. Možná si říkáš.. na tom přece nic
          není! No opak je bohužel pravdou, protože krutou realitou je, že většina projektů se
          mnohdy ani nedokončí právě kvůli nefungujícímu týmu.. v tomto kurzu nejde ani, tak o to
          jestli tvůj projekt skončí úspěšně nebo ne. Jde o to zjistit co vše to obnáší a být
          připraven / připravena na vše co může nastat.
        </Unit.AppP>

        <Unit.AppH2>Komunikační kanály</Unit.AppH2>
        <Unit.AppP>
          <ul css={s.ul}>
            <li>Online lekce: Zoom</li>
            <li>Revize úkolů: Gitlab</li>
            <li>Další komunikace: Discord</li>
          </ul>
        </Unit.AppP>

        <Unit.AppH2>Nějaké další dotazy?</Unit.AppH2>
        <Unit.AppP>
          Pokud tě zajímá i něco dalšího co ti tu chybí, klidně nám napiš na náš email:
          info@hexsquad.cz, nebo rovnou zavolej na tel. 723 584 131.
        </Unit.AppP>

        <Unit.AppH2 alignCenter>
          <strong>Rozpis lekcí dle týdnů</strong>
        </Unit.AppH2>
        <Unit.AppP>
          <ol css={s.curriculum}>
            <li>Představení HTML / CSS</li>
            <li>Práce s javascriptem 1/2</li>
            <li>Práce s javascriptem 2/2</li>
            <li>Začínáme s Reactem</li>
            <LessonLink
              to={courseRoutes.React.rockScissorsPaperGame.to}
              title={'Kámen, nůžky, papír'}
            />
            <LessonLink to={courseRoutes.React.ticTacToe.to} title={'Piškvorky'} />
            <LessonLink to={courseRoutes.React.sealBreaker.to} title={'Seal breaker'} />
            <LessonLink to={courseRoutes.React.todoApp.to} title={'Todo appka'} />
            <LessonLink to={courseRoutes.React.snake.to} title={'Had'} />
            <LessonLink to={courseRoutes.React.checkers.to} title={'Dáma 1/2'} />
            <LessonLink to={courseRoutes.React.checkers.to} title={'Dáma 2/2'} />

            <li>Context: theme & LSI moduly</li>

            <Unit.AppH2 alignCenter>Skupinový projekt: Šachy</Unit.AppH2>
            <li>
              {/* <Unit.AppLink to={courseRoutes.React.chess.to} blank> */}
              Začínáme
              {/* </Unit.AppLink> */}
            </li>
            <li>
              {/* <Unit.AppLink to={courseRoutes.React.chess.to} blank> */}
              Blížíme se do finále
              {/* </Unit.AppLink> */}
            </li>
            <li>
              {/* <Unit.AppLink to={courseRoutes.React.chess.to} blank> */}
              Prezentace projektu
              {/* </Unit.AppLink> */}
            </li>
            <li>Q&A, vyhodnocení kurzu</li>
          </ol>
          <p css={s.legend}>
            <Icon path={mdiCogPlayOutline} size={1} /> = spustitelná ukázka
          </p>
        </Unit.AppP>

        <Unit.AppH2 alignCenter>
          <strong>Navazující rozšíření kurzu</strong>
        </Unit.AppH2>
        <Unit.AppH2>
          <strong>Tvorba vlastní hry // -B-</strong>
        </Unit.AppH2>
        <Unit.AppP>
          Jde o navazující část kurzu Reactu, tato část vždy plynule navazuje po skončení základního
          kurzu. Je tedy možné se buď zapsat souběžně s hlavním kurzem nebo i samostatně, předpoklad
          je však již znalost práce s Reactem!
        </Unit.AppP>
        <Unit.AppP>
          Obsahem této části je 8 online lekcí (20 hodin + lektor na drátě), ve kterých budeš
          vytvářet a sestavovat hru dle vlastního výběru a to buď samostatně nebo dle účasti a
          domluvy je možnost i práce v týmu. Rozsah hry je však limitován možnostmi Reactu a časovým
          oknem nepřesahujícím max. 2 týdny po skončení kurzu. (tedy celkem 1,5 měsíce). Co je nebo
          naopak není reálné v rámci možností bude obsahem úvodu kurzu. Všeobecně vzato jde o hru
          obdobné složitosti a rozsahu jako je finální projekt základní části, (šachy). Do tohoto
          limitu tedy rozhodně nepatří MMO hry ani žádné hry vyžadující engine. Hrám tohoto typu
          bude věnován samostaný kurz věnovaný přímo této problematice.
        </Unit.AppP>

        <Unit.AppH2>
          <strong>Vývoj aplikace // -C-</strong>
        </Unit.AppH2>
        <Unit.AppP>
          Toto rozšíření je prozatím ve fázi příprav a bude zpřístupněno po publikaci kurzu
          Backendu.
        </Unit.AppP>

        <div css={s.degreeDiv}>
          <Unit.AppH1 alignCenter>Certifikát kurzu</Unit.AppH1>
          <img src={degree} css={s.degree} alt='Course degree' />
        </div>
      </Unit.AppContent>
    </>
  )
}

///////////////////////////////////////////////////////////////////////////////////////////////////////////
// Components
///////////////////////////////////////////////////////////////////////////////////////////////////////////

//////////////////////////// GTM ////////////////////////////
const GTM_ListenerInit = (pageType: string) => {
  //@ts-ignore
  window.dataLayer = window.dataLayer || []
  //@ts-ignore
  window.dataLayer.push({
    page_type: pageType,
  })
}
//////////////////////////// GTM ////////////////////////////

const LessonLink = (p: { to: string; title: string }) => (
  <li>
    <span onClick={() => GTM_ListenerInit('lesson')}>
      <Unit.AppLink to={p.to} blank>
        <Icon path={mdiCogPlayOutline} size={1} /> {p.title}
      </Unit.AppLink>
    </span>
  </li>
)
