/** @jsxImportSource @emotion/react */
// Default
import * as Unit from '../Reusable'
import * as theme from '../theme'
import { css } from '@emotion/react'

// Others
import { TypeAnimation } from 'react-type-animation'
import { keyframes } from '@emotion/react'
import { navLinkRoutes } from '../Routes'
import { useRef, useState } from 'react'

// MDI
import { mdiCookieCog, mdiMenu, mdiRobotAngry, mdiVolumeHigh, mdiVolumeOff } from '@mdi/js'
import { mdiDomain } from '@mdi/js'
import Icon from '@mdi/react'

// Images
import comgateIcon from '../images/layout/comgate.png'
import facebookIcon from '../images/layout/facebook.webp'
import instagramIcon from '../images/layout/instagram.webp'
import logo from '../images/layout/logo.gif'
import mailIcon from '../images/layout/mail.webp'
import mainBg from '../images/layout/mainBg.webp'
import toolNav from '../images/layout/toolNav.webp'

// Audio
import ReactAudioPlayer from 'react-audio-player'
import cyberPunk from '../audio/cyberPunk.mp3'

// Local data
import { briefingLog } from '../localData/briefingLog'

///////////////////////////////////////////////////////////////////////////////////////////////////////////
// CSS
///////////////////////////////////////////////////////////////////////////////////////////////////////////

const spin = keyframes`
  from {
      transform: rotate(0deg);
    }
  to {
      transform: rotate(360deg);
    }
`

export const s = {
  // LAYOUT //
  layoutWrapper: css({
    width: `${theme.responsiveSize.layout.mainWrapper.width}vw`,
    height: `${theme.responsiveSize.layout.mainWrapper.height}vh`,
    backgroundColor: theme.color.darkBg,
    backgroundImage: `url(${mainBg})`,
    backgroundSize: `${theme.responsiveSize.layout.mainWrapper.width}vw ${theme.responsiveSize.layout.mainWrapper.height}vh`,
    color: theme.color.main,
  }),
  mobileNav: css({
    position: `fixed`,
    height: `${theme.responsiveSize.layout.mobileNav.height}vh`,
    width: `${theme.responsiveSize.layout.mainWrapper.width}vw`,
    backgroundColor: theme.color.navBar,
    padding: `${theme.main.spacing.xxs} ${theme.main.spacing.sm} ${theme.main.spacing.xxs} ${theme.main.spacing.xxs}`,
    textAlign: 'right',
    display: `none`,
    zIndex: 1000,

    [theme.mq.tablet]: {
      display: `block`,
    },
  }),
  mobileNavBtn: css({
    margin: `0 ${theme.main.spacing.xxs}`,
    border: `1px solid black`,
    background: theme.color.secL1,
  }),
  navBar: css({
    position: 'fixed',
    top: 0,
    height: `${theme.responsiveSize.layout.mainWrapper.height}vh`,

    [theme.mq.tablet]: {
      width: `${theme.responsiveSize.layout.screen.widthMobile}vw`,
      height: `${
        theme.responsiveSize.layout.mainWrapper.height -
        theme.responsiveSize.layout.mobileNav.height
      }vh`,
      left: `50%`,
      transform: `translate(-50%, 0)`,
      margin: 0,
      paddingTop: `${theme.responsiveSize.layout.mobileNav.height}vh`,
      display: `none`,
    },

    [theme.mq.mobile]: {
      width: `${theme.responsiveSize.layout.mainWrapper.width}vw`,
      left: 0,
      transform: `translate(0, 0)`,
    },
  }),
  leftNav: css({
    left: 0,
    width: `${theme.responsiveSize.layout.leftNav.width}vw`,
  }),
  rightNav: css({
    right: 0,
    width: `${theme.responsiveSize.layout.rightNav.width}vw`,
  }),
  screen: css({
    width: `${theme.responsiveSize.layout.screen.width}vw`,
    height: `${theme.responsiveSize.layout.mainWrapper.height}vh`,
    margin: `0 ${theme.responsiveSize.layout.rightNav.width}vw 0 ${theme.responsiveSize.layout.leftNav.width}vw`,
    fontSize: `${theme.main.font.md}`,
    padding: theme.main.spacing.sm,
    overflowX: `hidden`,
    overflowY: `scroll`,
    background: theme.color.screen,

    [theme.mq.tablet]: {
      width: `${theme.responsiveSize.layout.mainWrapper.width}vw`,
      margin: 0,
      paddingTop: `${theme.responsiveSize.layout.mobileNav.height}vh`,
    },
  }),
  navBarContent: css({
    margin: theme.main.spacing.sm,
    padding: theme.main.spacing.sm,

    [theme.mq.tablet]: {
      width: `100%`,
      margin: 0,
      padding: 0,
    },

    [theme.mq.mobile]: {
      padding: theme.main.spacing.xs,
    },
  }),
  href: css({
    color: theme.color.main,
    textDecoration: `none`,
    padding: `0 ${theme.main.spacing.sm}`,
  }),
  // TOOL NAVIGATION //
  toolDiv: css({
    position: 'relative',
    width: '100%',
    height: '100%',
    margin: 'auto',
  }),
  toolImg: css({
    width: '100%',
    height: '100%',
    borderRadius: theme.main.borderRadius.round,
    animation: `${spin} 60s infinite`,

    [theme.mq.tablet]: {
      position: 'relative',
      left: '25%',
      width: '50%',
      height: '50%',
    },
  }),
  toolNavBtn: css({
    position: 'absolute',
    left: 0,
    margin: 'auto',
    border: `none`,
    borderRadius: theme.main.borderRadius.round,
    backgroundColor: 'transparent',
    color: theme.color.main,
    cursor: 'pointer',
  }),
  muteBtn: css({
    transform: `translate( -50%, -50%)`,

    [theme.mq.tablet]: {
      transform: `translate( 100%, 0%)`,
    },
  }),
  cookiesNavBtn: css({
    transform: `translate( -80%, 50%)`,

    [theme.mq.tablet]: {
      transform: `translate( 100%, 150%)`,
    },
  }),
  toolNavDocBox: css({
    width: `100%`,
    position: `absolute`,
    transform: `translate(0, -100%)`,
    padding: `0 ${theme.main.spacing.md}`,
    display: `flex`,
    justifyContent: `space-around`,
    textShadow: theme.main.shadow.text.main,
    backgroundColor: theme.color.secD4,

    [theme.mq.tablet]: {
      padding: `0 ${theme.main.spacing.xl}`,
    },

    [theme.mq.mobile]: {
      padding: `0 ${theme.main.spacing.lg}`,
    },
  }),
  toolNavDocIcon: css({
    cursor: `pointer`,
  }),
  toolNavIconBox: css({
    width: `${theme.responsiveSize.layout.leftNav.width}vw`,
    position: 'absolute',
    bottom: 0,
    display: `flex`,
    justifyContent: `space-around`,
    padding: `0 ${theme.main.spacing.xs}`,
    backgroundColor: theme.color.secD3,

    [theme.mq.tablet]: {
      width: `${theme.responsiveSize.layout.mainWrapper.width}vw`,
      bottom: `50px`,
      left: `-${(100 - theme.responsiveSize.layout.screen.widthMobile) / 2}vw`,
      padding: `0 ${theme.main.spacing.xl}`,
    },

    [theme.mq.mobile]: {
      width: `${theme.responsiveSize.layout.mainWrapper.width}vw`,
      left: 0,
      padding: `0 ${theme.main.spacing.xs}`,
    },

    [theme.mq.minSizeCorrector]: {
      bottom: 0,
    },
  }),
  toolNavIcon: css({
    width: `50px`,
    height: `50px`,
    margin: theme.main.spacing.xxs,
    backgroundColor: theme.color.lightBg,
    borderRadius: `10%`,
    cursor: `pointer`,
  }),
  comgateIcon: css({
    width: `100%`,
    height: `50px`,
  }),
  defaultModal: css({
    width: `100%`,
    height: `100%`,
    position: `absolute`,
    top: 0,
    left: 0,
    zIndex: 3000,
  }),
  contactModal: css({
    backgroundColor: theme.color.contact,
  }),
  cookiesModal: css({
    backgroundColor: theme.color.cookies,
  }),
  defaultModalBox: css({
    width: `90%`,
    position: 'absolute',
    bottom: 0,
    margin: `0 5%`,
    padding: `${theme.main.spacing.sm} ${theme.main.spacing.md}`,
    backgroundColor: theme.color.darkBg,
    textAlign: `center`,

    [theme.mq.laptop]: {
      padding: `${theme.main.spacing.sm} ${theme.main.spacing.xs}`,
    },

    [theme.mq.tablet]: {
      bottom: theme.main.spacing.lg,
      padding: `${theme.main.spacing.sm} ${theme.main.spacing.md}`,
    },
  }),
  contactModalBox: css({
    border: theme.main.border.main,
    color: theme.color.main,
  }),
  cookiesModalBox: css({
    border: theme.main.border.bug,
    color: theme.color.bug,
  }),
  cookiesModalContent: css({
    textAlign: `left`,
  }),
  defaultSubmit: css({
    padding: `${theme.main.spacing.xs} ${theme.main.spacing.md}`,
    fontWeight: `bold`,
    cursor: `pointer`,
  }),
  contactSubmit: css({
    border: theme.main.border.main,
  }),
  cookiesSubmit: css({
    border: theme.main.border.bug,
  }),
  contactBoxContent: css({
    fontWeight: `normal`,
    margin: `${theme.main.spacing.sm} 0`,
  }),
  contactText: css({
    marginBottom: theme.main.spacing.sm,
  }),
  contactMiniText: css({
    fontSize: theme.main.font.xs,
    margin: 0,
  }),
  // BRIEFING NAVIGATION //
  logoDiv: css({
    width: '200px',
    height: '200px',
    margin: 'auto',
  }),
  logoImg: css({
    width: '100%',
    height: '100%',
    borderRadius: theme.main.borderRadius.round,
  }),
  briefingHeader: css({
    height: '10vh',
    borderRadius: theme.main.borderRadius.contentBox,
    marginTop: theme.main.spacing.xs,
    padding: `${theme.main.spacing.sm} ${theme.main.spacing.xs}`,
    background: theme.color.navBar,
    textAlign: 'center',
    fontWeight: 'bold',
    fontStyle: 'italic',
    [theme.mq.tablet]: {
      height: '6vh',
      padding: `${theme.main.spacing.xxs} ${theme.main.spacing.xs}`,
    },
  }),
  briefingBox: css({
    position: 'relative',
    height: '45vh',
    borderRadius: theme.main.borderRadius.contentBox,
    marginTop: theme.main.spacing.xs,
    padding: theme.main.spacing.sm,
    background: theme.color.navBar,

    [theme.mq.tablet]: {
      paddingBottom: theme.main.spacing.md,
    },
  }),
  briefingContent: css({
    maxHeight: '30vh',
    overflowY: 'scroll',
    textAlign: 'justify',
  }),
  briefingStatus: css({
    position: 'absolute',
    bottom: '2.5vh',
    fontStyle: 'italic',
  }),
  landingPage: css({
    width: `${theme.responsiveSize.layout.landingPage.width}vw`,
    margin: `0 ${(100 - theme.responsiveSize.layout.landingPage.width) / 2}vw`,
    padding: `0 ${(100 - theme.responsiveSize.layout.landingPage.width) / 2}vw`,

    [theme.mq.tablet]: {
      padding: theme.main.spacing.sm,
    },
  }),
  overmind: css({
    width: `100vw`,
    height: `100vh`,
    margin: `0`,
    padding: `0`,
    overflowY: `hidden`,
  }),
  hide: css({
    display: `none`,
  }),
  footer: css({
    textAlign: `center`,
  }),
} as const

///////////////////////////////////////////////////////////////////////////////////////////////////////////
// Main
///////////////////////////////////////////////////////////////////////////////////////////////////////////

export const LayoutWrapper = (p: { children: React.ReactNode }) => {
  const [isLandingPageOn, setIsLandingPageOn] = useState(window.location.pathname === '/')
  const [isOvermindOn, setIsOvermindOn] = useState(
    window.location.pathname === '/dev_secret_hidden_tab'
  )

  return (
    <div css={s.layoutWrapper}>
      <ReactAudioPlayer src={cyberPunk} loop={true} volume={0.2} id={'Audio'} />

      {isOvermindOn ? (
        <Screen content={p.children} overmind />
      ) : isLandingPageOn ? (
        <>
          <MobileNav />
          <ToolNavigation landingPage />
          <Screen content={p.children} landingPage />
          <BriefingNavigation landingPage />
        </>
      ) : (
        <>
          <MobileNav />
          <ToolNavigation />
          <Screen content={p.children} />
          <BriefingNavigation />
        </>
      )}
    </div>
  )
}

///////////////////////////////////////////////////////////////////////////////////////////////////////////
// Components
///////////////////////////////////////////////////////////////////////////////////////////////////////////

export const MobileNav = () => {
  const [isOnLeftNav, setIsOnLeftNav] = useState(false)
  const [isOnRightNav, setIsOnRightNav] = useState(false)
  const LeftNavSwitch = () => {
    setIsOnLeftNav(!isOnLeftNav)
    setIsOnRightNav(false)
  }
  const RightNavSwitch = () => {
    setIsOnRightNav(!isOnRightNav)
    setIsOnLeftNav(false)
  }

  return (
    <nav css={s.mobileNav}>
      <button
        css={s.mobileNavBtn}
        onClick={() => [Unit.ShowNav(`BriefingNav`, isOnRightNav), RightNavSwitch()]}
      >
        <Icon path={mdiRobotAngry} size={1} />
      </button>
      <button
        css={s.mobileNavBtn}
        onClick={() => [Unit.ShowNav(`ToolNav`, isOnLeftNav), LeftNavSwitch()]}
      >
        <Icon path={mdiMenu} size={1} />
      </button>
    </nav>
  )
}

export const ToolNavigation = (p: { landingPage?: boolean }) => {
  const windowWidth = useRef(window.innerWidth)
  let isDesktop = windowWidth.current > theme.responsiveSize.layout.mobileNav.tabletSize

  let getCookies = Unit.GetCookies('Cookies')
  let isAudioAllow = Unit.GetCookies('isAudioAllow')
  let isAudioPause = Unit.GetCookies('isAudioPause')
  const [isCookiesModalOn, setIsCookiesModalOn] = useState(false)
  const [isContactModalOn, setIsContactModalOn] = useState(false)
  const [isMuted, setIsMuted] = useState(isAudioAllow && !isAudioPause ? false : true)
  const MuteSwitch = () => {
    localStorage.setItem('isAudioPause', JSON.stringify(!isMuted))
    setIsMuted(!isMuted)
    Unit.AudioMute(isMuted)
  }
  return (
    <aside css={[s.navBar, s.leftNav, p.landingPage ? s.hide : '']} id={'ToolNav'}>
      {getCookies ? isCookiesModalOn ? <ShowCookies /> : '' : <ShowCookies />}
      {isContactModalOn ? <ShowContact hideContact={setIsContactModalOn} /> : ''}
      <div css={s.navBarContent}>
        <nav css={s.toolDiv}>
          <img css={s.toolImg} src={toolNav} alt='Tool navigation icon' />
          <button onClick={() => MuteSwitch()} css={[s.toolNavBtn, s.muteBtn]} id={'MuteBtn'}>
            <Icon path={isMuted ? mdiVolumeOff : mdiVolumeHigh} size={1.5} />
          </button>
          <button
            onClick={() => setIsCookiesModalOn(!isCookiesModalOn)}
            css={[s.toolNavBtn, s.cookiesNavBtn]}
            id={'CookiesBtn'}
          >
            <Icon path={mdiCookieCog} size={1.2} />
          </button>
        </nav>
        {Object.values(navLinkRoutes).map((item, index) => (
          <span key={index} onClick={() => Unit.ShowNav(`Screen`, true, isDesktop)}>
            <NavLink title={item.title} to={item.to}></NavLink>
          </span>
        ))}
      </div>
      <div css={s.toolNavIconBox}>
        <div css={s.toolNavDocBox}>
          <a href='https://hexsquad.cz/vop' target='_blank' rel='noreferrer' css={s.href}>
            <h4 css={s.toolNavDocIcon}>VOP</h4>
          </a>
          <a href='https://hexsquad.cz/gdpr' target='_blank' rel='noreferrer' css={s.href}>
            <h4 css={s.toolNavDocIcon}>GDPR</h4>
          </a>
        </div>
        <img
          css={s.toolNavIcon}
          src={mailIcon}
          alt='Contact icon'
          onClick={() => setIsContactModalOn(!isContactModalOn)}
        />
        <a
          href='https://www.facebook.com/profile.php?id=61557269135739&locale=cs_CZ'
          target='_blank'
          rel='noreferrer'
        >
          <img css={s.toolNavIcon} src={facebookIcon} alt='Facebook icon' />
        </a>

        <a href='https://www.instagram.com/hexsquadsro/' target='_blank' rel='noreferrer'>
          <img css={s.toolNavIcon} src={instagramIcon} alt='Instagram icon' />
        </a>
      </div>
    </aside>
  )
}

export const BriefingNavigation = (p: { landingPage?: boolean }) => {
  const briefingArray = briefingLog.sort((a, b) => 0.5 - Math.random())
  return (
    <aside css={[s.navBar, s.rightNav, p.landingPage ? s.hide : '']} id={'BriefingNav'}>
      <div css={s.navBarContent}>
        <div css={s.logoDiv}>
          <img css={s.logoImg} src={logo} alt='Hex Squad logo' />
        </div>
        <BriefingHeader />
        <div css={s.briefingBox}>
          <BriefingLog briefingLog={briefingArray} />
        </div>
        <footer css={s.footer}>
          <img css={s.comgateIcon} src={comgateIcon} alt='Comgate icon' />
          <small>&copy; Copyright 2024, Hex Squad s.r.o.</small>
        </footer>
      </div>
    </aside>
  )
}

export const Screen = (p: {
  content: React.ReactNode
  landingPage?: boolean
  overmind?: boolean
}) => {
  let getCookies = Unit.GetCookies('Cookies')
  let isMobile = window.innerWidth < theme.responsiveSize.layout.mobileNav.tabletSize

  return (
    <div
      css={[s.screen, p.landingPage ? s.landingPage : '', p.overmind ? s.overmind : '']}
      id='Screen'
    >
      {isMobile ? getCookies ? '' : <ShowCookies /> : ''}
      {p.content}
    </div>
  )
}

export const NavLink = (p: { to: string; title: string }) => {
  return (
    <Unit.AppLink to={p.to}>
      <Unit.advanced.linkFocusHover css={Unit.advanced.linkFocus}>
        {p.title}
      </Unit.advanced.linkFocusHover>
    </Unit.AppLink>
  )
}

export const BriefingHeader = () => {
  return (
    <div css={s.briefingHeader}>
      <TypeAnimation
        sequence={[
          3000,
          "If you cant't win, hex it!",
          3000,
          "If you cant't win, change the rules!",
          3000,
          `If you cant't win, change the rules! 
          .. and rule the WORLD!`,
        ]}
        wrapper='div'
        cursor={false}
        speed={50}
        deletionSpeed={50}
        omitDeletionAnimation={false}
        preRenderFirstString
        style={{
          fontSize: theme.main.font.sm,
          display: 'inline-block',
          whiteSpace: 'pre-line',
        }}
        repeat={Infinity}
      />
    </div>
  )
}

///////////////////////////////////////////////////////////////////////////////////////////////////////////
// Sub components
///////////////////////////////////////////////////////////////////////////////////////////////////////////

export const ShowCookies = () => {
  let getCookies = Unit.GetCookies('Cookies')
  let getGTMCookies = Unit.GetCookies('analytics_storage')
  let getMarketingCookies = Unit.GetCookies('marketing_storage')

  const [minReq, setMinReq] = useState(getCookies === 'minReq' ? true : false)
  const [allowAll, setAllowAll] = useState(getCookies !== 'allowAll' && getCookies ? false : true)
  const [cookieValue, setCookieValue] = useState(getCookies ? getCookies : 'allowAll')

  const [isAllowGTM, setIsAllowGTM] = useState(getGTMCookies === 'granted' ? true : false)
  const [cookieGTMValue, setCookieGTMValue] = useState(getGTMCookies ? getGTMCookies : 'granted')

  const [isAllowMarketing, setAllowIsMarketing] = useState(
    getMarketingCookies === 'granted' ? true : false
  )
  const [cookieMarketingValue, setCookieMarketingValue] = useState(
    getMarketingCookies ? getMarketingCookies : 'granted'
  )

  const SetCookieValues = () => {
    setMinReq(!minReq)
    setAllowAll(!allowAll)

    // !! one step behind !!
    allowAll ? setCookieValue('minReq') : setCookieValue('allowAll')
    setIsAllowGTM(true)
    setCookieGTMValue('granted')
    setAllowIsMarketing(true)
    setCookieMarketingValue('granted')
  }
  const SetGTMCookieValues = () => {
    allowAll ? '' : setIsAllowGTM(!isAllowGTM)
    isAllowGTM ? setCookieGTMValue('denied') : setCookieGTMValue('granted')
  }
  const SetMarketingCookieValues = () => {
    allowAll ? '' : setAllowIsMarketing(!isAllowMarketing)
    isAllowMarketing ? setCookieMarketingValue('denied') : setCookieMarketingValue('granted')
  }
  const SaveCookieSettings = (webCookies: string, GTMCookies: string, marketingCookies: string) => {
    localStorage.setItem('Cookies', JSON.stringify(webCookies))
    localStorage.setItem('analytics_storage', JSON.stringify(GTMCookies))
    localStorage.setItem('marketing_storage', JSON.stringify(marketingCookies))
  }

  return (
    <div id={'CookiesModal'} css={[s.defaultModal, s.cookiesModal]}>
      <div css={[s.defaultModalBox, s.cookiesModalBox]}>
        <Icon path={mdiCookieCog} size={3} />

        <Unit.AppContent>
          <div css={s.cookiesModalContent}>
            <span onClick={() => SetCookieValues()}>
              <Unit.AppCheckbox
                title={'Nezbytné'}
                miniText={['odemčení navigace', 'spustitelné ukázky']}
                isChecked={minReq}
              />
            </span>
            <span onClick={() => SetGTMCookieValues()}>
              <Unit.AppCheckbox
                title={'Analytika'}
                miniText={[
                  'analýza návštěvnosti a chování na webu',
                  'sběr dat pro optimalizaci webu',
                ]}
                isChecked={isAllowGTM}
              />
            </span>
            <span onClick={() => SetMarketingCookieValues()}>
              <Unit.AppCheckbox
                title={'Marketing'}
                miniText={['optimalizace reklamy', 'zpracování osobních údajů reklamními systémy']}
                isChecked={isAllowMarketing}
              />
            </span>
            <span onClick={() => SetCookieValues()}>
              <Unit.AppCheckbox
                title={'Vše'}
                miniText={['nezbytné', 'audio autoplay']}
                isChecked={allowAll}
              />
            </span>
          </div>
          <form>
            <button
              type='submit'
              onClick={() => SaveCookieSettings(cookieValue, cookieGTMValue, cookieMarketingValue)}
              css={[s.defaultSubmit, s.cookiesSubmit]}
            >
              Povolit
            </button>
          </form>
        </Unit.AppContent>
      </div>
    </div>
  )
}

export const ShowContact = (p: { hideContact: any }) => {
  return (
    <div id={'ContactModal'} css={[s.defaultModal, s.contactModal]}>
      <div css={[s.defaultModalBox, s.contactModalBox]}>
        <Icon path={mdiDomain} size={3} />

        <Unit.AppContent>
          <div css={s.contactBoxContent}>
            <p css={s.contactText}>Hex Squad s.r.o.</p>
            <p css={[s.contactText, s.contactMiniText]}>Bělehradská 858/23</p>
            <p css={[s.contactText, s.contactMiniText]}>Praha 12 000</p>
          </div>

          <div css={s.contactBoxContent}>
            <p css={s.contactText}>
              IČ: <br />
              213 68 643
            </p>
          </div>

          <div css={s.contactBoxContent}>
            <p css={s.contactText}>
              Tel.: +420 <br />
              723 584 131
            </p>
          </div>

          <div css={s.contactBoxContent}>
            <p css={s.contactText}>
              E-mail: <br />
              info@hexsquad.cz
            </p>
          </div>

          <button
            type='submit'
            css={[s.defaultSubmit, s.contactSubmit]}
            onClick={() => p.hideContact(false)}
          >
            Zavřít
          </button>
        </Unit.AppContent>
      </div>
    </div>
  )
}

export const BriefingLog = (p: { briefingLog: string[] }) => {
  const [typingStatus, setTypingStatus] = useState('Initializing')
  return (
    <div css={s.briefingContent} id='Briefing'>
      {/* @FixMe ==> DRY ERROR, many attempts for solving, but no working solution */}
      <TypeAnimation
        sequence={[
          // index 0
          1500,
          () => {
            setTypingStatus('Typing...')
          },
          p.briefingLog[0],
          () => {
            setTypingStatus('Done Typing')
          },
          10000,
          () => {
            setTypingStatus('Deleting...')
          },
          1000,
          '',
          () => {
            setTypingStatus('Done Deleting')
          },
          1000,
          '',
          () => {
            setTypingStatus('Thinking...')
          },
          // index 1
          1500,
          () => {
            setTypingStatus('Typing...')
          },
          p.briefingLog[1],
          () => {
            setTypingStatus('Done Typing')
          },
          10000,
          () => {
            setTypingStatus('Deleting...')
          },
          1000,
          '',
          () => {
            setTypingStatus('Done Deleting')
          },
          1000,
          '',
          () => {
            setTypingStatus('Thinking...')
          },
          // index 2
          1500,
          () => {
            setTypingStatus('Typing...')
          },
          p.briefingLog[2],
          () => {
            setTypingStatus('Done Typing')
          },
          10000,
          () => {
            setTypingStatus('Deleting...')
          },
          1000,
          '',
          () => {
            setTypingStatus('Done Deleting')
          },
          1000,
          '',
          () => {
            setTypingStatus('Thinking...')
          },
          // index 3
          1500,
          () => {
            setTypingStatus('Typing...')
          },
          p.briefingLog[3],
          () => {
            setTypingStatus('Done Typing')
          },
          10000,
          () => {
            setTypingStatus('Deleting...')
          },
          1000,
          '',
          () => {
            setTypingStatus('Done Deleting')
          },
          1000,
          '',
          () => {
            setTypingStatus('Thinking...')
          },
        ]}
        wrapper='div'
        cursor={true}
        speed={50}
        deletionSpeed={99}
        omitDeletionAnimation={false}
        style={{
          fontSize: theme.main.font.sm,
          display: 'inline-block',
          whiteSpace: 'pre-line',
        }}
        repeat={Infinity}
      />
      <p css={s.briefingStatus}>Hex bot status: {typingStatus}</p>
    </div>
  )
}
