/** @jsxImportSource @emotion/react */
// Default
// import * as Unit from '../../Reusable'
// import * as theme from '../../theme'
// import { css } from '@emotion/react'

// Others
import { ResBar } from './ResBar'
import { SubInfoBar } from './SubInfoBar'

///////////////////////////////////////////////////////////////////////////////////////////////////////////
// CSS
///////////////////////////////////////////////////////////////////////////////////////////////////////////

// const s = {} as const

///////////////////////////////////////////////////////////////////////////////////////////////////////////
// Main
///////////////////////////////////////////////////////////////////////////////////////////////////////////

export const MainInfoBar = () => {
  return (
    <>
      <ResBar />
      <SubInfoBar />
    </>
  )
}

///////////////////////////////////////////////////////////////////////////////////////////////////////////
// Components
///////////////////////////////////////////////////////////////////////////////////////////////////////////
