import { css } from '@emotion/react'

export const mq = {
  // Mobile – 360 x 640; 375 x 667; 360 x 720.
  // iPhone – 375 x 812.
  // Tablet – 768 x 1024.
  // Laptop – 1366 x 768.
  // Desktop or high-resolution laptop – 1920 x 1080.
  mobile: `@media (max-width: 500px)`,
  tabletSm: `@media (max-width: 650px)`,
  tablet: `@media (max-width: 850px)`,
  laptopSm: `@media (max-width: 1100px)`,
  laptop: `@media (max-width: 1500px)`,
  desktop: `@media (max-width: 2000px)`,
  outOfLimit: `@media (min-width: 2001px)`,
} as const

export const responsiveSize = {
  layout: {
    mainWrapper: {
      width: 100, //vw
      height: 100, //vh
    },
    resBar: {
      height: 6, //vh
      innerItem: 40, //%
    },
    subInfoBar: {
      height: 2, //vh
    },
    screen: {
      width: 75, //vw
      innerScreen: {
        mainDataBoxWidth: 40, //%
        mainDataBoxTopPosition: 3, //%
        mainDataBoxLeftPosition: 10, //%
        slotsDataBoxWidth: 85, //%
        slotsDataBoxBottomPosition: 2, //%
        slotsDataBoxLeftPosition: 5, //%
        slotsDataBoxImgFrame: 5, //px
        slotsDataBoxImgSize: 50, //px
        progressBarFragmentCount: 20, // pcs
        progressBarHeight: 20, //px
      },
    },
    gamePanel: {
      header: { height: 30 }, //vh
      categoryItem: {
        width: 80, //px
        itemHeader: 20, //px
      },
      itemImgSize: 150, //px
    },
  },
  font: {
    xs: 0.65, //rem
    sm: 0.85, //rem
    md: 1, //rem
    md_header: 1.3, //rem
    lg: 1.7, //rem
    xl: 2.5, //rem
    xxl: 4, //rem
  },
  spacing: {
    xxs: 0.3, //rem
    xs: 0.5, //rem
    sm: 1, //rem
    md: 2, //rem
    lg: 4, //rem
    xl: 6, //rem
  },
  borderRadius: {
    round: 100, //%
  },
  imgSizing: {
    npc: 120, //px
    item: 50, //px
  },
  buttons: {
    assignInputBtn: { width: 80 }, //px
  },
} as const

type stringArrayIndex = { [index: string]: string }
export const color: stringArrayIndex = {
  light: '#fff',
  lightBg: '#fff',

  dark: '#000',
  darkBg: '#000',

  main: '#0f0',
  secL1: `#3f3`,
  secL2: `#6f6`,
  secL3: `#9f9`,
  secL4: `#cfc`,
  secD1: `#0c0`,
  secD2: `#090`,
  secD3: `#060`,
  secD4: `#030`,

  focus: `#FFA500`,
  focusSec: `#00f`,

  link: `#fff`,
  bug: `#f00`,

  transparent_cover_dark: `rgba(0,0,0, 0.8)`,
  transparent_cover_light: `rgba(255,255,255, 0.8)`,

  q0: '#777', // Scrap
  q1: '#fff', // Normal
  q2: '#6f6', // Good
  q3: '#66f', // Rare
  q4: '#FFA500', // Epic
  q5: `#AE37ff`, // Overmind
}

export const main = {
  layout: {
    mainWrapper: {
      width: `${responsiveSize.layout.mainWrapper.width}vw`,
      height: `${responsiveSize.layout.mainWrapper.width}vh`,
    },
    resBar: {
      width: `${responsiveSize.layout.screen.width}vw`,
      height: `${responsiveSize.layout.resBar.height}vh`,
    },
    subInfoBar: {
      height: `${responsiveSize.layout.subInfoBar.height}vh`,
    },
    screen: {
      width: `${responsiveSize.layout.screen.width}vw`,
      height: `${
        responsiveSize.layout.mainWrapper.height -
        responsiveSize.layout.resBar.height -
        responsiveSize.layout.subInfoBar.height
      }vh`,
      modalScreen: {
        height: `${
          responsiveSize.layout.mainWrapper.height -
          responsiveSize.layout.resBar.height -
          responsiveSize.layout.subInfoBar.height -
          responsiveSize.layout.screen.innerScreen.mainDataBoxTopPosition * 2
        }vh`,
      },
      innerScreen: {
        mainDataBoxWidth: `${responsiveSize.layout.screen.innerScreen.mainDataBoxWidth}%`,
        mainDataBoxTopPosition: `${responsiveSize.layout.screen.innerScreen.mainDataBoxTopPosition}%`,
        mainDataBoxLeftPosition: `${responsiveSize.layout.screen.innerScreen.mainDataBoxLeftPosition}%`,
        progressBarWidth: `${
          100 -
          responsiveSize.layout.screen.innerScreen.mainDataBoxLeftPosition -
          responsiveSize.layout.screen.innerScreen.mainDataBoxWidth -
          responsiveSize.layout.screen.innerScreen.mainDataBoxLeftPosition / 4 -
          10
        }%`,
        progressBarTopPosition: `${responsiveSize.layout.screen.innerScreen.mainDataBoxTopPosition}%`,
        progressBarRightPosition: `${responsiveSize.layout.gamePanel.categoryItem.width * 1.5}px`,
        progressBarFragmentWidth: `${
          100 / responsiveSize.layout.screen.innerScreen.progressBarFragmentCount
        }%`,
        progressBarHeight: `${responsiveSize.layout.screen.innerScreen.progressBarHeight}px`,
        slotsDataBoxWidth: `${responsiveSize.layout.screen.innerScreen.slotsDataBoxWidth}%`,
        slotsDataBoxBottomPosition: `${responsiveSize.layout.screen.innerScreen.slotsDataBoxBottomPosition}%`,
        slotsDataBoxLeftPosition: `${responsiveSize.layout.screen.innerScreen.slotsDataBoxLeftPosition}%`,
        slotsDataBoxImgSize: `${responsiveSize.layout.screen.innerScreen.slotsDataBoxImgSize}px`,
        slotsDataBoxImgFrame: `${responsiveSize.layout.screen.innerScreen.slotsDataBoxImgFrame}px`,
        slotsDataBoxImgWithFrame: `${
          responsiveSize.layout.screen.innerScreen.slotsDataBoxImgSize +
          responsiveSize.layout.screen.innerScreen.slotsDataBoxImgFrame
        }px`,
        blackMarketHeight: `${
          (responsiveSize.layout.mainWrapper.height -
            responsiveSize.layout.resBar.height -
            responsiveSize.layout.screen.innerScreen.mainDataBoxTopPosition) /
          1.5
        }vh`,
      },
    },
    gamePanel: {
      width: `${responsiveSize.layout.mainWrapper.width - responsiveSize.layout.screen.width}vw`,
      height: `${responsiveSize.layout.mainWrapper.height}vh`,
      gpHeader: { height: `${responsiveSize.layout.gamePanel.header.height}vh` },
      gpMenu: {
        height: `${
          100 - responsiveSize.layout.resBar.height - responsiveSize.layout.gamePanel.header.height
        }vh`,
        itemImgSize: `${responsiveSize.layout.gamePanel.itemImgSize}px`,
      },
      gpCategory: {
        width: `${responsiveSize.layout.gamePanel.categoryItem.width}px`,
        height: `${
          responsiveSize.layout.gamePanel.categoryItem.width +
          responsiveSize.layout.gamePanel.categoryItem.itemHeader
        }px`,
        titleHeight: `${responsiveSize.layout.gamePanel.categoryItem.itemHeader}px`,
      },
    },
    navMenu: {
      width: `${responsiveSize.layout.mainWrapper.width - responsiveSize.layout.screen.width}vw`,
      height: `${responsiveSize.layout.resBar.height}vh`,
    },
  },
  imgSizing: {
    npc: `${responsiveSize.imgSizing.npc}px`,
    item: `${responsiveSize.imgSizing.item}px`,
  },
  font: {
    xs: `${responsiveSize.font.xs}rem`,
    sm: `${responsiveSize.font.sm}rem`,
    md: `${responsiveSize.font.md}rem`,
    md_header: `${responsiveSize.font.md_header}rem`,
    lg: `${responsiveSize.font.lg}rem`,
    xl: `${responsiveSize.font.xl}rem`,
    xxl: `${responsiveSize.font.xxl}rem`,
  },
  spacing: {
    xxs: `${responsiveSize.spacing.xxs}rem`,
    xs: `${responsiveSize.spacing.xs}rem`,
    sm: `${responsiveSize.spacing.sm}rem`,
    md: `${responsiveSize.spacing.md}rem`,
    lg: `${responsiveSize.spacing.lg}rem`,
    xl: `${responsiveSize.spacing.xl}rem`,
  },
  border: {
    main: `2px solid ${color.main}`,
    bug: `2px solid ${color.bug}`,
    dark: `2px solid ${color.darkBg}`,
    game: `2px solid ${color.focus}`,
    focus: `2px solid ${color.q5}`,
  },
  borderRadius: {
    round: `${responsiveSize.borderRadius.round}%`,
  },
  shadow: {
    text: {
      main: `-2px -2px 2px ${color.dark}, 2px -2px 2px ${color.dark}, -2px 2px 2px ${color.dark}, 2px 2px 2px ${color.dark}`,
    },
    box: {
      main: `0px 0px 10px ${color.main}`,
    },
  },
  buttons: {
    assignInputBtn: { width: `${responsiveSize.buttons.assignInputBtn.width}px` },
  },
} as const

export const GlobalStyles = css`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  body {
    width: 100vw;
    height: 100vh;
    overflow: hidden;
  }

  .nav:hover {
    color: ${color.main};
  }

  .focus:hover {
    color: ${color.q5};
  }

  .focusBG:hover {
    background-color: ${color.q5};
  }

  .focusBorder:hover {
    border: ${main.border.focus};
  }

  .submit:hover {
    color: ${color.light};
    background-color: ${color.bug};
    border: ${main.border.dark};
  }

  ::-webkit-scrollbar {
    width: 15px;
  }
  ::-webkit-scrollbar-track {
    background: transparent;
  }
  ::-webkit-scrollbar-thumb {
    background-color: ${color.transparent_cover_dark};
    border: 2px solid ${color.transparent_cover_light};
  }
  .logScroll::-webkit-scrollbar {
    width: 10px;
  }
  .microScroll::-webkit-scrollbar {
    width: 3px;
  }
  .microScroll::-webkit-scrollbar-thumb {
    background-color: ${color.transparent_cover_light};
    border: 0.1px solid ${color.dark};
  }
`
