export const researchHQData = {
  id: 'B', // ID = unique HQ / category identification
  name: 'Tech command center',
  level: 2,

  statsAtCurrentLevel: {
    slots: 15,
    workers: 150,
    researchSpeed: 1700,
    powerConsumption: 550,
  },
  statsAtNextLevel: {
    slots: 18,
    workers: 250,
    researchSpeed: 1900,
    powerConsumption: 760,
    constructionRate: 700,
    resCost: {
      metal: 100,
      plastics: 380,
      electronics: 640,
      fuel: 120,
    },
  },
}
