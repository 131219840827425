/** @jsxImportSource @emotion/react */
// Default
// import * as Unit from '../../Reusable'
import * as theme from '../../../theme_overmind'
import { css } from '@emotion/react'

// Others
import { GameContext } from '../../../gameContext'
import { useContext } from 'react'

// Local data
import { MainScreen } from '../MainScreen'
import { officerImgList } from '../../../gameStaticData/officerImgList'
import menuIcon from '../../../gameStaticData/images/UI/menu_icon.webp'

///////////////////////////////////////////////////////////////////////////////////////////////////////////
// CSS
///////////////////////////////////////////////////////////////////////////////////////////////////////////

const s = {
  navMenu_div: css({
    width: theme.main.layout.navMenu.width,
    height: theme.main.layout.navMenu.height,
    display: `flex`,
    justifyContent: `right`,
    backgroundColor: theme.color.transparent_cover_dark,
    color: theme.color.main,
  }),
  defaultItem_format: css({
    width: theme.main.layout.navMenu.height,
    height: `100%`,
    margin: theme.main.spacing.xxs,
    cursor: `pointer`,
  }),
  resItem_div: css({
    flex: `1`,
    textAlign: `center`,
    border: `none`,
    marginTop: theme.main.spacing.xs,
    paddingTop: theme.main.spacing.xxs,
  }),
  officer_img: css({
    border: theme.main.border.game,
  }),
  navItem_div: css({
    marginRight: theme.main.spacing.xs,
    border: theme.main.border.main,
    backgroundImage: `url(${menuIcon})`,
    backgroundSize: `100%`,
  }),
} as const

///////////////////////////////////////////////////////////////////////////////////////////////////////////
// Main
///////////////////////////////////////////////////////////////////////////////////////////////////////////

export const NavMenu = () => {
  const context = useContext(GameContext)

  // Active menu display changer
  const ChangeActiveMenuContent = (marketScreen: number) => {
    context.activeScreen.setActiveScreen(4)
    context.activeMarketScreen.setActiveMarketScreen(marketScreen)
  }

  let intel = 1250000 //@Todo - replace MOCK!

  return (
    <div css={s.navMenu_div}>
      <div
        css={[s.defaultItem_format, s.resItem_div]}
        className='focus'
        onClick={() => ChangeActiveMenuContent(0)}
      >
        <p>Intel</p>
        <p>{intel.toLocaleString('en-US')}</p>
      </div>
      {officerImgList.map((item, index) => (
        <img
          src={item}
          key={index}
          css={[s.defaultItem_format, s.officer_img]}
          className='focusBorder'
          onClick={() => ChangeActiveMenuContent(2)}
        />
      ))}
      <div css={[s.defaultItem_format, s.navItem_div]} className='focusBorder' />
    </div>
  )
}

///////////////////////////////////////////////////////////////////////////////////////////////////////////
// Components
///////////////////////////////////////////////////////////////////////////////////////////////////////////
