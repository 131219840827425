/** @jsxImportSource @emotion/react */
// Default Reusable
import * as theme from '../theme_overmind'
import { css } from '@emotion/react'
// import styled from '@emotion/styled'

// Controllers
import { Fn_G_ComposeResCostMessage } from '../gameControllers/Reusable'

// Reusable categories
import * as GP_Reusable from './GamePanel'

// Others
import { useState } from 'react'

// Images

///////////////////////////////////////////////////////////////////////////////////////////////////////////
// CSS - Main
///////////////////////////////////////////////////////////////////////////////////////////////////////////

export const main = {
  gInputFormAssign: css({
    width: theme.main.buttons.assignInputBtn.width,
    margin: `${theme.main.spacing.xs} 0`,
    padding: theme.main.spacing.xxs,
    textAlign: `center`,
    color: theme.color.dark,
    backgroundColor: theme.color.transparent_cover_light,
    border: `none`,
  }),
  gInputFormSet: css({
    margin: `${theme.main.spacing.xs} 0`,
    padding: theme.main.spacing.xxs,
    color: theme.color.light,
    backgroundColor: theme.color.transparent_cover_dark,
    border: `none`,
  }),
  gInputFormSubmit: css({
    cursor: `pointer`,
    margin: `${theme.main.spacing.xs} 0`,
    padding: theme.main.spacing.xxs,
    backgroundColor: theme.color.focus,
    border: `none`,
    fontWeight: 'bold',
  }),
  gFailure: css({
    color: theme.color.bug,
  }),
  gCooldown: css({
    color: theme.color.bug,
    fontWeight: `bold`,
  }),
  gFlex_div: css({
    display: `flex`,
    alignItems: `center`,
    justifyContent: `space-between`,
  }),
} as const

///////////////////////////////////////////////////////////////////////////////////////////////////////////
// Main Components
///////////////////////////////////////////////////////////////////////////////////////////////////////////

export type ResCost = {
  metal: number
  plastics: number
  electronics: number
  fuel: number
}

export type ResCostUnit = {
  metal?: number
  plastics?: number
  electronics?: number
  fuel?: number
  food?: number
}

export const CurrentLevelInfo = (p: { children: React.ReactNode }) => <div>{p.children}</div>

export const NextLevelInfo = (p: { children: React.ReactNode }) => {
  return <p css={GP_Reusable.main.gpItemNextLevelInfo_p}>(Next level: {p.children})</p>
}

export const BaseInfo = (p: { children: React.ReactNode }) => {
  return <p css={GP_Reusable.main.gpItemNextLevelInfo_p}>{p.children}</p>
}

export const NextLevelCostInfo = (p: { resCost: ResCost | ResCostUnit }) => {
  return (
    <div css={GP_Reusable.main.gpMenuItemInfoContent_div}>
      Cost requirements:
      <p css={GP_Reusable.main.gpItemNextLevelCostInfo_p}>
        {Fn_G_ComposeResCostMessage(p.resCost)}
      </p>
    </div>
  )
}

export const NextLevelBuildTimeInfo = (p: { children: React.ReactNode }) => {
  return <p css={GP_Reusable.main.gpItemNextLevelInfo_p}>({p.children})</p>
}

export const UpgradeBox = (p: {
  constructionRate: number
  resCost: ResCost | ResCostUnit
  alternativeType?: string
  unlockAt?: number
  hqLevel?: number
}) => {
  let title = 'Upgrade'
  if (p.alternativeType === 'Unit') {
    title = 'Train'
  } else if (p.alternativeType === 'Research') {
    title = 'Research'
  }

  const requiredLevel =
    (p.unlockAt && p.hqLevel && p.hqLevel >= p.unlockAt) || (!p.unlockAt && !p.hqLevel)
      ? true
      : false

  let actionHrs = Math.floor(p.constructionRate / 3600)
  let actionMin = Math.floor(p.constructionRate / 60) % 60
  let actionSec = p.constructionRate % 60

  let actionFormat = `${actionHrs ? `${actionHrs} hrs ` : ''}${
    actionMin ? `${actionMin} min ` : ''
  }${actionSec} sec`

  return (
    <div css={GP_Reusable.main.gpMenuItemFooter_div}>
      {requiredLevel ? (
        <>
          <button
            css={GP_Reusable.main.gpMenuItem_upgradeBtn}
            onClick={() => {}}
            className='focusBG'
          >
            {title} <NextLevelBuildTimeInfo>{actionFormat}</NextLevelBuildTimeInfo>
          </button>
          <NextLevelCostInfo resCost={p.resCost} />
        </>
      ) : (
        <>
          <button
            css={[
              GP_Reusable.main.gpMenuItem_upgradeBtn,
              GP_Reusable.main.gpMenuItem_upgradeBtn_locked,
            ]}
            className='submit'
          >
            Unlock at level{`\xa0`}
            {p.unlockAt}
          </button>
        </>
      )}
    </div>
  )
}

//@todo data update action!
export const InputFormAssign = (p: { currentValue: number; maxLimit: number }) => {
  const [currentValue, setCurrentValue] = useState(p.currentValue)
  return (
    <form>
      <input
        css={main.gInputFormAssign}
        type='number'
        min={0}
        max={p.maxLimit}
        value={currentValue}
        onChange={e => setCurrentValue(Number(e.target.value))}
      />
      <input type='submit' value='Assign' className='focusBG' css={main.gInputFormSubmit} />
    </form>
  )
}

// //@todo data update action!
export const InputFormSet = (p: {
  useStateValueSetter: (action: number) => void
  currentValue: number
  minLimit: number
  maxLimit: number
}) => {
  return (
    <form>
      <input
        css={main.gInputFormSet}
        type='number'
        min={p.minLimit}
        max={p.maxLimit}
        value={p.currentValue}
        onChange={e => p.useStateValueSetter(Number(e.target.value))}
      />
      <input type='submit' className='focusBG' css={main.gInputFormSubmit} />
    </form>
  )
}
