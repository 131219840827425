// Images
import coldInsulation from './images/defensiveBuildings/cold_insulation.webp'
import cyberShield from './images/defensiveBuildings/cyber_shield.webp'
import forceShield from './images/defensiveBuildings/force_shield.webp'
import heatShield from './images/defensiveBuildings/heat_shield.webp'
import radiationShield from './images/defensiveBuildings/radiation_shield.webp'

export const defensiveBuildingsImgList = [
  coldInsulation,
  heatShield,
  radiationShield,
  forceShield,
  cyberShield,
]
