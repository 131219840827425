//@todo - refactoring with server data -> Old controller !!!
//Only GameTick === FE fn

import { useEffect } from 'react'

import * as ResourcesController from './ResourcesController'

// Game refresh interval
export const GameTick = () => {
  useEffect(() => {
    const GameTickUpdate = setInterval(() => {
      ///@ Todo fix type!
      let x: any = GetData(`ProductionData`)
      if (x !== undefined) {
        ResourcesController.Update(x)
        ///@ Todo fix type!
      }
    }, 1000)

    return () => clearInterval(GameTickUpdate)
  })
}

// Load data from local storage
export const GetData = (targetName: string) => {
  const jsonData = localStorage.getItem(targetName)

  if (jsonData !== null) {
    const data: number[] = JSON.parse(jsonData)
    return data
  }
}

// Get HQ level
export const UpdateHQLevel = (level: number, setLevel: (level: number) => void) => {
  const SetHQLevel = (level: number, setLevel: (level: number) => void) => {
    setLevel(level)
  }

  useEffect(() => {
    SetHQLevel(level, setLevel)
  }, [])
}
