/** @jsxImportSource @emotion/react */
// Default
import * as Unit from '../Reusable'
import * as theme from '../theme'
import { css } from '@emotion/react'

// import { useEffect, useState } from 'react'

// Others
import { BlackMarket } from '../bricks/layout/mainScreen/BlackMarket'
import { CreateProductionBuildingList } from '../gameControllers/InitController'
import { LayoutWrapper } from '../bricks/layout/Layout'

// Images

///////////////////////////////////////////////////////////////////////////////////////////////////////////
// CSS
///////////////////////////////////////////////////////////////////////////////////////////////////////////

const s = {
  frame: css({
    width: `90vw`,
    height: `100vh`,
    overflow: 'scroll',
    margin: `auto`,
  }),
} as const

///////////////////////////////////////////////////////////////////////////////////////////////////////////
// Main
///////////////////////////////////////////////////////////////////////////////////////////////////////////

export const DevOvermind = () => {
  const validData = {
    food: 0,
    water: 0,
    oxygen: 0,
    power: 0,
    metal: 5000,
    plastics: 5000,
    electronics: 5000,
    fuel: 5000,
  }

  localStorage.setItem('ResData', JSON.stringify(validData))

  // let x = CreateProductionBuildingList()

  // const buildingsData = [0, 1, 2, 3, 1, 1, 2, 1, 2]

  // localStorage.setItem('BuildingsData', JSON.stringify(buildingsData))

  // CalculationController.CreateResProductionList()

  return (
    <>
      {/* <div css={s.frame}>{JSON.stringify(x)}</div> */}
      <LayoutWrapper />
    </>
  )
}

///////////////////////////////////////////////////////////////////////////////////////////////////////////
// Components
///////////////////////////////////////////////////////////////////////////////////////////////////////////
