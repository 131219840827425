/** @jsxImportSource @emotion/react */
// Default Reusable
import * as theme from '../theme_overmind'
import { css } from '@emotion/react'
// import styled from '@emotion/styled'

// Others

// Images

///////////////////////////////////////////////////////////////////////////////////////////////////////////
// CSS - Main
///////////////////////////////////////////////////////////////////////////////////////////////////////////

export const main = {
  q0_scrap: css({
    color: theme.color.q0,
  }),
  q1_normal: css({
    color: theme.color.q1,
  }),
  q2_good: css({
    color: theme.color.q2,
  }),
  q3_rare: css({
    color: theme.color.q3,
  }),
  q4_epic: css({
    color: theme.color.q4,
  }),
  q5_overmind: css({
    color: theme.color.q5,
  }),
  q0_scrap_bg: css({
    backgroundColor: theme.color.q0,
  }),
  q1_normal_bg: css({
    backgroundColor: theme.color.q1,
  }),
  q2_good_bg: css({
    backgroundColor: theme.color.q2,
  }),
  q3_rare_bg: css({
    backgroundColor: theme.color.q3,
  }),
  q4_epic_bg: css({
    backgroundColor: theme.color.q4,
  }),
  q5_overmind_bg: css({
    backgroundColor: theme.color.q5,
  }),
} as const

///////////////////////////////////////////////////////////////////////////////////////////////////////////
// Main Components
///////////////////////////////////////////////////////////////////////////////////////////////////////////

export const QualityColor = (quality: number) => {
  const qualityList = [
    main.q0_scrap,
    main.q1_normal,
    main.q2_good,
    main.q3_rare,
    main.q4_epic,
    main.q5_overmind,
  ]
  return qualityList[quality]
}

export const QualityBG = (quality: number) => {
  const qualityList = [
    main.q0_scrap_bg,
    main.q1_normal_bg,
    main.q2_good_bg,
    main.q3_rare_bg,
    main.q4_epic_bg,
    main.q5_overmind_bg,
  ]
  return qualityList[quality]
}
