export const defenseHQData = {
  id: 'C', // ID = unique HQ / category identification
  name: 'Defense command center',
  level: 8,

  statsAtCurrentLevel: {
    slots: 60,
    workers: 350,
    constructionSpeed: 2100,
    buildingsLimit: 14,
  },
  statsAtNextLevel: {
    slots: 72,
    workers: 450,
    constructionSpeed: 2600,
    buildingsLimit: 18,
    constructionRate: 1300,
    resCost: {
      metal: 400,
      plastics: 180,
      electronics: 240,
      fuel: 420,
    },
  },
}
