/** @jsxImportSource @emotion/react */
import { RoutesList } from './Routes'
import ReactDOM from 'react-dom/client'

// Others
import { GameContext } from './gameContext'
import { defenseHQ_default, productionHQ_default } from './gameContext'
import { useState } from 'react'

///////////////////////////////////////////////////////////////////////////////////////////////////////////
// CSS
///////////////////////////////////////////////////////////////////////////////////////////////////////////

///////////////////////////////////////////////////////////////////////////////////////////////////////////
// Main
///////////////////////////////////////////////////////////////////////////////////////////////////////////

export const App = () => {
  const [language, setLanguage] = useState('en')
  const [activeScreen, setActiveScreen] = useState(0)
  const [activeMarketScreen, setActiveMarketScreen] = useState(0)
  const [productionHQ_data, setProductionHQ_data] = useState(productionHQ_default)
  const [defenseHQ_data, setDefenseHQ_data] = useState(defenseHQ_default)
  const [levelOfSelectedHQ, setLevelOfSelectedHQ] = useState(0)

  const value = {
    language: { language, setLanguage },
    activeScreen: { activeScreen, setActiveScreen },
    activeMarketScreen: { activeMarketScreen, setActiveMarketScreen },
    productionHQ_data: { productionHQ_data, setProductionHQ_data },
    defenseHQ_data: { defenseHQ_data, setDefenseHQ_data },
    levelOfSelectedHQ: { levelOfSelectedHQ, setLevelOfSelectedHQ },
  }

  return (
    <GameContext.Provider value={value}>
      <RoutesList />
    </GameContext.Provider>
  )
}

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(<App />)
