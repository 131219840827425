/** @jsxImportSource @emotion/react */
// Default
import * as Unit from '../../../Reusable_overmind'
import * as theme from '../../../theme_overmind'
import { css } from '@emotion/react'

// Others
import { GameContext } from '../../../gameContext'
import { ProductionHQ_data, ResList } from '../../../gameContext'
import { useContext } from 'react'

//Controller
import { UpdateHQLevel } from '../../../gameControllers/GameController'

// Local data
import { productionHQData } from '../../../gameStaticData/MOCK/productionHQData'
import productionHQ from '../../../gameStaticData/images/HQs/production_HQ.webp'

///////////////////////////////////////////////////////////////////////////////////////////////////////////
// CSS
///////////////////////////////////////////////////////////////////////////////////////////////////////////

const s = {
  nextLevel_default: css({
    fontSize: theme.main.font.xs,
  }),
} as const

///////////////////////////////////////////////////////////////////////////////////////////////////////////
// Main
///////////////////////////////////////////////////////////////////////////////////////////////////////////

export const ProductionHQ = () => {
  const context = useContext(GameContext)
  let productionHQ_contextData: ProductionHQ_data = context.productionHQ_data.productionHQ_data
  UpdateHQLevel(productionHQData.level, context.levelOfSelectedHQ.setLevelOfSelectedHQ)

  const MOCK_progressBar = [{ name: 'Ore mines', reqTime: 1200, processedTime: 720 }] //@Todo - replace MOCK!

  return (
    <Unit.G_MS.InnerScreenPattern
      dataBlock={{
        name: productionHQData.name,
        level: productionHQData.level,
        currentWorkers: 102,
        currentWorkplaces: productionHQData.statsAtCurrentLevel.workers,
        nextWorkPlaces: productionHQData.statsAtNextLevel.workers,
        currentConstructionSpeed: productionHQData.statsAtCurrentLevel.constructionSpeed,
        nextConstructionSpeed: productionHQData.statsAtNextLevel.constructionSpeed,
        currentBuildingCount: productionHQ_contextData.currentBuildingLimit,
        currentBuildingLimit: productionHQData.statsAtCurrentLevel.buildingsLimit,
        nextBuildingLimit: productionHQData.statsAtNextLevel.buildingsLimit,
        currentPowerConsumption: 90,
        fullPowerConsumption: productionHQ_contextData.powerConsumption,
        constructionRate: productionHQData.statsAtNextLevel.constructionRate,
        resCost: productionHQData.statsAtNextLevel.resCost,
      }}
      slotCount={productionHQData.statsAtCurrentLevel.slots}
      img={productionHQ}
      progressQueue={MOCK_progressBar}
    >
      <InfoTable
        production={productionHQ_contextData.resProduction}
        currentStorage={productionHQData.statsAtCurrentLevel.storageLimits}
        nextStorage={productionHQData.statsAtNextLevel.storageLimits}
      />
    </Unit.G_MS.InnerScreenPattern>
  )
}

///////////////////////////////////////////////////////////////////////////////////////////////////////////
// Components
///////////////////////////////////////////////////////////////////////////////////////////////////////////

const InfoTable = (p: { production: ResList; currentStorage: ResList; nextStorage: ResList }) => {
  let productionData = Object.values(p.production)
  return (
    <>
      <Unit.G_MS.InfoTableHeader columnsCount={3}>
        <div>Type</div>
        <div>Production</div>
        <div>Storage limit</div>
      </Unit.G_MS.InfoTableHeader>

      {Object.entries(p.currentStorage).map((item, index) => {
        return (
          <InfoTableItem
            key={index}
            title={item[0]}
            production={productionData[index]}
            currentStorage={item[1]}
            nextStorage={Object.values(p.nextStorage)[index]}
          />
        )
      })}
    </>
  )
}

const InfoTableItem = (p: {
  title: string
  production: number
  currentStorage: number
  nextStorage: number
}) => (
  <Unit.G_MS.InfoTableItem columnsCount={3}>
    <div>{p.title.charAt(0).toUpperCase() + p.title.slice(1)}:</div>{' '}
    <div>{p.production.toLocaleString('en-US')}</div>
    <div>
      {p.currentStorage.toLocaleString('en-US')}{' '}
      <span css={s.nextLevel_default}>(Next: {p.nextStorage.toLocaleString('en-US')})</span>
    </div>
  </Unit.G_MS.InfoTableItem>
)
