export const unitHQData = {
  id: 'D', // ID = unique HQ / category identification
  name: 'Military command center',
  level: 4,

  statsAtCurrentLevel: {
    slots: 17,
    workers: 250,
    constructionSpeed: 2100,
    powerConsumption: 750,
  },
  statsAtNextLevel: {
    slots: 19,
    workers: 330,
    constructionSpeed: 2990,
    powerConsumption: 1100,
    constructionRate: 3300,
    resCost: {
      metal: 600,
      plastics: 380,
      electronics: 340,
      fuel: 1420,
    },
  },
}
