/** @jsxImportSource @emotion/react */
// Default Reusable
import * as theme from '../theme_overmind'
import { css } from '@emotion/react'
// import styled from '@emotion/styled'

// Others

// Images

///////////////////////////////////////////////////////////////////////////////////////////////////////////
// CSS - Main
///////////////////////////////////////////////////////////////////////////////////////////////////////////

export const main = {
  alignCenter: css({
    textAlign: `center`,
  }),
  alignRight: css({
    textAlign: `right`,
  }),
  extraTopMargin: css({
    marginTop: theme.main.spacing.lg,
  }),
  extraBottomMargin: css({
    marginBottom: theme.main.spacing.xl,
  }),
  noTopMargin: css({
    marginTop: 0,
  }),
  italic: css({
    fontStyle: `italic`,
  }),
  appContent: css({
    margin: `${theme.main.spacing.sm} 0`,
    fontSize: theme.main.font.md,

    [theme.mq.tablet]: {
      margin: `${theme.main.spacing.md} 0`,
    },

    [theme.mq.mobile]: {
      margin: `${theme.main.spacing.sm} 0`,
    },
  }),
  appP: css({
    marginBottom: theme.main.spacing.sm,
    textAlign: `justify`,
    fontSize: theme.main.font.md,
  }),
  appH1: css({
    margin: `${theme.main.spacing.sm} 0 ${theme.main.spacing.xxs} 0`,
    fontSize: theme.main.font.xl,
    color: theme.color.secL3,
  }),
  appH2: css({
    margin: `${theme.main.spacing.md} 0 ${theme.main.spacing.xxs} 0`,
    fontSize: theme.main.font.lg,
    color: theme.color.secL3,
  }),
  appQuotation: css({
    margin: `${theme.main.spacing.md} ${theme.main.spacing.xl}`,
    fontSize: theme.main.font.lg,
    fontStyle: 'italic',

    [theme.mq.tablet]: {
      margin: `${theme.main.spacing.md} ${theme.main.spacing.sm}`,
    },
  }),
} as const

///////////////////////////////////////////////////////////////////////////////////////////////////////////
// Main Components
///////////////////////////////////////////////////////////////////////////////////////////////////////////

type PropsMain = {
  children: React.ReactNode
  alignCenter?: boolean
  alignRight?: boolean
  extraTopMargin?: boolean
  extraBottomMargin?: boolean
  noTopMargin?: boolean
  italic?: boolean
  bug?: boolean
}
export const Content = (p: PropsMain) => <article css={main.appContent}>{p.children}</article>
export const P = (p: PropsMain) => (
  <section
    css={[
      main.appP,
      p.alignCenter ? main.alignCenter : '',
      p.extraTopMargin ? main.extraTopMargin : '',
      p.extraBottomMargin ? main.extraBottomMargin : '',
      p.italic ? main.italic : '',
    ]}
  >
    {p.children}
  </section>
)
export const H1 = (p: PropsMain) => (
  <h1
    css={[
      main.appH1,
      p.alignCenter ? main.alignCenter : '',
      p.extraTopMargin ? main.extraTopMargin : '',
      p.italic ? main.italic : '',
    ]}
  >
    {p.children}
  </h1>
)
export const H2 = (p: PropsMain) => (
  <h2
    css={[
      main.appH2,
      p.alignCenter ? main.alignCenter : '',
      p.alignRight ? main.alignRight : '',
      p.extraTopMargin ? main.extraTopMargin : '',
      p.noTopMargin ? main.noTopMargin : '',
      p.italic ? main.italic : '',
    ]}
  >
    {p.children}
  </h2>
)
