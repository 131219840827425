export const defensiveBuildingsList = [
  {
    id: 'C-00', // ID = unique building's identification
    index: 0, // index = game UI index
    name: 'Cold insulation',
    defType: 'cold',
    level: 3,

    statsAtCurrentLevel: {
      def: 100,
      powerConsumption: 2000,
    },
    statsAtNextLevel: {
      def: 180,
      powerConsumption: 3200,
      constructionRate: 300,
      resCost: {
        metal: 100,
        plastics: 80,
        electronics: 40,
        fuel: 20,
      },
    },
  },
  {
    id: 'C-01',
    index: 1,
    name: 'Heat shield',
    defType: 'heat',
    level: 1,

    statsAtCurrentLevel: {
      def: 10,
      powerConsumption: 200,
    },
    statsAtNextLevel: {
      def: 18,
      powerConsumption: 320,
      constructionRate: 300,
      resCost: {
        metal: 100,
        plastics: 80,
        electronics: 40,
        fuel: 20,
      },
    },
  },
  {
    id: 'C-02',
    index: 2,
    name: 'Radiation shield',
    defType: 'radiation',
    level: 0,

    statsAtCurrentLevel: {
      def: 0,
      powerConsumption: 0,
    },
    statsAtNextLevel: {
      def: 9,
      powerConsumption: 320,
      constructionRate: 300,
      resCost: {
        metal: 100,
        plastics: 80,
        electronics: 40,
        fuel: 20,
      },
    },
  },
  {
    id: 'C-03',
    index: 3,
    name: 'Force shield',
    defType: 'force',
    level: 5,

    statsAtCurrentLevel: {
      def: 150,
      powerConsumption: 200,
    },
    statsAtNextLevel: {
      def: 180,
      powerConsumption: 320,
      constructionRate: 300,
      resCost: {
        metal: 100,
        plastics: 80,
        electronics: 40,
        fuel: 20,
      },
    },
  },
  {
    id: 'C-04',
    index: 4,
    name: 'Cyber shield',
    defType: 'cyber',
    level: 2,

    statsAtCurrentLevel: {
      def: 10,
      powerConsumption: 200,
    },
    statsAtNextLevel: {
      def: 18,
      powerConsumption: 320,
      constructionRate: 300,
      resCost: {
        metal: 100,
        plastics: 80,
        electronics: 40,
        fuel: 20,
      },
    },
  },
]
