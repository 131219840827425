/** @jsxImportSource @emotion/react */
// Default
import * as Unit from '../../../Reusable_overmind'
// import * as theme from '../../../theme'
// import { css } from '@emotion/react'

// Others
import { GameContext } from '../../../gameContext'
import { useContext } from 'react'

// Local data
import { techImgList } from '../../../gameStaticData/techImgList'
import { techList } from '../../../gameStaticData/MOCK/techList'

///////////////////////////////////////////////////////////////////////////////////////////////////////////
// CSS
///////////////////////////////////////////////////////////////////////////////////////////////////////////

// const s = {} as const

///////////////////////////////////////////////////////////////////////////////////////////////////////////
// Main
///////////////////////////////////////////////////////////////////////////////////////////////////////////

export const ResearchMenu = () => {
  const context = useContext(GameContext)

  // Init
  const MOCK_techList = techList //@Todo - replace MOCK!

  return (
    <Unit.G_GP.MenuCategory>
      {MOCK_techList.map((item: Tech, index) => {
        const currentLevel = item.statsAtCurrentLevel
        const nextLevel = item.statsAtNextLevel

        return (
          <Unit.G_GP.TechPattern
            key={index}
            img={techImgList[index]}
            name={item.name}
            level={item.level}
            currentEffect={currentLevel.effect}
            nextEffect={nextLevel.effect}
            researchRate={nextLevel.researchRate}
            resCost={nextLevel.resCost}
            unlockAt={item.unlockAt}
            hqLevel={context.levelOfSelectedHQ.levelOfSelectedHQ}
          />
        )
      })}
    </Unit.G_GP.MenuCategory>
  )
}

///////////////////////////////////////////////////////////////////////////////////////////////////////////
// Components
///////////////////////////////////////////////////////////////////////////////////////////////////////////

type Tech = {
  id: string
  index: number
  name: string
  level: number
  unlockAt: number

  statsAtCurrentLevel: {
    effect: number
  }
  statsAtNextLevel: TechStats
}

type TechStats = {
  researchRate: number
  effect: number
  resCost: {
    metal: number
    plastics: number
    electronics: number
    fuel: number
  }
}
