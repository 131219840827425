// Images
import explorerDrone from './images/units/explorer_drone.webp'
import heavyOffensiveMech from './images/units/heavy_offensive_mech.webp'
import infantryTransporter from './images/units/infantry_transporter.webp'
import largeCargoTransporter from './images/units/large_cargo_transporter.webp'
import lightOffensiveDrone from './images/units/light_offensive_drone.webp'
import scrapGatherer from './images/units/scrap_gatherer.webp'
import smallCargoTransporter from './images/units/small_cargo_transporter.webp'
import soldier from './images/units/soldier.webp'
import technician from './images/units/technician.webp'

export const unitImgList = [
  explorerDrone,
  smallCargoTransporter,
  largeCargoTransporter,
  infantryTransporter,
  scrapGatherer,
  technician,
  soldier,
  lightOffensiveDrone,
  heavyOffensiveMech,
]
