/** @jsxImportSource @emotion/react */
// Default
import * as Unit from '../../../Reusable_overmind'
// import * as theme from '../../../theme'
// import { css } from '@emotion/react'

// Others
import { GameContext } from '../../../gameContext'
import { useContext } from 'react'

//Controller
import { UpdateHQLevel } from '../../../gameControllers/GameController'

// Local data
import { unitHQData } from '../../../gameStaticData/MOCK/unitHQData'
import factory from '../../../gameStaticData/images/HQs/factory.webp'

///////////////////////////////////////////////////////////////////////////////////////////////////////////
// CSS
///////////////////////////////////////////////////////////////////////////////////////////////////////////

// const s = {} as const

///////////////////////////////////////////////////////////////////////////////////////////////////////////
// Main
///////////////////////////////////////////////////////////////////////////////////////////////////////////

export const UnitHQ = () => {
  const context = useContext(GameContext)
  UpdateHQLevel(unitHQData.level, context.levelOfSelectedHQ.setLevelOfSelectedHQ)

  const MOCK_progressBar = [
    { name: 'Explorer drone', reqTime: 500, processedTime: 250 },
    { name: 'Scrap gatherer', reqTime: 600, processedTime: 0 },
    { name: 'Small cargo-transporter', reqTime: 600, processedTime: 0 },
  ] //@Todo - replace MOCK!

  return (
    <Unit.G_MS.InnerScreenPattern
      dataBlock={{
        name: unitHQData.name,
        level: unitHQData.level,
        currentWorkers: 82,
        currentWorkplaces: unitHQData.statsAtCurrentLevel.workers,
        nextWorkPlaces: unitHQData.statsAtNextLevel.workers,
        currentConstructionSpeed: unitHQData.statsAtCurrentLevel.constructionSpeed,
        nextConstructionSpeed: unitHQData.statsAtNextLevel.constructionSpeed,
        currentPowerConsumption: 500,
        fullPowerConsumption: unitHQData.statsAtCurrentLevel.powerConsumption,
        nextFullPowerConsumption: unitHQData.statsAtNextLevel.powerConsumption,
        constructionRate: unitHQData.statsAtNextLevel.constructionRate,
        resCost: unitHQData.statsAtNextLevel.resCost,
      }}
      slotCount={unitHQData.statsAtCurrentLevel.slots}
      img={factory}
      progressQueue={MOCK_progressBar}
    >
      <h3>Coming soon!</h3>
      <p>Units management</p>
      <p>Expeditions</p>
      <p>PvE combat system</p>
      <p>World map</p>
      <p>PvP combat system</p>
      <p>World factions</p>
      <p>{`Overmind on the rise! (Glitch's return)`}</p>
    </Unit.G_MS.InnerScreenPattern>
  )
}

///////////////////////////////////////////////////////////////////////////////////////////////////////////
// Components
///////////////////////////////////////////////////////////////////////////////////////////////////////////
