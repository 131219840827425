/** @jsxImportSource @emotion/react */
// Default
import * as Unit from '../../../Reusable_overmind'
import * as theme from '../../../theme_overmind'
import { css } from '@emotion/react'

// Others

// Local data

// MDI
import { mdiAccountGroup } from '@mdi/js'
import { mdiAlert } from '@mdi/js'
import { mdiBabyBottleOutline } from '@mdi/js'
import { mdiCalendarAlert } from '@mdi/js'
import { mdiCarBrakeAlert } from '@mdi/js'
import { mdiCogRefreshOutline } from '@mdi/js'
import { mdiDomain } from '@mdi/js'
import { mdiHazardLights } from '@mdi/js'
import { mdiLightningBolt } from '@mdi/js'
import { mdiShieldOutline } from '@mdi/js'
import { mdiSwordCross } from '@mdi/js'
import Icon from '@mdi/react'

///////////////////////////////////////////////////////////////////////////////////////////////////////////
// CSS
///////////////////////////////////////////////////////////////////////////////////////////////////////////

const s = {
  mainWrapper_div: css({
    position: `absolute`,
    height: theme.main.layout.screen.modalScreen.height,
    width: theme.main.layout.screen.innerScreen.slotsDataBoxWidth,
    top: theme.main.layout.screen.innerScreen.mainDataBoxTopPosition,
    left: theme.main.layout.screen.innerScreen.slotsDataBoxLeftPosition,
    color: theme.color.light,
    backgroundColor: theme.color.transparent_cover_dark,
    overflowY: `scroll`,
    border: theme.main.border.dark,
  }),
  infoBoxWrapper_div: css({
    padding: theme.main.spacing.sm,
  }),
  infoBoxHeader_div: css({
    margin: `${theme.main.spacing.sm} 0`,
    paddingBottom: theme.main.spacing.xs,
    borderBottom: theme.main.border.game,
    textAlign: `center`,
  }),
  infoBoxData_div: css({
    textAlign: `center`,
    alignItems: `center`,
  }),
  eventBox_div: css({
    margin: `${theme.main.spacing.sm} 0`,
  }),
  grid4_div: css({
    display: `grid`,
    gridTemplateColumns: `1fr 1fr 1fr 1fr`,
  }),
  grid5_div: css({
    display: `grid`,
    gridTemplateColumns: `1fr 1fr 1fr 1fr 2fr`,
  }),
  grid6_div: css({
    display: `grid`,
    gridTemplateColumns: `1fr 1fr 1fr 1fr 1fr 1fr`,
  }),
} as const

///////////////////////////////////////////////////////////////////////////////////////////////////////////
// Main
///////////////////////////////////////////////////////////////////////////////////////////////////////////

export const ColonyManager = () => {
  const MOCK_DATA = {
    events: {
      title: 'Ongoing events..',
      icon: [mdiHazardLights, mdiShieldOutline, mdiAlert, mdiCarBrakeAlert, mdiCalendarAlert],
      header: ['Type', 'Shields', 'Pressure', 'Failure', 'Effects'],
      data: [
        {
          name: 'tornado',
          shields: { type: 'force', value: 200 },
          pressure: 300,
          effects: ['Productivity reduced by 30%'],
        },
        {
          name: 'high radioactivity',
          shields: { type: 'radioactivity', value: 50 },
          pressure: 250,
          effects: ['Birthrate reduced by 50%'],
        },
      ],
    },
    population: {
      title: 'Population',
      icon: [mdiAccountGroup, mdiCogRefreshOutline, mdiBabyBottleOutline, mdiSwordCross],
      header: ['Workers', 'Productivity', 'Birthrate', 'Discontent'],
      data: {
        workers: [240, 400],
        productivity: 110,
        birthrate: 30,
        discontent: 10,
      },
    },
    productivity: {
      title: 'Productivity',
      icon: [
        mdiDomain,
        mdiAccountGroup,
        mdiAccountGroup,
        mdiLightningBolt,
        mdiLightningBolt,
        mdiCogRefreshOutline,
      ],
      header: ['Workplace', 'assigned', 'needed', 'assigned', 'needed', 'Efficiency'],
      data: {
        production: {
          workers: { assigned: 120, needed: 120 },
          power: { assigned: 20, needed: 180 },
        },
        research: { workers: { assigned: 170, needed: 180 }, power: { assigned: 20, needed: 100 } },
        defense: { workers: { assigned: 100, needed: 210 }, power: { assigned: 80, needed: 180 } },
        military: {
          workers: { assigned: 180, needed: 360 },
          power: { assigned: 240, needed: 240 },
        },
      },
    },
  } //@Todo - replace MOCK!

  return (
    <Unit.G_MS.ScreenPattern img={''}>
      <div css={s.mainWrapper_div}>
        <InfoBoxPopulation input={MOCK_DATA.population} />
        <InfoBoxProductivity input={MOCK_DATA.productivity} />
        <OngoingEvents input={MOCK_DATA.events} />
      </div>
    </Unit.G_MS.ScreenPattern>
  )
}

///////////////////////////////////////////////////////////////////////////////////////////////////////////
// Components
///////////////////////////////////////////////////////////////////////////////////////////////////////////

type PopulationData = {
  title: string
  icon: string[]
  header: string[]

  data: { workers: number[]; productivity: number; birthrate: number; discontent: number }
}

type ProductionData = {
  title: string
  icon: string[]
  header: string[]
  data: {
    [index: string]: {
      workers: { assigned: number; needed: number }
      power: { assigned: number; needed: number }
    }
  }
}

type EventData = {
  title: string
  icon: string[]
  header: string[]
  data: {
    name: string
    shields: { type: string; value: number }
    pressure: number
    effects: string[]
  }[]
}

const InfoHeader = (p: { icon: string[]; title: string[]; columnCount: number }) => {
  let grid = s.grid4_div
  if (p.columnCount === 4) {
    grid = s.grid4_div
  } else if (p.columnCount === 5) {
    grid = s.grid5_div
  } else if (p.columnCount === 6) {
    grid = s.grid6_div
  }

  return (
    <div css={[s.infoBoxHeader_div, grid]}>
      {p.icon.map((item, index) => {
        return (
          <div key={index}>
            <Icon path={item} size={1} />
          </div>
        )
      })}
      {p.title.map((item, index) => {
        return <div key={index}>{item.charAt(0).toUpperCase() + item.slice(1)}</div>
      })}
    </div>
  )
}

const InfoBoxPopulation = (p: { input: PopulationData }) => {
  return (
    <div css={s.infoBoxWrapper_div}>
      <h3>{p.input.title}</h3>
      <InfoHeader icon={p.input.icon} title={p.input.header} columnCount={4} />

      <div css={[s.infoBoxData_div, s.grid4_div]}>
        <div>
          {p.input.data.workers[0]} / {p.input.data.workers[1]}
        </div>
        <div>{p.input.data.productivity} %</div>
        <div>{p.input.data.birthrate} %</div>
        <div>{p.input.data.discontent} %</div>
      </div>
    </div>
  )
}

const InfoBoxProductivity = (p: { input: ProductionData }) => {
  return (
    <div css={s.infoBoxWrapper_div}>
      <h3>{p.input.title}</h3>
      <InfoHeader icon={p.input.icon} title={p.input.header} columnCount={6} />

      {Object.entries(p.input.data).map((item, index) => {
        return (
          <div css={[s.infoBoxData_div, s.grid6_div]} key={index}>
            <div>{item[0].charAt(0).toUpperCase() + item[0].slice(1)}</div>
            <Unit.G.InputFormAssign
              currentValue={item[1].workers.assigned}
              maxLimit={item[1].workers.needed}
            />
            <div>{item[1].workers.needed}</div>

            <Unit.G.InputFormAssign
              currentValue={item[1].power.assigned}
              maxLimit={item[1].power.needed}
            />
            <div>{item[1].power.needed}</div>

            <div>
              {Math.round(
                ((item[1].workers.assigned / item[1].workers.needed) * 100 +
                  (item[1].power.assigned / item[1].power.needed) * 100) /
                  2
              )}
              %
            </div>
          </div>
        )
      })}
    </div>
  )
}

const OngoingEvents = (p: { input: EventData }) => (
  <div css={s.infoBoxWrapper_div}>
    <h3>{p.input.title}</h3>
    <InfoHeader icon={p.input.icon} title={p.input.header} columnCount={5} />

    {p.input.data.map((item, index) => {
      return (
        <div css={[s.infoBoxData_div, s.grid5_div, s.eventBox_div]} key={index}>
          <div>
            {item.name.charAt(0).toUpperCase() + item.name.slice(1)} <br />({item.shields.type})
          </div>
          <div>{item.shields.value}</div>
          <div>{item.pressure}</div>
          <div css={Unit.G.main.gFailure}>
            {item.shields.value - item.pressure < 0 ? item.shields.value - item.pressure : ''}
          </div>
          <div>
            {item.effects.map((item, index) => {
              return <div key={index}>{item}</div>
            })}
          </div>
        </div>
      )
    })}
  </div>
)
