/** @jsxImportSource @emotion/react */
// Default
import * as Unit from '../../Reusable_overmind'
// import * as theme from '../../theme'
// import { css } from '@emotion/react'

// Others
import { GameContext } from '../../gameContext'
import { useContext } from 'react'

// Components
import { BlackMarket } from './mainScreen/BlackMarket'
import { ColonyManager } from './mainScreen/ColonyManager'
import { DefenseHQ } from './mainScreen/DefenseHQ'
import { ProductionHQ } from './mainScreen/ProductionHQ'
import { ResearchHQ } from './mainScreen/ResearchHQ'
import { UnitHQ } from './mainScreen/UnitHQ'

const contentList = [
  <ProductionHQ key={0} />,
  <ResearchHQ key={1} />,
  <DefenseHQ key={2} />,
  <UnitHQ key={3} />,
  <BlackMarket key={4} />,
]

const hiddenContentList = [<ColonyManager key={-1} />]

///////////////////////////////////////////////////////////////////////////////////////////////////////////
// CSS
///////////////////////////////////////////////////////////////////////////////////////////////////////////

// const s = {} as const

///////////////////////////////////////////////////////////////////////////////////////////////////////////
// Main
///////////////////////////////////////////////////////////////////////////////////////////////////////////

export const MainScreen = () => {
  const context = useContext(GameContext)
  const activeScreen = context.activeScreen.activeScreen

  return (
    <>
      {activeScreen < 0 ? hiddenContentList[Math.abs(activeScreen + 1)] : contentList[activeScreen]}
    </>
  )
}

///////////////////////////////////////////////////////////////////////////////////////////////////////////
// Components
///////////////////////////////////////////////////////////////////////////////////////////////////////////
