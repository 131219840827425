/** @jsxImportSource @emotion/react */
// Default
import * as Unit from '../Reusable'
import * as theme from '../theme'
import { css } from '@emotion/react'
import { useEffect, useState } from 'react'

// Others
import { useNavigate } from 'react-router-dom'
import { useRef } from 'react'
import emailjs from '@emailjs/browser'
import type { SerializedStyles } from '@emotion/serialize'

// mdi
import { mdiFileSign } from '@mdi/js'
import Icon from '@mdi/react'

///////////////////////////////////////////////////////////////////////////////////////////////////////////
// CSS - Templates
///////////////////////////////////////////////////////////////////////////////////////////////////////////

const template = {
  vertical: {
    defaultPosition: theme.responsiveSize.layout.screen.width / 5,
    stepperModifier: theme.responsiveSize.layout.profileWingStep.height,
  },
  horizontal: {
    defaultPosition:
      theme.responsiveSize.layout.screen.width / 2 - theme.responsiveSize.layout.screen.width / 4,
    stepperModifier: theme.responsiveSize.layout.profileWingStep.width,
  },
} as const

const templateAdvanced = {
  div1: {
    vertical: `${template.vertical.defaultPosition / 3 - template.vertical.stepperModifier}vw`,
    horizontal: `${
      template.horizontal.defaultPosition - template.horizontal.stepperModifier * 3
    }vw`,
  },
  div2: {
    vertical: `${template.vertical.defaultPosition / 1.5 - template.vertical.stepperModifier}vw`,
    horizontal: `${
      template.horizontal.defaultPosition - template.horizontal.stepperModifier * 2
    }vw`,
  },
  div3: {
    vertical: `${template.vertical.defaultPosition / 1 - template.vertical.stepperModifier}vw`,
    horizontal: `${
      template.horizontal.defaultPosition - template.horizontal.stepperModifier * 1
    }vw`,
  },
  divMobile: {
    vertical: template.vertical.stepperModifier,
  },
} as const

///////////////////////////////////////////////////////////////////////////////////////////////////////////
// CSS
///////////////////////////////////////////////////////////////////////////////////////////////////////////

export const s = {
  profileCard: css({
    position: `relative`,
    textAlign: `center`,
    fontWeight: `bold`,
  }),
  profileImgBox: css({
    width: `${theme.responsiveSize.layout.screen.width / 5}vw`,
    height: `${theme.responsiveSize.layout.screen.width / 5}vw`,
    position: `relative`,
    margin: `auto`,
    borderRadius: theme.main.borderRadius.profileImg,
    backgroundColor: theme.color.wing,

    [theme.mq.tablet]: {
      width: `${theme.responsiveSize.layout.screen.width / 1.5}vw`,
      height: `${theme.responsiveSize.layout.screen.width / 1.5}vw`,
      margin: `${theme.main.spacing.md} auto`,
    },

    [theme.mq.mobile]: {
      width: `${theme.responsiveSize.layout.screen.width}vw`,
      height: `${theme.responsiveSize.layout.screen.width}vw`,
      margin: `${theme.main.spacing.md} auto ${theme.main.spacing.sm} auto`,
    },
  }),
  profileImg: css({
    width: `100%`,
    height: `100%`,
    borderRadius: theme.main.borderRadius.profileImg,
  }),
  label: css({
    position: `absolute`,
    transform: `translate( 0, -130%)`,
  }),
  divBorder: css({
    border: theme.main.border.main,
  }),
  wingBorder: css({
    border: theme.main.border.main,
    backgroundColor: theme.color.wing,
    textShadow: theme.main.shadow.text.main,
  }),
  profileWing: css({
    width: `${(theme.responsiveSize.layout.screen.width / 5) * 1.45}vw`,
    position: `absolute`,
    top: 0,
    padding: `${theme.main.spacing.xxs} ${theme.main.spacing.xs}`,
    fontSize: theme.main.font.sm,

    [theme.mq.laptop]: {
      fontSize: theme.main.font.xs,
    },

    [theme.mq.tablet]: {
      width: `${theme.responsiveSize.layout.screen.widthMobile}vw`,
      position: `relative`,
      fontSize: theme.main.font.md,
    },

    [theme.mq.mobile]: {
      fontSize: theme.main.font.sm,
      width: `${theme.responsiveSize.layout.screen.widthMobileWider}vw`,
    },
  }),
  mainWingBox: css({
    width: `${(theme.responsiveSize.layout.screen.width / 5) * 2}vw`,
    margin: `${theme.responsiveSize.layout.profileWingStep.height * 2}vw auto ${
      theme.responsiveSize.layout.profileWingStep.height
    }vw auto`,

    [theme.mq.tablet]: {
      width: `${theme.responsiveSize.layout.screen.width}vw`,
      margin: `0 auto ${theme.main.spacing.md} auto`,
    },
  }),
  mainWing: css({
    padding: `${theme.main.spacing.xxs} ${theme.main.spacing.xs}`,
    borderRadius: `0 0 ${theme.main.borderRadius.profileWing} ${theme.main.borderRadius.profileWing}`,
    fontSize: theme.main.font.lg,
    fontWeight: `bold`,

    [theme.mq.tablet]: {
      borderRadius: 0,
    },
  }),
  mainWingLink: css({
    width: '50%',
    margin: `${theme.main.spacing.xs} auto`,
    cursor: `pointer`,
  }),
  leftWing: css({
    borderRadius: `0 ${theme.main.borderRadius.profileWing}`,
    transform: `translate( -50%, -100%)`,

    [theme.mq.tablet]: {
      borderRadius: 0,
      transform: `translate( -50%, 0)`,
    },
  }),
  rightWing: css({
    borderRadius: `${theme.main.borderRadius.profileWing} 0`,
    transform: `translate( 50%, -100%)`,

    [theme.mq.tablet]: {
      borderRadius: 0,
      transform: `translate( -50%, 0)`,
    },
  }),
  leftDiv1: css({
    top: templateAdvanced.div1.vertical,
    left: templateAdvanced.div1.horizontal,

    [theme.mq.tablet]: {
      marginTop: theme.main.spacing.md,
      top: `${templateAdvanced.divMobile.vertical * 1}vw`,
      left: '50%',
    },
  }),
  leftDiv2: css({
    top: templateAdvanced.div2.vertical,
    left: templateAdvanced.div2.horizontal,

    [theme.mq.tablet]: {
      marginTop: theme.main.spacing.md,
      top: `${templateAdvanced.divMobile.vertical * 1}vw`,
      left: '50%',
    },
  }),
  leftDiv3: css({
    top: templateAdvanced.div3.vertical,
    left: templateAdvanced.div3.horizontal,

    [theme.mq.tablet]: {
      marginTop: theme.main.spacing.md,
      top: `${templateAdvanced.divMobile.vertical * 1}vw`,
      left: '50%',
    },
  }),
  rightDiv1: css({
    top: templateAdvanced.div1.vertical,
    right: templateAdvanced.div1.horizontal,

    [theme.mq.tablet]: {
      marginTop: theme.main.spacing.md,
      top: `${templateAdvanced.divMobile.vertical * 1}vw`,
      right: 0,
      left: '50%',
    },
  }),
  rightDiv2: css({
    top: templateAdvanced.div2.vertical,
    right: templateAdvanced.div2.horizontal,

    [theme.mq.tablet]: {
      marginTop: theme.main.spacing.md,
      top: `${templateAdvanced.divMobile.vertical * 1}vw`,
      right: 0,
      left: '50%',
    },
  }),
  rightDiv3: css({
    top: templateAdvanced.div3.vertical,
    right: templateAdvanced.div3.horizontal,

    [theme.mq.tablet]: {
      marginTop: theme.main.spacing.md,
      top: `${templateAdvanced.divMobile.vertical * 1}vw`,
      right: 0,
      left: '50%',
    },
  }),
  discountTitle: css({
    color: theme.color.discount,
    fontSize: theme.main.font.sm,
  }),
  discountOld: css({
    fontWeight: `normal`,
    fontSize: theme.main.font.sm,
    color: theme.color.light,
    textShadow: 'none',
    textDecoration: `line-through`,
  }),
  discountNew: css({
    color: theme.color.discount,
  }),
  signInBoxLine: css({
    width: `90%`,
    margin: `auto`,
    padding: `${theme.main.spacing.xs} ${theme.main.spacing.sm}`,
    display: 'flex',
    justifyContent: `space-around`,
    fontSize: theme.main.font.md,

    [theme.mq.tablet]: {
      width: `100%`,
      fontSize: theme.main.font.sm,
    },
  }),
  signInBoxCell: css({
    width: `20%`,
  }),
  signInBoxCellId: css({
    width: `5%`,
  }),
  signInBoxCellSignIn: css({
    width: `15%`,
  }),
  signInBoxCellWide: css({
    width: `30%`,
  }),
  signInBtn: css({
    padding: `${theme.main.spacing.xs} ${theme.main.spacing.sm}`,
    margin: `${theme.main.spacing.xs} auto ${theme.main.spacing.lg} auto`,
    fontSize: theme.main.font.md,
    fontWeight: `bold`,
    color: theme.color.discount,
    cursor: `pointer`,

    [theme.mq.tablet]: {
      marginTop: theme.main.spacing.md,
      padding: `${theme.main.spacing.sm} ${theme.main.spacing.md}`,
      fontSize: theme.main.font.lg,
    },

    [theme.mq.mobile]: {
      padding: `${theme.main.spacing.xs} ${theme.main.spacing.sm}`,
      fontSize: theme.main.font.md,
    },
  }),
  autoFocusOn: css({
    color: theme.color.discount,
    cursor: 'pointer',
  }),
  autoFocusOff: css({
    color: theme.color.main,
    cursor: 'pointer',
  }),
  orderSummaryModal: css({
    width: `${theme.responsiveSize.layout.screen.width}vw`,
    height: `${theme.responsiveSize.layout.mainWrapper.height}vh`,
    padding: theme.main.spacing.sm,
    position: `fixed`,
    top: 0,
    left: `${theme.responsiveSize.layout.leftNav.width}vw`,
    backgroundColor: theme.color.darkBg,
    border: theme.main.border.main,
    overflowX: `scroll`,
    zIndex: 3000,

    [theme.mq.tablet]: {
      width: `${theme.responsiveSize.layout.mainWrapper.width}vw`,
      left: 0,
    },
  }),
  orderSummaryModalForm: css({
    margin: `${theme.main.spacing.md} auto`,
    fontSize: theme.main.font.md,
    textAlign: `left`,
  }),
  orderSummaryModalFormInput: css({
    width: `80%`,
    display: `flex`,
    justifyContent: `space-around`,

    [theme.mq.tablet]: {
      width: `60%`,
      display: `block`,
    },

    [theme.mq.mobile]: {
      width: `100%`,
      display: `block`,
    },
  }),
  orderSummaryModalFormSubmit: css({
    width: `40%`,

    [theme.mq.tablet]: {
      width: `60%`,
    },

    [theme.mq.mobile]: {
      width: `100%`,
    },
  }),
  orderSummaryModalFormInputBox: css({
    width: `40%`,

    [theme.mq.tablet]: {
      width: `100%`,
    },
  }),
  orderSummaryModalFormH2: css({
    marginTop: 0,
    marginBottom: theme.main.spacing.xs,
    fontSize: theme.main.font.lg,

    [theme.mq.laptop]: {
      fontSize: theme.main.font.md,
      marginBottom: theme.main.spacing.sm,
    },

    [theme.mq.tablet]: {
      marginTop: theme.main.spacing.md,
      marginBottom: theme.main.spacing.xs,
      fontSize: theme.main.font.lg,
    },
  }),
  orderSummaryModalCheckbox: css({
    margin: `${theme.main.spacing.sm} auto`,
  }),
  orderSummaryModalSubmitBtn: css({
    width: `50%`,
    margin: `${theme.main.spacing.md} auto`,
    padding: `${theme.main.spacing.sm} ${theme.main.spacing.md}`,
    border: theme.main.border.main,
    backgroundColor: theme.color.wing,
    fontSize: theme.main.font.lg,
    textShadow: theme.main.shadow.text.main,
    color: theme.color.main,
    cursor: `pointer`,

    [theme.mq.laptop]: {
      width: `50%`,
    },

    [theme.mq.tablet]: {
      width: `60%`,
    },

    [theme.mq.mobile]: {
      width: `100%`,
      padding: `${theme.main.spacing.sm} ${theme.main.spacing.xs}`,
    },
  }),
  orderSummaryModalLabel: css({
    display: `block`,
  }),
  orderSummaryModalInfoBox: css({
    width: `70%`,
    margin: `0 auto ${theme.main.spacing.lg} auto`,
    textAlign: `justify`,

    [theme.mq.tablet]: {
      width: `100%`,
    },
  }),
  orderSummaryModalInput: css({
    width: `100%`,
    backgroundColor: theme.color.secL3,
    border: theme.main.border.main,
    padding: `${theme.main.spacing.xxs} ${theme.main.spacing.xs}`,
  }),
  orderSummaryModalCloseBtn: css({
    position: `fixed`,
    top: theme.main.spacing.sm,
    right: `${theme.responsiveSize.layout.rightNav.width + theme.responsiveSize.font.lg}vw`,
    padding: `${theme.main.spacing.xs} ${theme.main.spacing.sm}`,
    backgroundColor: theme.color.secD2,
    border: theme.main.border.main,
    textShadow: theme.main.shadow.text.main,
    fontSize: theme.main.font.lg,
    fontWeight: `bold`,
    color: theme.color.main,
    cursor: `pointer`,
    zIndex: 5000,

    [theme.mq.tablet]: {
      right: theme.main.font.lg,
      padding: theme.main.spacing.xs,
      fontSize: theme.main.font.md,
    },
  }),
  hrefBox: css({
    fontSize: theme.main.font.sm,
    textAlign: `right`,
  }),
  href: css({
    color: theme.color.main,
    textDecoration: `none`,
  }),
} as const

///////////////////////////////////////////////////////////////////////////////////////////////////////////
// Main
///////////////////////////////////////////////////////////////////////////////////////////////////////////

export type PropsWing = {
  img: string
  name: string
  lecturer: string
  level: string
  price: number
  discountPrice?: number
  discountDate?: string
  duration: string
  req: string
  type: string
  basicCourse: { id: string; duration: string; paymentLink: string; discountPaymentLink: string }
  extensions: {
    id: string
    title: string
    duration: string
    price: number
    discountPrice?: number
    paymentLink: string
    discountPaymentLink: string
  }[]
}

export const WingedHeader = (p: PropsWing) => {
  // Alternative url changer .. some problems with Link
  const navigate = useNavigate()
  const backClick = () => {
    navigate(`/`) // @ToDo courses list
  }

  const [showSignIn, setShowSignIn] = useState(false)

  const ShowSignIn = () => {
    setShowSignIn(!showSignIn)

    //////////////////////////// GTM ////////////////////////////
    //@ts-ignore
    window.dataLayer = window.dataLayer || []
    //@ts-ignore
    window.dataLayer.push({
      event: 'enrol',
      eventProps: {
        name: p.name,
      },
    })
    //////////////////////////// GTM ////////////////////////////
  }

  let isDiscountValid = false
  if (p.discountDate) {
    let getDiscountDate = p.discountDate.split(' ').slice(-1)
    getDiscountDate = getDiscountDate[0].split('.').slice(-3)

    const getDiscountDay = Number(getDiscountDate[0])
    const getDiscountMonth = Number(getDiscountDate[1]) - 1
    const getDiscountYear = Number(getDiscountDate[2])

    const discountEnd = new Date(getDiscountYear, getDiscountMonth, getDiscountDay).getTime()
    const currentDay = new Date().getTime()
    isDiscountValid = currentDay < discountEnd
  }

  return (
    <header css={s.profileCard}>
      {/* Middle items */}
      <div css={[s.profileImgBox, s.divBorder]}>
        <img css={s.profileImg} src={p.img} alt='Course profil' />
      </div>
      <div css={s.mainWingBox}>
        <div css={[s.mainWing, s.wingBorder]}>{p.name}</div>
        {/* <Unit.advanced.linkFocusHover
          css={[s.mainWing, s.wingBorder, s.mainWingLink]}
          onClick={() => backClick()}
        >
          Zpět
        </Unit.advanced.linkFocusHover> */}
      </div>

      {/* Left items */}
      <Wing wing={'left'} title={'Lektor'} value={p.lecturer} position={s.leftDiv1} />
      <Wing wing={'left'} title={'Náročnost'} value={p.level} position={s.leftDiv2} />
      <Wing wing={'left'} title={'Předpoklady'} value={p.req} position={s.leftDiv3} />

      {/* Right items */}
      <Wing wing={'right'} title={'Forma'} value={p.type} position={s.rightDiv1} />
      <Wing
        wing={'right'}
        title={'Cena'}
        title2={isDiscountValid ? p.discountDate : ''}
        value={p.price}
        value2={isDiscountValid ? p.discountPrice : ''}
        position={s.rightDiv2}
      />
      <Wing wing={'right'} title={'Délka'} value={p.duration} position={s.rightDiv3} />

      {showSignIn ? (
        <div>
          <Unit.AppH2>
            <strong>Základní kurz</strong>
          </Unit.AppH2>
          <CourseHeader />
          <CourseLine
            id={p.basicCourse.id}
            title={p.name}
            duration={p.basicCourse.duration}
            price={p.price}
            discountPrice={p.discountPrice}
            paymentLink={
              p.basicCourse.discountPaymentLink
                ? p.basicCourse.discountPaymentLink
                : p.basicCourse.paymentLink
            }
          />
          <Unit.AppH2>
            <strong>Rozšíření kurzu</strong>
          </Unit.AppH2>
          <CourseHeader />
          {p.extensions.map((item, index) => (
            <CourseLine
              key={index}
              id={item.id}
              title={item.title}
              duration={item.duration}
              price={item.price}
              discountPrice={item.discountPrice}
              paymentLink={item.discountPaymentLink ? item.discountPaymentLink : item.paymentLink}
            />
          ))}
        </div>
      ) : (
        ''
      )}

      <button onClick={() => ShowSignIn()} css={[s.signInBtn, s.wingBorder]}>
        <Unit.advanced.linkFocusHover>
          {showSignIn ? 'Skrýt nabídku' : 'Chci se zapsat na kurz!'}
        </Unit.advanced.linkFocusHover>
      </button>
    </header>
  )
}

///////////////////////////////////////////////////////////////////////////////////////////////////////////
// Components
///////////////////////////////////////////////////////////////////////////////////////////////////////////

export const Wing = (p: {
  wing: string
  title: string
  title2?: string
  value: string | number
  value2?: string | number
  position: SerializedStyles
}) => {
  let wing
  p.wing === 'left' ? (wing = s.leftWing) : (wing = s.rightWing)
  let position = p.position

  return (
    <div css={[s.profileWing, s.wingBorder, wing, position]}>
      <div css={s.label}>
        {p.title} <span css={s.discountTitle}>{p.title2 ? p.title2 : ''}</span>
      </div>
      <div>
        <span css={p.value2 ? s.discountOld : ''}>
          <strong>{typeof p.value === 'number' ? `${p.value},- Kč` : p.value}</strong>
        </span>
        <span css={p.value2 ? s.discountNew : ''}>
          <strong>{typeof p.value2 === 'number' ? ` ${p.value2},- Kč` : ''}</strong>
        </span>
      </div>
    </div>
  )
}

export const CourseHeader = () => {
  return (
    <div css={[s.signInBoxLine, s.wingBorder]}>
      <p css={s.signInBoxCellId}>ID</p>
      <p css={s.signInBoxCellWide}>Název služby</p>
      <p css={s.signInBoxCell}>Délka</p>
      <p css={s.signInBoxCellWide}>Konečná cena</p>
      <p css={s.signInBoxCellSignIn}>Zapsat se</p>
    </div>
  )
}

export const CourseLine = (p: {
  id: string
  title: string
  duration: string
  price: number
  discountPrice?: number
  paymentLink: string
}) => {
  let isMobile = window.innerWidth < theme.responsiveSize.layout.mobileNav.tabletSize
  const [isOrderSummaryModalOn, setIsOrderSummaryModalOn] = useState(false)
  const [autoFocusSignIn, setAutoFocusSignIn] = useState(false)

  useEffect(() => {
    const focusInterval = setInterval(() => {
      setAutoFocusSignIn(!autoFocusSignIn)
    }, 1000)

    return () => clearInterval(focusInterval)
  })

  const GTM_signIn = () => {
    //////////////////////////// GTM ////////////////////////////
    //@ts-ignore
    window.dataLayer.push({ ecommerce: null })
    //@ts-ignore
    window.dataLayer.push({
      event: 'begin_checkout',
      ecommerce: {
        currency: 'CZK',
        value: p.discountPrice ? p.discountPrice : p.price,
        items: [
          {
            item_id: p.id,
            item_name: p.title,
            price: p.discountPrice ? p.discountPrice : p.price,
            quantity: 1,
          },
        ],
      },
    })
    //////////////////////////// GTM ////////////////////////////
  }

  return (
    <>
      {isOrderSummaryModalOn ? (
        <>
          <OrderSummaryModal
            id={p.id}
            title={p.title}
            duration={p.duration}
            price={p.discountPrice ? p.discountPrice : p.price}
            paymentLink={p.paymentLink}
          />
          <Unit.advanced.linkFocusHover
            css={s.orderSummaryModalCloseBtn}
            onClick={() => setIsOrderSummaryModalOn(false)}
          >
            {isMobile ? 'X' : 'Zrušit'}
          </Unit.advanced.linkFocusHover>
        </>
      ) : (
        ''
      )}
      <div css={[s.signInBoxLine, s.wingBorder]}>
        <p css={s.signInBoxCellId}>{p.id}</p>
        <p css={s.signInBoxCellWide}>{p.title}</p>
        <p css={s.signInBoxCell}>{p.duration}</p>
        <p css={s.signInBoxCellWide}>
          <span css={p.discountPrice ? s.discountOld : ''}>{`${p.price},- Kč`}</span>
          <span css={p.discountPrice ? s.discountNew : ''}>
            {isMobile ? <br /> : ''}
            {p.discountPrice ? ` ${p.discountPrice},- Kč` : ''}
          </span>
        </p>
        <p css={s.signInBoxCellSignIn}>
          <span
            css={autoFocusSignIn ? s.autoFocusOn : s.autoFocusOff}
            onClick={() => [setIsOrderSummaryModalOn(true), GTM_signIn()]}
          >
            <Unit.advanced.linkFocusHoverSpan>
              <Icon path={mdiFileSign} size={1} />
            </Unit.advanced.linkFocusHoverSpan>
          </span>
        </p>
      </div>
    </>
  )
}

export const OrderSummaryModal = (p: {
  id: string
  title: string
  duration: string
  price: number
  paymentLink: string
}) => {
  const [firstNameValue, setFirstNameValue] = useState('')
  const [lastNameValue, setLastNameValue] = useState('')
  const [phoneValue, setPhoneValue] = useState('')
  const [emailValue, setEmailValue] = useState('')
  const [addressValue, setAddressValue] = useState('')
  const [townValue, setTownValue] = useState('')
  const [zipValue, setZipValue] = useState('')
  const [codeValue, setCodeValue] = useState('')
  const [vop, setVop] = useState(false)
  const [gdpr, setGdpr] = useState(false)
  const [loading, setLoading] = useState(false)

  useEffect(() => emailjs.init('aVnBvG5Ph9ijhIfj-'), [])

  const handleSubmit = async (e: any) => {
    //////////////////////////// GTM ////////////////////////////
    //@ts-ignore
    window.dataLayer.push({ ecommerce: null })
    //@ts-ignore
    window.dataLayer.push({
      event: 'purchase',
      ecommerce: {
        transaction_id: Date.now(),
        currency: 'CZK',
        value: p.price,
        tax: 0,
        items: [
          {
            item_id: p.id,
            item_name: p.title,
            price: p.price,
            quantity: 1,
          },
        ],
        userData: [
          { page_type: p.title },
          { customer_email: emailValue },
          { first_name: firstNameValue },
          { last_name: lastNameValue },
          { postal_code: zipValue },
          { country: 'CZ' },
          { phone_number: phoneValue },
        ],
      },
    })
    //////////////////////////// GTM ////////////////////////////

    const serviceId = 'service_kp3809p'
    const templateId = 'template_se5dtnr'

    if (
      !firstNameValue ||
      !lastNameValue ||
      !emailValue ||
      !addressValue ||
      !townValue ||
      !zipValue
    ) {
      alert('Doplň všechny povinné údaje prosím..')
    } else if (!vop) {
      alert('Je potřeba odsouhlasit VOP!')
    } else if (!gdpr) {
      alert('Je potřeba odsouhlasit GDPR!')
    } else if (
      firstNameValue &&
      lastNameValue &&
      emailValue &&
      addressValue &&
      townValue &&
      zipValue &&
      vop &&
      gdpr
    ) {
      try {
        setLoading(true)
        await emailjs.send(serviceId, templateId, {
          firstName: firstNameValue,
          lastName: lastNameValue,
          phoneNumber: phoneValue,
          recipient: emailValue,
          address: addressValue,
          town: townValue,
          zip: zipValue,
          promoCode: codeValue,
          id: p.id,
          title: p.title,
          duration: p.duration,
          price: p.price,
        })
        alert(
          'Gratulujeme k tvému prvnímu kroku na vyšší level! Další pokyny očekávej ve svém emailu.'
        )
      } catch (error) {
        console.log(error)
      } finally {
        setLoading(false)
      }
    } else {
      alert(
        'Neznámá chyba, prosím zkus vyplnit formulář znovu. Pokud potíže přetrvávají, kontaktuj nás na email: info@hexsquad.cz'
      )
    }
  }

  return (
    <article css={s.orderSummaryModal}>
      <div>
        <Unit.AppH1>Zápis na kurz</Unit.AppH1>
        <Unit.AppH1>{p.title}</Unit.AppH1>
        <Unit.AppH2>Shrnutí objednávky</Unit.AppH2>
        <div css={[s.signInBoxLine, s.wingBorder]}>
          <p css={s.signInBoxCellId}>ID</p>
          <p css={s.signInBoxCellWide}>Název služby</p>
          <p css={s.signInBoxCell}>Délka</p>
          <p css={s.signInBoxCellWide}>Konečná cena</p>
        </div>
        <div css={[s.signInBoxLine, s.wingBorder]}>
          <p css={s.signInBoxCellId}>{p.id}</p>
          <p css={s.signInBoxCellWide}>{p.title}</p>
          <p css={s.signInBoxCell}>{p.duration}</p>
          <p css={s.signInBoxCellWide}>{`${p.price},- Kč`}</p>
        </div>

        <form
          onSubmit={e => {
            e.preventDefault()
          }}
        >
          <div css={[s.orderSummaryModalForm, s.orderSummaryModalFormInput]}>
            <div css={s.orderSummaryModalFormInputBox}>
              <OSModalInput
                label={'* Jméno'}
                name={'firstName'}
                type={'text'}
                value={firstNameValue}
                setValue={setFirstNameValue}
                autocomplete={'given-name'}
              />
              <OSModalInput
                label={'* Příjmení'}
                name={'lastName'}
                type={'text'}
                value={lastNameValue}
                setValue={setLastNameValue}
                autocomplete={'family-name'}
              />
              <OSModalInput
                label={'Telefon (doporučeno)'}
                name={'phone'}
                type={'tel'}
                value={phoneValue}
                setValue={setPhoneValue}
                autocomplete={'tel-national'}
              />
              <OSModalInput
                label={'* E-mail'}
                name={'email'}
                type={'email'}
                value={emailValue}
                setValue={setEmailValue}
                autocomplete={'email'}
              />
            </div>
            <div css={s.orderSummaryModalFormInputBox}>
              <h2 css={s.orderSummaryModalFormH2}>Fakturační adresa</h2>
              <OSModalInput
                label={'* Ulice, čp.'}
                name={'adress'}
                type={'text'}
                value={addressValue}
                setValue={setAddressValue}
                autocomplete={'billing street-address'}
              />
              <OSModalInput
                label={'* Město'}
                name={'town'}
                type={'text'}
                value={townValue}
                setValue={setTownValue}
                autocomplete={'country-name'}
              />
              <OSModalInput
                label={'* PSČ'}
                name={'zip'}
                type={'text'}
                value={zipValue}
                setValue={setZipValue}
                autocomplete={'postal-code'}
              />
            </div>
          </div>

          <div css={[s.orderSummaryModalForm, s.orderSummaryModalFormInput]}>
            <div css={s.orderSummaryModalFormInputBox}>
              <OSModalInput
                label={'~PROMO CODE~'}
                name={'code'}
                type={'text'}
                value={codeValue}
                setValue={setCodeValue}
                autocomplete={'promo-code'}
              />
            </div>
          </div>

          <div css={s.orderSummaryModalInfoBox}>
            PROMO KÓD pro uplatnění dodatečné slevy. Upravená částka zápisného se propíše až v linku
            platební brány, není tedy třeba panikařit, když ti v potvrzení objednávky dorazí na
            email cena bez něj.
          </div>

          <div css={[s.orderSummaryModalForm, s.orderSummaryModalFormSubmit]}>
            <div css={s.orderSummaryModalCheckbox}>
              <div css={s.hrefBox}>
                <a href='https://hexsquad.cz/vop' target='_blank' rel='noreferrer' css={s.href}>
                  (VOP zde..)
                </a>
              </div>
              <span onClick={() => setVop(!vop)}>
                <Unit.AppCheckbox title={'Všeobecné obchodní podmínky'} isChecked={vop} />
              </span>
            </div>
            <div css={s.orderSummaryModalCheckbox}>
              <div css={s.hrefBox}>
                <a href='https://hexsquad.cz/gdpr' target='_blank' rel='noreferrer' css={s.href}>
                  (GDPR zde..)
                </a>
              </div>
              <span onClick={() => setGdpr(!gdpr)}>
                <Unit.AppCheckbox title={'GDPR'} isChecked={gdpr} />
              </span>
            </div>
          </div>

          <button
            id='orderSubmit'
            disabled={loading}
            css={s.orderSummaryModalSubmitBtn}
            onClick={handleSubmit}
          >
            Zapsat se!
          </button>
        </form>

        <div css={s.orderSummaryModalInfoBox}>
          Po odeslání žádosti k zápisu kurzu ti přijde naše automatická zpráva s dalšími
          instrukcemi.
        </div>
      </div>
    </article>
  )
}

export const OSModalInput = (p: {
  label: string
  name: string
  type: string
  value: string
  setValue: any
  autocomplete: string
}) => (
  <div>
    <label htmlFor={p.name} css={s.orderSummaryModalLabel}>
      {p.label}
    </label>
    <input
      css={s.orderSummaryModalInput}
      name={p.name}
      id={p.name}
      type={p.type}
      value={p.value}
      onChange={e => p.setValue(e.target.value)}
      autoComplete={p.autocomplete}
    />
  </div>
)
