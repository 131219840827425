// Images
import electronicsFactory from './images/productionBuildings/assembly_line.webp'
import greenhouse from './images/productionBuildings/greenhouse.webp'
import oreMines from './images/productionBuildings/ore_mine.webp'
import oxygenGenerator from './images/productionBuildings/oxygen_generator.webp'
import plasticFactory from './images/productionBuildings/plastics_factory.webp'
import powerPlant from './images/productionBuildings/power_plant.webp'
import refinery from './images/productionBuildings/refinery.webp'
import waterCollector from './images/productionBuildings/water_collector.webp'

export const productionBuildingsImgList = [
  oreMines,
  plasticFactory,
  electronicsFactory,
  refinery,
  oxygenGenerator,
  waterCollector,
  greenhouse,
  powerPlant,
]
