//@todo - refactoring with server data -> Old controller !!!

// Format for loaded data from local storage
export type BaseDataFormat = {
  oxygen: number
  water: number
  food: number
  power: number
  metal: number
  plastics: number
  electronics: number
  fuel: number
}

// Format for calculations
export type InputDataFormat = {
  oxygen?: number
  water?: number
  food?: number
  power?: number
  metal?: number
  plastics?: number
  electronics?: number
  fuel?: number
}

// Load data from local storage
export const GetData = () => {
  const jsonData = localStorage.getItem('ResData')

  if (jsonData !== null) {
    const data: BaseDataFormat = JSON.parse(jsonData)
    return data
  }
}

// Update data and save new values to local storage
export const Update = (p?: InputDataFormat) => {
  p !== undefined ? (p = p) : (p = {})
  const resData = GetData()
  let isValidUpdate = true

  if (resData !== undefined) {
    const foodValue = resData.food + (p.food !== undefined ? p.food : 0)
    const waterValue = resData.water + (p.water !== undefined ? p.water : 0)
    const oxygenValue = resData.oxygen + (p.oxygen !== undefined ? p.oxygen : 0)
    const powerValue = resData.power + (p.power !== undefined ? p.power : 0)
    const metalValue = resData.metal + (p.metal !== undefined ? p.metal : 0)
    const plasticsValue = resData.plastics + (p.plastics !== undefined ? p.plastics : 0)
    const electronicsValue = resData.electronics + (p.electronics !== undefined ? p.electronics : 0)
    const fuelValue = resData.fuel + (p.fuel !== undefined ? p.fuel : 0)

    const validResData = {
      food: foodValue,
      water: waterValue,
      oxygen: oxygenValue,
      power: powerValue,
      metal: metalValue,
      plastics: plasticsValue,
      electronics: electronicsValue,
      fuel: fuelValue,
    }

    Object.values(validResData).map(item => {
      item < 0 ? (isValidUpdate = false) : ''
    })

    if (isValidUpdate) {
      localStorage.setItem('ResData', JSON.stringify(validResData))
      return validResData
    } else {
      return false
    }
  }
}

// Process resources
export const ProcessResources = (p: { [index: string]: number }) => {
  const consumedResValues: { [index: string]: number } = {}
  Object.entries(p).map(item => {
    consumedResValues[item[0]] = -item[1]
  })
  return Update(consumedResValues)
}
