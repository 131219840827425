/** @jsxImportSource @emotion/react */
// Default Reusable
import * as theme from '../theme_overmind'
import { css } from '@emotion/react'
// import styled from '@emotion/styled'

// Reusable categories
import * as G_Reusable from './GameDefault'

// Others
import { officerList } from '../gameStaticData/officerImgList'

// Images

///////////////////////////////////////////////////////////////////////////////////////////////////////////
// CSS - Main
///////////////////////////////////////////////////////////////////////////////////////////////////////////

export const main = {
  ms_div: css({
    position: 'relative',
    width: theme.main.layout.screen.width,
    height: theme.main.layout.screen.height,
  }),
  msImg: css({
    width: `100%`,
    height: `100%`,
  }),
  msDataBox_div: css({
    width: theme.main.layout.screen.innerScreen.mainDataBoxWidth,
    position: `absolute`,
    top: theme.main.layout.screen.innerScreen.mainDataBoxTopPosition,
    left: theme.main.layout.screen.innerScreen.mainDataBoxLeftPosition,
    color: theme.color.light,
  }),
  msMainDataBox_div: css({
    padding: theme.main.spacing.sm,
    border: theme.main.border.dark,
    backgroundColor: theme.color.transparent_cover_dark,
    color: theme.color.light,
  }),
  msMainDataBoxBlock_div: css({
    marginTop: theme.main.spacing.xs,
  }),
  msMainDataBoxInnerBlock_div: css({
    marginBottom: theme.main.spacing.xs,
  }),
  msSecDataBox_div: css({
    position: `relative`,
    right: theme.main.spacing.lg,
    marginTop: theme.main.spacing.sm,
    padding: theme.main.spacing.sm,
    border: theme.main.border.dark,
    backgroundColor: theme.color.transparent_cover_dark,
    color: theme.color.light,
  }),
  msInfoTableHeader_div: css({
    marginBottom: theme.main.spacing.xs,
  }),
  msInfoTableItem_div: css({
    display: `grid`,
  }),
  msInfoTableItem_div_col2: css({
    display: `grid`,
    gridTemplateColumns: `1fr 1fr`,
  }),
  msInfoTableItem_div_col3: css({
    gridTemplateColumns: `1.5fr 1fr 1.5fr`,
  }),
  msInfoTableItem_div_col4: css({
    gridTemplateColumns: `1fr 1fr 1fr 1fr`,
  }),
  msShopOfferBar_div: css({
    width: `${(100 / officerList.length) * (officerList.length - 2)}%`,
  }),
  msSlotsSlideBar_div: css({
    width: theme.main.layout.screen.innerScreen.slotsDataBoxWidth,
    position: `absolute`,
    bottom: theme.main.layout.screen.innerScreen.slotsDataBoxBottomPosition,
    left: theme.main.layout.screen.innerScreen.slotsDataBoxLeftPosition,
    padding: theme.main.spacing.xs,
    backgroundColor: theme.color.transparent_cover_dark,
    border: theme.main.border.dark,
  }),
  msSlotsGridBox_Div: css({
    display: `grid`,
    justifyContent: `space-between`,
    gridTemplateColumns: `repeat(auto-fill, ${theme.main.layout.screen.innerScreen.slotsDataBoxImgWithFrame})`,
    margin: `0 0 ${theme.main.layout.screen.innerScreen.slotsDataBoxImgFrame} ${theme.main.layout.screen.innerScreen.slotsDataBoxImgFrame}`,
  }),
  msSlotBox_btn: css({
    width: theme.main.layout.screen.innerScreen.slotsDataBoxImgSize,
    height: theme.main.layout.screen.innerScreen.slotsDataBoxImgSize,
    marginTop: theme.main.layout.screen.innerScreen.slotsDataBoxImgFrame,
    cursor: `pointer`,
  }),
  msProgressBox_div: css({
    width: theme.main.layout.screen.innerScreen.progressBarWidth,
    position: `absolute`,
    top: theme.main.layout.screen.innerScreen.progressBarTopPosition,
    right: theme.main.layout.screen.innerScreen.progressBarRightPosition,
    padding: theme.main.spacing.xs,
    color: theme.color.light,
    backgroundColor: theme.color.transparent_cover_dark,
  }),
  msProgressBar_div: css({
    position: `relative`,
    width: `100%`,
    height: theme.main.layout.screen.innerScreen.progressBarHeight,
    display: `flex`,
    marginBottom: theme.main.spacing.xs,
    backgroundColor: theme.color.transparent_cover_light,
  }),
  msProgressBarInner_div: css({
    width: theme.main.layout.screen.innerScreen.progressBarFragmentWidth,
    height: theme.main.layout.screen.innerScreen.progressBarHeight,
    backgroundColor: theme.color.focus,
    border: theme.main.border.dark,
  }),
  msProgressBarTimer_span: css({
    position: `absolute`,
    paddingRight: theme.main.spacing.xxs,
    textShadow: theme.main.shadow.text.main,
    right: 0,
  }),
} as const

///////////////////////////////////////////////////////////////////////////////////////////////////////////
// Main Components
///////////////////////////////////////////////////////////////////////////////////////////////////////////

type MainScreenDataBox = {
  name: string
  level: number
  currentWorkers: number
  currentWorkplaces: number
  nextWorkPlaces: number
  currentBuildingCount?: number
  currentBuildingLimit?: number
  nextBuildingLimit?: number
  currentResearchSpeed?: number
  nextResearchSpeed?: number
  currentConstructionSpeed?: number
  nextConstructionSpeed?: number
  currentPowerConsumption: number
  fullPowerConsumption: number
  nextFullPowerConsumption?: number
  constructionRate: number
  resCost: G_Reusable.ResCost
}

export const ScreenPattern = (p: { children: React.ReactNode; img: string }) => (
  <div css={main.ms_div}>
    {p.children}
    <img css={main.msImg} src={p.img}></img>
  </div>
)

export const InnerScreenPattern = (p: {
  dataBlock: MainScreenDataBox
  slotCount: number
  img: string
  children: React.ReactNode
  progressQueue: {
    name: string
    reqTime: number
    processedTime: number
  }[]
}) => (
  <div css={main.ms_div}>
    <ProgressBox progressQueue={p.progressQueue} />
    <div css={main.msDataBox_div}>
      <MainDataBlock
        name={p.dataBlock.name}
        level={p.dataBlock.level}
        currentWorkers={p.dataBlock.currentWorkers}
        currentWorkplaces={p.dataBlock.currentWorkplaces}
        nextWorkPlaces={p.dataBlock.nextWorkPlaces}
        currentBuildingCount={p.dataBlock.currentBuildingCount}
        currentBuildingLimit={p.dataBlock.currentBuildingLimit}
        nextBuildingLimit={p.dataBlock.nextBuildingLimit}
        currentResearchSpeed={p.dataBlock.currentResearchSpeed}
        nextResearchSpeed={p.dataBlock.nextResearchSpeed}
        currentConstructionSpeed={p.dataBlock.currentConstructionSpeed}
        nextConstructionSpeed={p.dataBlock.nextConstructionSpeed}
        currentPowerConsumption={p.dataBlock.currentPowerConsumption}
        fullPowerConsumption={p.dataBlock.fullPowerConsumption}
        nextFullPowerConsumption={p.dataBlock.nextFullPowerConsumption}
        constructionRate={p.dataBlock.constructionRate}
        resCost={p.dataBlock.resCost}
      />

      <div css={main.msSecDataBox_div}>{p.children}</div>
    </div>

    <SlotBar count={p.slotCount} />
    <img css={main.msImg} src={p.img}></img>
  </div>
)

export const ProgressBox = (p: {
  progressQueue: {
    name: string
    reqTime: number
    processedTime: number
  }[]
}) => {
  const fragmentCountMax = theme.responsiveSize.layout.screen.innerScreen.progressBarFragmentCount
  let fragmentsCount = 0
  let actionFormat = ''

  if (p.progressQueue[0]) {
    const remainingTime = p.progressQueue[0].reqTime - p.progressQueue[0].processedTime
    fragmentsCount = Math.floor(
      ((fragmentCountMax / 100) * p.progressQueue[0].processedTime) /
        (p.progressQueue[0].reqTime / 100)
    )
    let actionHrs = Math.floor(remainingTime / 3600)
    let actionMin = Math.floor(remainingTime / 60) % 60
    let actionSec = remainingTime % 60

    actionFormat = `${actionHrs ? `${actionHrs} hrs ` : ''}${
      actionMin ? `${actionMin} min ` : ''
    }${actionSec} sec`
  }

  return (
    <div css={main.msProgressBox_div}>
      <h4>Current in progress: {p.progressQueue[0] ? p.progressQueue[0].name : 'Nothing..'}</h4>
      <ProgressBar fragmentCount={fragmentsCount} actionFormat={actionFormat} />
      {p.progressQueue[1] ? <ConstructionQueue progressQueue={p.progressQueue} /> : ''}
    </div>
  )
}

export const ProgressBar = (p: { fragmentCount: number; actionFormat?: string }) => {
  let fragmentsCount = p.fragmentCount
  let progressBarStatus = []
  let actionFormat = ''
  p.actionFormat ? (actionFormat = p.actionFormat) : ''

  for (let i = 0; i < fragmentsCount; i++) {
    progressBarStatus.push(<div css={main.msProgressBarInner_div} key={i} />)
  }
  return (
    <div css={main.msProgressBar_div}>
      {progressBarStatus} <span css={main.msProgressBarTimer_span}>{actionFormat}</span>
    </div>
  )
}

export const ConstructionQueue = (p: {
  progressQueue: {
    name: string
    reqTime: number
    processedTime: number
  }[]
}) => {
  return (
    <>
      <h4>Next in queue</h4>
      {p.progressQueue.map((item, index) => (index === 0 ? '' : <p key={index}>{item.name}</p>))}
    </>
  )
}

export const MainDataBlock = (p: MainScreenDataBox) => (
  <div css={main.msMainDataBox_div}>
    <div>
      <h2>{p.name}</h2>
      <h3>Level: {p.level}</h3>
    </div>
    <div css={main.msMainDataBoxBlock_div}>
      {p.currentBuildingLimit ? (
        <div css={main.msMainDataBoxInnerBlock_div}>
          <G_Reusable.CurrentLevelInfo>
            Building limit: {p.currentBuildingCount} / {p.currentBuildingLimit}
          </G_Reusable.CurrentLevelInfo>
          <G_Reusable.NextLevelInfo>{p.nextBuildingLimit}</G_Reusable.NextLevelInfo>
        </div>
      ) : (
        <></>
      )}

      {p.currentResearchSpeed ? (
        <div css={main.msMainDataBoxInnerBlock_div}>
          <G_Reusable.CurrentLevelInfo>
            Research speed: {p.currentResearchSpeed}
          </G_Reusable.CurrentLevelInfo>
          <G_Reusable.NextLevelInfo>{p.nextResearchSpeed}</G_Reusable.NextLevelInfo>
        </div>
      ) : (
        <></>
      )}

      {p.currentConstructionSpeed ? (
        <div css={main.msMainDataBoxInnerBlock_div}>
          <G_Reusable.CurrentLevelInfo>
            Construction speed: {p.currentConstructionSpeed}
          </G_Reusable.CurrentLevelInfo>
          <G_Reusable.NextLevelInfo>{p.nextConstructionSpeed}</G_Reusable.NextLevelInfo>
        </div>
      ) : (
        <></>
      )}

      <div css={[main.msMainDataBoxInnerBlock_div, G_Reusable.main.gFlex_div]}>
        <div>
          <G_Reusable.CurrentLevelInfo>
            Workplaces: {p.currentWorkers} / {p.currentWorkplaces}{' '}
          </G_Reusable.CurrentLevelInfo>
          <G_Reusable.NextLevelInfo>{p.nextWorkPlaces}</G_Reusable.NextLevelInfo>
        </div>
        <G_Reusable.InputFormAssign
          currentValue={p.currentWorkers}
          maxLimit={p.currentWorkplaces}
        />
      </div>

      <div css={[main.msMainDataBoxInnerBlock_div, G_Reusable.main.gFlex_div]}>
        <div>
          <G_Reusable.CurrentLevelInfo>
            Power consumption: {p.currentPowerConsumption} / {p.fullPowerConsumption}
          </G_Reusable.CurrentLevelInfo>
          {p.nextFullPowerConsumption ? (
            <G_Reusable.NextLevelInfo>{p.nextFullPowerConsumption}</G_Reusable.NextLevelInfo>
          ) : (
            <></>
          )}
        </div>
        <G_Reusable.InputFormAssign
          currentValue={p.currentPowerConsumption}
          maxLimit={p.fullPowerConsumption}
        />
      </div>

      <G_Reusable.UpgradeBox constructionRate={p.constructionRate} resCost={p.resCost} />
    </div>
  </div>
)

export const InfoTableHeader = (p: { children: React.ReactNode; columnsCount?: number }) => (
  <div
    css={[
      main.msInfoTableItem_div,
      main.msInfoTableHeader_div,
      p.columnsCount === 3
        ? main.msInfoTableItem_div_col3
        : p.columnsCount === 4
        ? main.msInfoTableItem_div_col4
        : main.msInfoTableItem_div_col2,
    ]}
  >
    {p.children}
  </div>
)

export const InfoTableItem = (p: { children: React.ReactNode; columnsCount?: number }) => (
  <div
    css={[
      main.msInfoTableItem_div,
      p.columnsCount === 3
        ? main.msInfoTableItem_div_col3
        : p.columnsCount === 4
        ? main.msInfoTableItem_div_col4
        : main.msInfoTableItem_div_col2,
    ]}
  >
    {p.children}
  </div>
)

export const SlotBar = (p: { count: number }) => (
  <div css={main.msSlotsSlideBar_div}>
    <CreateItems count={p.count} />
  </div>
)

export const ShopOfferBar = (p: { count: number }) => (
  <div css={main.msShopOfferBar_div}>
    <CreateItems count={p.count} />
  </div>
)

export const CreateItems = (p: { count: number }) => (
  <div css={main.msSlotsGridBox_Div}>
    {Array.from(Array(p.count), (e, i) => {
      return (
        <button css={main.msSlotBox_btn} key={i}>
          Slot {i + 1}
        </button>
      )
    })}
  </div>
)
