export const productionBuildingsList = [
  {
    id: 'A-00', // ID = unique building's identification
    index: 0, // index = game UI index
    name: 'Ore mines',
    resType: 'metal',
    level: 2,

    statsAtCurrentLevel: {
      resProduction: 54,
      powerConsumption: 38,
    },
    statsAtNextLevel: {
      resProduction: 78,
      powerConsumption: 49,
      constructionRate: 300,
      resCost: {
        metal: 100,
        plastics: 80,
        electronics: 40,
        fuel: 20,
      },
    },
  },
  {
    id: 'A-01',
    index: 1,
    name: 'Plastic refinement factory',
    resType: 'plastics',
    level: 3,

    statsAtCurrentLevel: {
      resProduction: 26,
      powerConsumption: 12,
    },
    statsAtNextLevel: {
      resProduction: 78,
      powerConsumption: 49,
      constructionRate: 25700,
      resCost: {
        metal: 100,
        plastics: 80,
        electronics: 40,
        fuel: 20,
      },
    },
  },
  {
    id: 'A-02',
    index: 2,
    name: 'Electronics assembly-line',
    resType: 'electronics',
    level: 1,

    statsAtCurrentLevel: {
      resProduction: 15,
      powerConsumption: 30,
    },
    statsAtNextLevel: {
      resProduction: 78,
      powerConsumption: 49,
      constructionRate: 8,
      resCost: {
        metal: 100,
        plastics: 80,
        electronics: 40,
        fuel: 20,
      },
    },
  },
  {
    id: 'A-03',
    index: 3,
    name: 'Crude oil refinery',
    resType: 'fuel',
    level: 4,

    statsAtCurrentLevel: {
      resProduction: 33,
      powerConsumption: 45,
    },
    statsAtNextLevel: {
      resProduction: 78,
      powerConsumption: 49,
      constructionRate: 300,
      resCost: {
        metal: 100,
        plastics: 80,
        electronics: 40,
        fuel: 20,
      },
    },
  },
  {
    id: 'A-04',
    index: 4,
    name: 'Oxygen generator',
    resType: 'oxygen',
    level: 2,

    statsAtCurrentLevel: {
      resProduction: 10,
      powerConsumption: 10,
    },
    statsAtNextLevel: {
      resProduction: 78,
      powerConsumption: 49,
      constructionRate: 300,
      resCost: {
        metal: 100,
        plastics: 80,
        electronics: 40,
        fuel: 20,
      },
    },
  },
  {
    id: 'A-05',
    index: 5,
    name: 'Water collector',
    resType: 'water',
    level: 2,

    statsAtCurrentLevel: {
      resProduction: 20,
      powerConsumption: 22,
    },
    statsAtNextLevel: {
      resProduction: 78,
      powerConsumption: 49,
      constructionRate: 300,
      resCost: {
        metal: 100,
        plastics: 80,
        electronics: 40,
        fuel: 20,
      },
    },
  },
  {
    id: 'A-06',
    index: 6,
    name: 'Greenhouse complex',
    resType: 'food',
    level: 2,

    statsAtCurrentLevel: {
      resProduction: 18,
      powerConsumption: 18,
    },
    statsAtNextLevel: {
      resProduction: 78,
      powerConsumption: 49,
      constructionRate: 300,
      resCost: {
        metal: 100,
        plastics: 80,
        electronics: 40,
        fuel: 20,
      },
    },
  },
  {
    id: 'A-07',
    index: 7,
    name: 'Power plant',
    resType: 'power',
    level: 2,

    statsAtCurrentLevel: {
      resProduction: 45,
      powerConsumption: 20,
    },
    statsAtNextLevel: {
      resProduction: 78,
      powerConsumption: 49,
      constructionRate: 300,
      resCost: {
        metal: 100,
        plastics: 80,
        electronics: 40,
        fuel: 20,
      },
    },
  },
]
