/** @jsxImportSource @emotion/react */
// Default
import * as Unit from '../Reusable'
import * as theme from '../theme'
import { css } from '@emotion/react'

// Others

// Images

///////////////////////////////////////////////////////////////////////////////////////////////////////////
// CSS
///////////////////////////////////////////////////////////////////////////////////////////////////////////

const s = {} as const

///////////////////////////////////////////////////////////////////////////////////////////////////////////
// Main
///////////////////////////////////////////////////////////////////////////////////////////////////////////

export const Career = () => {
  Unit.ModulesInitialization()

  //////////////////////////// GTM ////////////////////////////
  //@ts-ignore
  window.dataLayer = window.dataLayer || []
  //@ts-ignore
  window.dataLayer.push({
    page_type: 'career',
  })
  //////////////////////////// GTM ////////////////////////////

  return (
    <Unit.AppContent>
      <Unit.AppH1 alignCenter>Přidej se k nám i ty!</Unit.AppH1>
      <Unit.AppH2>Co by jsi vlastně dělal/a?</Unit.AppH2>
      <Unit.AppP>
        Odpověď na tuto otázku záleží na tom o jakou pozici by jsi měl/a zájem. V první řadě nejsme
        klasická firma, která by obsazovala pouze vypsané volné pozice.. obsazujeme variabilně
        všechny pozice, o které je zájem. Co to znamená? No znamená to, že jsme schopni nabídnou
        místo mezi námi komukoli, kdo něco umí a má zájem být platným členem týmu a to ať už na
        pozici lektora, správce našich sociálních sítí, markeťáka nebo jakékoli jiné pozici, o které
        nás přesvědčíš, že právě tam tě potřebujeme!
      </Unit.AppP>

      <Unit.AppH1 extraTopMargin>Pozice lektora</Unit.AppH1>
      <Unit.AppH2>Chci být lektorem.. potřebuji nějakou specifickou kvalifikaci?</Unit.AppH2>
      <Unit.AppP>
        Pokud máš na mysli nějaký diplom, certifikát nebo jinou formu kvalifikace tak naše odpověď
        je jasná.. rozhodně nepotřebuješ! Nicméně mimo originálního přístupu a designu kurzů si
        zakládáme především také i na vysoké kvalitě. Takže ne nepotřebuješ mít nikým vydané
        potvrzení, že něco umíš, to co požadujeme my je, aby jsi skutečně něco uměl/a, alespoň tedy
        v rámci toho co by jsi chtěl/a u nás vyučovat.
      </Unit.AppP>
      <Unit.AppH2>Jak nám dokážeš, že něco umíš?</Unit.AppH2>
      <Unit.AppP>
        Opět naprosto jednoduše, každý lektor si vytváří své vlastní osnovy toho co bude učit a jak
        to bude učit. Vymezíš si téma, obtížnost, dobu nezbytnou pro celý kurz, navrhuješ si i
        vlastní cenu. Naprosto ve všem máš volnou ruku, limit není vymezen. Tuto osnovu poté
        zhodnotí další členové našeho týmu, kteří ti dají zpětnou vazbu a doptají se tě na detaily.
        Následně pak absolvuješ zrychlený kurz před týmem a pokud budou splněny všechny požadavky,
        včetně kvality obsahu pak vítej mezi námi! Ve zkratce tedy, každý má šanci to zkusit a nemáš
        co ztratit :P
      </Unit.AppP>
      <Unit.AppH2>Jaké budu mít finanční ohodnocení?</Unit.AppH2>
      <Unit.AppP>
        Opět v této otázce nejdeme s proudem. Pokud očekáváš, že ti řekneme konkrétní částku, kterou
        si u nás vyděláš za odučenou hodinu, tak se odpovědi nedočkáš. Není to proto, že by to bylo
        nějakým tajemstvím nebo nějaký trik jak tě oklamat, je to proto, protože tvůj honorář za
        tvou práci je podíl ze zápisného z celého kurzu! Tzn. tvoje odměna bude záviset nejenom na
        tom jakou má tvůj kurz hodnotu, ale i na tom kolik studentů budeš mít zapsáno v dané
        instanci. Což je zároveň i velká příležitot pro tebe, protože jsi takto skutečně pánem svého
        osudu. Čím kvalitnější bude tvoje práce, nebo čím více budeš aktivní.. např. budeš si
        prezentovat a propagovat své kurzy, aby jsi přitáhl/a nové studenty .. tím více tě také
        odměníme. Rozhodně u nás nečekej přístup typu, když uděláš více práce než ostatní, tak
        dostaneš i úplně stejně jako ostatní a ještě po tobě budeme chtít více a více .. takto
        rozhodně NE :D
      </Unit.AppP>
      <Unit.AppP>
        Nicméně orientačně průměrná odměna dle standardního kurzu a obsazenosti se pohybuje cca
        někde v rozmezí 250-1200,- Kč na hodinu. U komplexnějších kurzů můžeš samozřejmě dosahovat
        naprosto jiného dolního i horního limitu. Podíl, který připadá lektorovi je aktuálně
        nastaven na 40% z celého kurzu!
      </Unit.AppP>

      <Unit.AppH1 extraTopMargin>Pozice markeťáka</Unit.AppH1>
      <Unit.AppP>
        Dokážeš efektivně umístit reklamu nebo komunikovat s lidmi a nabídnout jim produkt? Jestli
        jsi komunikativní typ a zvolíš oslovení přímo konkrétních potencionálních klientů v tváří v
        tvář nebo jsi naopak spíše introvert, ale dovedeš správně určit a naplánovat reklamu
        produktu.. na tom u nás opravdu nezáleží.
      </Unit.AppP>
      <Unit.AppP>
        Každý markeťák od nás dostane vlastní identifikační kód, který disponuje nástrojem
        procentuální slevy ze zápisného z libovolného kurzu pro klienty. Pokud se klient s takovýmto
        slevovým kódem zapíše na kurz. My naprosto přesně budeme vědět jak se o nás dozvěděl. Klient
        tedy dosáhne výhodnější ceny a markeťák zase své provize v podobě 10% ze zápisného kurzu.
      </Unit.AppP>
      <Unit.AppP>Podíl z vlastní propagace je sčítatelný k celkové odměně i pro lektory!</Unit.AppP>

      <Unit.AppH1 extraTopMargin>Jiná pozice?</Unit.AppH1>
      <Unit.AppP extraBottomMargin>
        Pokud nám máš co nabídnou mimo výše uvedené.. kontaktuj nás klidně i ty! Jsme otevřeni všem
        možnostem i příležitostem!
      </Unit.AppP>
    </Unit.AppContent>
  )
}

///////////////////////////////////////////////////////////////////////////////////////////////////////////
// Components
///////////////////////////////////////////////////////////////////////////////////////////////////////////
