/** @jsxImportSource @emotion/react */
// Default
// import * as Unit from '../../Reusable'
import * as theme from '../../theme_overmind'
import { css } from '@emotion/react'

// Others

// Local data
import UI_metal_plate from '../../gameStaticData/images/UI/metal_plate.webp'

// Components
import { GamePanel } from './GamePanel'
import { MainInfoBar } from './upperBars/MainInfoBar'
import { MainScreen } from './MainScreen'
import { NavMenu } from './upperBars/NavMenu'

///////////////////////////////////////////////////////////////////////////////////////////////////////////
// CSS
///////////////////////////////////////////////////////////////////////////////////////////////////////////

const s = {
  layoutWrapper_div: css({
    width: theme.main.layout.mainWrapper.width,
    height: theme.main.layout.mainWrapper.height,
    display: `flex`,
    backgroundImage: `url(${UI_metal_plate})`,
    color: theme.color.dark,
  }),
} as const

///////////////////////////////////////////////////////////////////////////////////////////////////////////
// Main
///////////////////////////////////////////////////////////////////////////////////////////////////////////

export const LayoutWrapper = () => (
  <div css={s.layoutWrapper_div}>
    <div>
      <MainInfoBar />
      <MainScreen />
    </div>
    <div>
      <NavMenu />
      <GamePanel />
    </div>
  </div>
)

///////////////////////////////////////////////////////////////////////////////////////////////////////////
// Components
///////////////////////////////////////////////////////////////////////////////////////////////////////////
