/** @jsxImportSource @emotion/react */
// Default
import * as Unit from '../../../Reusable_overmind'
// import * as theme from '../../../theme'
// import { css } from '@emotion/react'

// Others
import { GameContext } from '../../../gameContext'
import { useContext } from 'react'

//Controller
import { UpdateHQLevel } from '../../../gameControllers/GameController'

// Local data
import { researchHQData } from '../../../gameStaticData/MOCK/researchHQData'
import lab from '../../../gameStaticData/images/HQs/laboratory.webp'

///////////////////////////////////////////////////////////////////////////////////////////////////////////
// CSS
///////////////////////////////////////////////////////////////////////////////////////////////////////////

// const s = {} as const

///////////////////////////////////////////////////////////////////////////////////////////////////////////
// Main
///////////////////////////////////////////////////////////////////////////////////////////////////////////

export const ResearchHQ = () => {
  const context = useContext(GameContext)
  UpdateHQLevel(researchHQData.level, context.levelOfSelectedHQ.setLevelOfSelectedHQ)

  const MOCK_progressBar: any = [] //@Todo - replace MOCK!

  return (
    <Unit.G_MS.InnerScreenPattern
      dataBlock={{
        name: researchHQData.name,
        level: researchHQData.level,
        currentWorkers: 52,
        currentWorkplaces: researchHQData.statsAtCurrentLevel.workers,
        nextWorkPlaces: researchHQData.statsAtNextLevel.workers,
        currentResearchSpeed: researchHQData.statsAtCurrentLevel.researchSpeed,
        nextResearchSpeed: researchHQData.statsAtNextLevel.researchSpeed,
        currentPowerConsumption: 200,
        fullPowerConsumption: researchHQData.statsAtCurrentLevel.powerConsumption,
        nextFullPowerConsumption: researchHQData.statsAtNextLevel.powerConsumption,
        constructionRate: researchHQData.statsAtNextLevel.constructionRate,
        resCost: researchHQData.statsAtNextLevel.resCost,
      }}
      slotCount={researchHQData.statsAtCurrentLevel.slots}
      img={lab}
      progressQueue={MOCK_progressBar}
    >
      <h3>Coming soon!</h3>
      <p>More technologies</p>
      <p>Tech trees</p>
      <p>Expeditions</p>
      <p>World map</p>
      <p>{`Overmind on the rise! (Glitch's return)`}</p>
    </Unit.G_MS.InnerScreenPattern>
  )
}

///////////////////////////////////////////////////////////////////////////////////////////////////////////
// Components
///////////////////////////////////////////////////////////////////////////////////////////////////////////
