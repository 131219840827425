/** @jsxImportSource @emotion/react */
// Default
// import * as Unit from '../../Reusable'
import * as theme from '../../theme_overmind'
import { css } from '@emotion/react'

// Others
import { GameContext } from '../../gameContext'
import { useContext } from 'react'

// Local data
import { hqImgList } from '../../gameStaticData/hqImgList'
import UI_metal_plate from '../../gameStaticData/images/UI/metal_plate.webp'

const MOCK_logArray = [
  { message: 'Explorer drone in training queue.', nextLine: 'Action completed in 41s' },
  { message: 'Sand storm on the horizon!', nextLine: 'Strikes in 6:12s', type: 'warning' },
  { message: 'Ore mines under construction.', nextLine: 'Action completed in 7:21s' },
  { message: 'Reinforced armor research in progress.', nextLine: 'Action completed in 26:47s' },
  {
    message: 'Enemy spy heading in our direction!',
    nextLine: 'Strikes in 38:02s',
    type: 'warning',
  },
  { message: 'Return of expedition.', nextLine: 'Action completed in 43:41s' },
] //@Todo - replace MOCK!

// Components
import { ColonyManagerMenu } from './gamePanel/ColonyManagerMenu'
import { DefenseMenu } from './gamePanel/DefenseMenu'
import { ExchangeMenu } from './gamePanel/ExchangeMenu'
import { ProductionMenu } from './gamePanel/ProductionMenu'
import { ResearchMenu } from './gamePanel/ResearchMenu'
import { UnitMenu } from './gamePanel/UnitMenu'

const contentList = [
  <ProductionMenu key={0} />,
  <ResearchMenu key={1} />,
  <DefenseMenu key={2} />,
  <UnitMenu key={3} />,
  <ExchangeMenu key={4} />,
]

const hiddenContentList = [<ColonyManagerMenu key={-1} />]

///////////////////////////////////////////////////////////////////////////////////////////////////////////
// CSS
///////////////////////////////////////////////////////////////////////////////////////////////////////////

const s = {
  gamePanel_div: css({
    width: theme.main.layout.gamePanel.width,
    height: theme.main.layout.gamePanel.height,
    border: theme.main.border.dark,
  }),
  gpHeader_div: css({
    width: `100%`,
    height: theme.main.layout.gamePanel.gpHeader.height,
    padding: `${theme.main.spacing.sm} ${theme.main.spacing.md} ${theme.main.spacing.xs} ${theme.main.spacing.xs}`,
  }),
  gpLogBox: css({
    width: `100%`,
    height: `100%`,
    position: `relative`,
    padding: theme.main.spacing.xs,
    backgroundColor: theme.color.transparent_cover_dark,
    border: theme.main.border.dark,
    color: theme.color.light,
    overflowY: `scroll`,
  }),
  gpLogItem: css({
    margin: theme.main.spacing.xs,
  }),
  gpLogItemMessage: css({}),
  gpLogItemNextLine: css({
    fontSize: theme.main.font.sm,
  }),
  gpMenu_div: css({
    width: `100%`,
    height: `${theme.main.layout.gamePanel.gpMenu.height}`,
  }),
  gpMenuNav_div: css({
    position: 'absolute',
    transform: `translate(-100%, 0)`,
    display: `flex`,
    flexDirection: `column`,
    textAlign: `center`,
  }),
  gpMenuNavItem_div: css({
    width: theme.main.layout.gamePanel.gpCategory.width,
    height: theme.main.layout.gamePanel.gpCategory.height,
    margin: theme.main.spacing.xs,
    cursor: `pointer`,
    border: theme.main.border.dark,
    backgroundColor: theme.color.focus,
  }),
  gpMenuNav_p: css({
    height: theme.main.layout.gamePanel.gpCategory.titleHeight,
    fontSize: theme.main.font.sm,
  }),
  gpMenuNav_img: css({
    width: `80%`,
    height: `70%`,
  }),
  gpMenuContent_div: css({
    width: `100%`,
    height: `100%`,
    backgroundImage: `url(${UI_metal_plate})`,
  }),
  messageType_warning: css({
    color: theme.color.bug,
  }),
} as const

///////////////////////////////////////////////////////////////////////////////////////////////////////////
// Main
///////////////////////////////////////////////////////////////////////////////////////////////////////////

export const GamePanel = () => {
  const context = useContext(GameContext)
  const activeScreen = context.activeScreen.activeScreen

  // Active menu display changer
  const ChangeActiveMenuContent = (index: number) => {
    context.activeScreen.setActiveScreen(index)
  }
  return (
    <div css={s.gamePanel_div}>
      <div css={s.gpMenuNav_div}>
        <GPMenuCategory title='HQ' onClickFn={ChangeActiveMenuContent} id={0} />
        <GPMenuCategory title='Lab' onClickFn={ChangeActiveMenuContent} id={1} />
        <GPMenuCategory title='Defense' onClickFn={ChangeActiveMenuContent} id={2} />
        <GPMenuCategory title='Units' onClickFn={ChangeActiveMenuContent} id={3} />
        <GPMenuCategory title='Market' onClickFn={ChangeActiveMenuContent} id={4} />
      </div>
      <GPHeader /> <GPMenu index={activeScreen} />
    </div>
  )
}

///////////////////////////////////////////////////////////////////////////////////////////////////////////
// Components
///////////////////////////////////////////////////////////////////////////////////////////////////////////

const GPHeader = () => (
  <div css={s.gpHeader_div}>
    <div css={s.gpLogBox} className='logScroll'>
      {MOCK_logArray.map((item, index) => (
        <GPLogItem
          message={item.message}
          nextLine={item.nextLine}
          type={item.type}
          key={index}
          index={index}
        />
      ))}
    </div>
  </div>
)

const GPLogItem = (p: { index: number; message: string; nextLine?: string; type?: string }) => {
  return (
    <div css={[s.gpLogItem, p.type === 'warning' ? s.messageType_warning : '']}>
      <p css={s.gpLogItemMessage}>
        [{p.index + 1}] {p.message}
      </p>
      {p.nextLine ? <p css={s.gpLogItemNextLine}>({p.nextLine})</p> : <></>}
    </div>
  )
}

const GPMenu = (p: { index: number }) => {
  return (
    <div css={s.gpMenu_div}>
      <div css={s.gpMenuContent_div}>
        {p.index < 0 ? hiddenContentList[Math.abs(p.index + 1)] : contentList[p.index]}
      </div>
    </div>
  )
}

const GPMenuCategory = (p: { title: string; onClickFn: (a: number) => void; id: number }) => (
  <div css={s.gpMenuNavItem_div} onClick={() => p.onClickFn(p.id)} className='focusBG'>
    <p css={s.gpMenuNav_p}>{p.title}</p>
    <img css={s.gpMenuNav_img} src={hqImgList[p.id]} />
  </div>
)
