import { createContext } from 'react'

///////////////////////////////////////////////////////////////////////////////////////////////////////////
// Default
///////////////////////////////////////////////////////////////////////////////////////////////////////////

export const productionHQ_default = {
  currentBuildingLimit: 0,
  powerConsumption: 0,
  resProduction: {
    metal: 0,
    plastics: 0,
    electronics: 0,
    fuel: 0,
    oxygen: 0,
    water: 0,
    food: 0,
    power: 0,
  },
}

export const defenseHQ_default = {
  currentBuildingLimit: 0,
  powerConsumption: 0,
  defStats: {
    heat: 0,
    cold: 0,
    radiation: 0,
    cyber: 0,
    force: 0,
  },
}

///////////////////////////////////////////////////////////////////////////////////////////////////////////
// Main
///////////////////////////////////////////////////////////////////////////////////////////////////////////

export const GameContext = createContext({
  language: {
    language: 'en',
    setLanguage: (language: string) => {},
  },
  activeScreen: {
    activeScreen: 0,
    setActiveScreen: (index: number) => {},
  },
  activeMarketScreen: {
    activeMarketScreen: 0,
    setActiveMarketScreen: (index: number) => {},
  },
  productionHQ_data: {
    productionHQ_data: productionHQ_default,
    setProductionHQ_data: (p: ProductionHQ_data) => {},
  },
  defenseHQ_data: {
    defenseHQ_data: defenseHQ_default,
    setDefenseHQ_data: (p: DefHQ_data) => {},
  },
  levelOfSelectedHQ: {
    levelOfSelectedHQ: 0,
    setLevelOfSelectedHQ: (level: number) => {},
  },
})

///////////////////////////////////////////////////////////////////////////////////////////////////////////
// Components
///////////////////////////////////////////////////////////////////////////////////////////////////////////

export type ProductionHQ_data = {
  powerConsumption: number
  currentBuildingLimit: number
  resProduction: ResList
}

export type ResList = {
  metal: number
  plastics: number
  electronics: number
  fuel: number
  oxygen: number
  water: number
  food: number
  power: number
}

export type DefHQ_data = {
  powerConsumption: number
  currentBuildingLimit: number
  defStats: {
    heat: number
    cold: number
    radiation: number
    cyber: number
    force: number
  }
}

export type DefStats = {
  heat: number
  cold: number
  radiation: number
  cyber: number
  force: number
}
