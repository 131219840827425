/** @jsxImportSource @emotion/react */
// Default
import * as Unit from '../../../Reusable_overmind'
// import * as theme from '../../../theme'
// import { css } from '@emotion/react'

// Others
import { GameContext } from '../../../gameContext'
import { useContext, useEffect } from 'react'

// Local data
import { defensiveBuildingsImgList } from '../../../gameStaticData/defensiveBuildingsImgList'
import { defensiveBuildingsList } from '../../../gameStaticData/MOCK/defensiveBuildingsList'

///////////////////////////////////////////////////////////////////////////////////////////////////////////
// CSS
///////////////////////////////////////////////////////////////////////////////////////////////////////////

// const s = {} as const

///////////////////////////////////////////////////////////////////////////////////////////////////////////
// Main
///////////////////////////////////////////////////////////////////////////////////////////////////////////

export const DefenseMenu = () => {
  const context = useContext(GameContext)

  // Init
  const MOCK_defensiveBuildingsList = defensiveBuildingsList //@Todo - replace MOCK!

  // Set game data into context for main screen
  const UpdateGameData = () => {
    let levelSum = 0
    let powerConsumptionSum = 0
    let setDefStats: { [index: string]: number } = {}

    Object.values(MOCK_defensiveBuildingsList).map(item => {
      setDefStats[item.defType] = item.statsAtCurrentLevel.def
      powerConsumptionSum += item.statsAtCurrentLevel.powerConsumption
      levelSum += item.level
    })

    context.defenseHQ_data.setDefenseHQ_data({
      currentBuildingLimit: levelSum,
      powerConsumption: powerConsumptionSum,
      defStats: {
        heat: setDefStats.heat,
        cold: setDefStats.cold,
        radiation: setDefStats.radiation,
        cyber: setDefStats.cyber,
        force: setDefStats.force,
      },
    })
  }

  useEffect(() => {
    UpdateGameData()
  }, [])

  return (
    <Unit.G_GP.MenuCategory>
      {MOCK_defensiveBuildingsList.map((item: DefBuilding, index) => {
        const currentLevel = item.statsAtCurrentLevel
        const nextLevel = item.statsAtNextLevel
        return (
          <Unit.G_GP.BuildingPattern
            key={index}
            img={defensiveBuildingsImgList[index]}
            name={item.name}
            level={item.level}
            currentPowerConsumption={currentLevel.powerConsumption}
            nextPowerConsumption={nextLevel.powerConsumption}
            currentDef={currentLevel.def}
            nextDef={nextLevel.def}
            constructionRate={nextLevel.constructionRate}
            resCost={nextLevel.resCost}
          />
        )
      })}
    </Unit.G_GP.MenuCategory>
  )
}

///////////////////////////////////////////////////////////////////////////////////////////////////////////
// Components
///////////////////////////////////////////////////////////////////////////////////////////////////////////

type DefBuilding = {
  id: string
  index: number
  name: string
  defType: string
  level: number

  statsAtCurrentLevel: {
    def: number
    powerConsumption: number
  }
  statsAtNextLevel: DefBuildingStats
}

type DefBuildingStats = {
  def: number
  powerConsumption: number
  constructionRate: number
  resCost: {
    metal: number
    plastics: number
    electronics: number
    fuel: number
  }
}
