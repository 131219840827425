/** @jsxImportSource @emotion/react */
// Default Reusable
import * as theme from './theme_overmind'
import { css } from '@emotion/react'
// import styled from '@emotion/styled'

// Reusable categories
import * as AppReusable from './Reusable/App'
import * as GameDefaultReusable from './Reusable/GameDefault'
import * as GamePanelReusable from './Reusable/GamePanel'
import * as MainScreenReusable from './Reusable/MainScreen'
import * as QualityReusable from './Reusable/Quality'

// Others

// Images

///////////////////////////////////////////////////////////////////////////////////////////////////////////
// Unit
///////////////////////////////////////////////////////////////////////////////////////////////////////////

export const App = AppReusable
export const G = GameDefaultReusable
export const G_GP = GamePanelReusable
export const G_MS = MainScreenReusable
export const G_Q = QualityReusable
