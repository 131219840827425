// Images
import aiAvatar from './images/officerMiniatures/ai_avatar.webp'
import defenseOfficer from './images/officerMiniatures//defense_officer.webp'
import industryChief from './images/officerMiniatures//industry_chief.webp'
import madScientist from './images/officerMiniatures//mad_scientist.webp'
import militaryOfficer from './images/officerMiniatures//military_officer.webp'

export const officerImgList = [
  industryChief,
  madScientist,
  defenseOfficer,
  militaryOfficer,
  aiAvatar,
]

export const officerList = [
  {
    name: 'Industry Chief',
    description:
      'Experienced chief of industry. Thanks to his experience he can strengthen the production of all resources, even with a smaller number of workers',
    effects: [
      { description: 'Resources production increased by:', value: 20 },
      { description: 'Required workers reduced by:', value: 10 },
    ],
    price: 1000,
    duration: 30,
    quality: 4,
    img: industryChief,
  },
  {
    name: 'Mad Scientist',
    description:
      'Although it is a mad weirdo that we do not know about, he certainly seems to be an unquestionable benefit in the field of our research.',
    effects: [
      { description: 'Research speed increased by:', value: 20 },
      { description: 'Power consumption reduced by', value: 10 },
    ],
    price: 1000,
    duration: 30,
    quality: 4,
    img: madScientist,
  },
  {
    name: 'Defense Officer',
    description:
      'An experienced operating officer who can strengthen our defensive systems and to reduce damage after failure of shields.',
    effects: [
      { description: 'Defense effectivity increased by:', value: 20 },
      { description: 'Negative effects of shield failure reduced by:', value: 20 },
    ],
    price: 1000,
    duration: 30,
    quality: 4,
    img: defenseOfficer,
  },
  {
    name: 'Retired General',
    description:
      'The retired general of the past era. But his experience and firm hand can be a strong driving drive of all our units.',
    effects: [
      { description: 'Units training speed increased by:', value: 20 },
      { description: 'All units stats increased by: ', value: 5 },
    ],
    price: 1000,
    duration: 30,
    quality: 4,
    img: militaryOfficer,
  },
  {
    name: 'AI Avatar',
    description:
      'Adjusted fragment of AI based on overmind technology. Not only can he be able to replace all specialists at the same time, he can also prepare our colony for an immediate interpretation jump!',
    effects: [
      { description: 'Has effects of all other specialists!', value: -1 },
      { description: 'Immediate colony transmission!', value: -1 },
    ],
    price: 3000,
    duration: 30,
    quality: 5,
    img: aiAvatar,
  },
] //@Todo - move to BE
